const Cancel = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.075 0C5.85212 0 0 5.85212 0 13.075C0 20.2979 5.85212 26.15 13.075 26.15C20.2979 26.15 26.15 20.2979 26.15 13.075C26.15 5.85212 20.2979 0 13.075 0ZM19.486 16.5072C19.7338 16.755 19.7338 17.1557 19.486 17.4035L17.3982 19.486C17.1504 19.7338 16.7497 19.7338 16.5019 19.486L13.075 16.0274L9.64281 19.486C9.39502 19.7338 8.99433 19.7338 8.74654 19.486L6.66403 17.3982C6.41624 17.1504 6.41624 16.7497 6.66403 16.5019L10.1226 13.075L6.66403 9.64281C6.41624 9.39502 6.41624 8.99433 6.66403 8.74654L8.75181 6.65876C8.99961 6.41097 9.40029 6.41097 9.64808 6.65876L13.075 10.1226L16.5072 6.66403C16.755 6.41624 17.1557 6.41624 17.4035 6.66403L19.4912 8.75181C19.739 8.99961 19.739 9.40029 19.4912 9.64808L16.0274 13.075L19.486 16.5072Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Cancel;
