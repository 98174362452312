import React from "react";

const Info = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 0C4.25331 0 0 4.25331 0 9.5C0 14.7467 4.25331 19 9.5 19C14.7467 19 19 14.7467 19 9.5C19 4.25327 14.7467 0 9.5 0ZM11.0833 15.5866C11.0833 15.945 10.7928 16.2355 10.4344 16.2355H8.65417C8.29581 16.2355 8.00528 15.945 8.00528 15.5866V8.36318C8.00528 8.00482 8.29577 7.71429 8.65417 7.71429H10.4344C10.7928 7.71429 11.0833 8.00478 11.0833 8.36318V15.5866ZM9.5 6.48478C8.61034 6.48478 7.88913 5.76357 7.88913 4.87391C7.88913 3.98426 8.61034 3.26304 9.5 3.26304C10.3897 3.26304 11.1109 3.98426 11.1109 4.87391C11.1109 5.76357 10.3897 6.48478 9.5 6.48478Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Info;
