import { useState } from "react";
import { NavLink } from "react-router-dom";
import Table from "../../UI/Table/Table";
import Pagination from "../../UI/Pagination/Pagination";
import { getEnrollments } from "../../../api/api";
import { setDateFormat } from "../../../assets/helper";

import { MdOutlinePageview } from "react-icons/md";

import classes from "./Enrollments.module.scss";

const Enrollments = ({ enrollments }) => {
  const tHead = ["Name", "Email", "Location", "Date Submitted", "Action"];
  const totalPages = enrollments.summary.total_pages;
  const itemsPerPage = enrollments.summary.items_returned;

  const [list, setList] = useState(enrollments.items);
  const [summary, setSummary] = useState(enrollments.summary);
  const [itemOffset, setItemOffset] = useState(itemsPerPage);
  const [loading, setLoading] = useState(false);

  const data = !list.length
    ? []
    : list.map((item) => {
        return {
          name: `${item.first} ${item.last}`,
          email: item.email,
          location: item.location,
          date: setDateFormat(item.submitted),
          action_id: item.id,
        };
      });

  const onPaginateHandler = (value, callback) => {
    setLoading(true);

    getEnrollments({ page: value })
      .then((response) => {
        setList(response.items);
        setSummary(response.summary);
        setItemOffset(itemsPerPage * response.summary.current_page);

        callback();
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      {!list.length ? (
        <p>No data</p>
      ) : (
        <div>
          <div className={classes.Info}>
            Showing {itemOffset - summary.items_returned + 1} to {itemOffset} of{" "}
            {summary.total_items} enrollments.
          </div>

          <Table head={tHead} loading={loading} dontOrder={tHead}>
            {data.map((item) => (
              <tr key={item.action_id}>
                <td>{item.name}</td>
                <td>
                  <a href={`mailto:${item.email}`}>{item.email}</a>
                </td>
                <td>{item.location}</td>
                <td>{item.date}</td>
                <td>
                  <NavLink
                    to={`/enrollment-detail/${item.action_id}`}
                    className={classes.Action}
                  >
                    <MdOutlinePageview /> <span>Detail</span>
                  </NavLink>
                </td>
              </tr>
            ))}
          </Table>
        </div>
      )}

      <div className={classes.Footer}>
        <NavLink to={`/search`} className={`link ${classes.Link}`}>
          enrollment search &gt;
        </NavLink>

        {totalPages > 1 ? (
          <Pagination
            onPaginate={onPaginateHandler}
            currentPage={summary.current_page}
            totalPages={totalPages}
            loading={loading}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Enrollments;
