import Modal from "../UI/Modal/Modal";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import Space from "../UI/Space/Space";
import { getUserProfilePicture } from "../../api/api";
import BlankProfile from "../../assets/images/blank_profile.png"

import useInput from "../../hooks/useInput";
import classes from "./Users.module.scss";
import { useContext, useEffect, useState } from "react";
import Checkbox from "../UI/Checkbox/Checkbox";
import CardEnrollmentUserLevelContext from "../../contexts/userLevelContext";

const UserDetailsModal = ({ onClose, onSave, data }) => {
  const [image, setImage] = useState(null);
  const [canAllowUpload, setAllowUpload] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const role = useContext(CardEnrollmentUserLevelContext)

  

  useEffect(() => {
    const isAdmin = role === 'Administrator';
    setAllowUpload(isAdmin);    
  }, [role])



  useEffect(() => {
    if(image) {
      return
    }

    getUserProfilePicture(data.id).then((pfp) => {
      setImage(pfp);
    }).catch(() => {
      setImage(BlankProfile);
    });
  }, [image, data])

  const {
    value: name,
    isTouched: nameTouched,
    isValid: nameIsValid,
    hasError: nameHasError,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
  } = useInput((value) => value.trim() !== "", data.name);

  const {
    value: email,
    isTouched: emailTouched,
    isValid: emailIsValid,
    hasError: emailHasError,
    inputChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput((value) => value.trim() !=="", data.email)

  const { value: location } = useInput(
    (value) => value.trim() !== "",
    data.location
  );

  const [allowFileUpload, setAllowFileUpload] = useState(data.file_upload_allowed || false);

  let formIsValid = false;

  if (nameIsValid && emailIsValid) {
    formIsValid = true;
  }

  const saveHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    const prepareBody = {
      first: name,
      email: email,
      upload_enabled: allowFileUpload || false
      // file_upload_allowed: allowFileUpload || data.file_upload_allowed,
      /*
      phone: {
        phone_number: data.phone_number,
        phone_type: data.phone_type,
      },
      */
    };
    if(prepareBody.first === data.name) {
      delete prepareBody.first;
    }
    if(prepareBody.email === data.email) {
      delete prepareBody.email;
    }
    if(prepareBody.allowFileUpload === data.file_upload_allowed) {
      delete prepareBody.upload_enabled;
    }
    setUploading(true);
    try {
      onSave(data.id, prepareBody);

    }catch {

    }

    setUploading(false);
  };

  return (
    <Modal title="Coordinator Details" onClose={onClose}>
      <form onSubmit={saveHandler}>
        <div className={classes.Modal__wrapper}>
          <div className={classes.Modal__left}>
            <Input
              label="Name:*"
              clsType="small"
              required={true}
              onChange={nameChangeHandler}
              onBlur={nameBlurHandler}
              value={name}
              touched={nameTouched}
              hasError={nameHasError}
            />
          </div>
          {image == null ?
            ""
            : 
            <div className={classes.Modal__img}>
              <img src={image} alt="avatar" />
            </div> 
          }
          
        </div>

        <Space value="20" />

        <Input
          type="email"
          label="Email:*"
          clsType="small"
          required={true}
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          value={email}
          touched={emailTouched}
          hasError={emailHasError}
          disabled={data.status === 'deactivated'}
        />

        <Input
          label="Location:*"
          clsType="small"
          disabled="disabled"
          value={location}
          readonly="true"
        />

        {canAllowUpload ?
          <div>
            <Space value="10"/>

            <Checkbox
            label="Should be allowed to upload files"
            value={allowFileUpload}
            onChange={() => {setAllowFileUpload(!allowFileUpload); console.log('test')}}
            />
          </div> : ''
        }

        <Space value="30" />


        <div className={classes.Modal__center}>
          <Button color="primary" type="submit" disabled={!formIsValid || isUploading}>
            Update
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default UserDetailsModal;
