import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Table from "../UI/Table/Table";
import Pagination from "../UI/Pagination/Pagination";
import Checkbox from "../UI/Checkbox/Checkbox";
import Notification from "../UI/Notification/Notification";
import UserDetailsModal from "./UserDetailsModal";

import useNotification from "../../hooks/useNotification";
import { setDateFormat } from "../../assets/helper";
import { getUsers, updateUser, userActions, userInvitation } from "../../api/api";

import classes from "./Users.module.scss";
import WaitModal from "../Enroll/WaitModal";
import UserTableEntry from "./UserTableEntry.tsx";

const UsersTable = ({ users, render, cbo }) => {
  const tHead = [
    "Name / Username",
    "Email",
    "Status",
    "Last Login",
    "Active Since",
    "Location / Role",
    "Actions",
  ];



  const [totalPages, setTotalPages] = useState(users.summary.total_pages);
  const [list, setList] = useState(users.items);
  const [summary, setSummary] = useState(users.summary);
  const [itemOffset, setItemOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resetPagination, setResetPagination] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [isLegacy, setIsLegacy] = useState(true);
  const [modalIsShow, setModalIsShow] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [showWaitModal, setShowWaitModal] = useState(false);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(null);

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    add: addNotification,
    remove: removeNotification,
  } = useNotification();

  // some things need to take us to page one again
  useEffect(() => {
    setPage(1)
    // HACK - race condition where sometimes the last operation isn't to request page 1
    // so we are waiting 1 sec and then sending page1 again
    setTimeout(function() {
      setResetPagination(true)
      setPage(1)
    }, 1000)
  }, [cbo, isDeactivated, isLegacy])

  const reloadTable = () => {
    const tHeadOrder = {
      "Name / Username": 'name',
      'Email': 'email',
      'Status': 'status',
      'Last Login': 'last_login',
      'Active Since': 'active_since',
      "Location / Role": 'location_id'
    }

    const params = {
      page: page,
      include_deactivated: isDeactivated,
      include_legacy: isLegacy,
      cbo_id: cbo
    }
    if(order) {
      params.order_by = tHeadOrder[order.col];
      params.order_direction = order.direction;
    }

    getUsers(params)
    .then((response) => {
      setList(response.items);
      setSummary(response.summary);
      setItemOffset(6 * (response.summary.current_page - 1));
      setTotalPages(response.summary.total_pages);
      // setResetPagination(true);
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
      setLoading(false);
    });
  }

  useEffect(reloadTable, [isDeactivated, isLegacy, page, resetPagination, cbo, order]);

  const onPaginateHandler = (value, callback) => {
    setPage(value)
    callback()
  };

  const deactivatedHandler = () => {
    setIsDeactivated(!isDeactivated);
    setResetPagination(false);
  };

  const legacyHandler = () => {
    setIsLegacy(!isLegacy);
    setResetPagination(false);
  };

  /**
   * View Details Modal
   */
  const openUserDetailHandler = (item) => {
    setActiveUser(item);
    setModalIsShow(true);
  };

  const closeUserDetailHandler = () => {
    setModalIsShow(false);
    setActiveUser(null);
  };

  const updateUserDetailsHandler = (id, prepareBody) => {
    removeNotification();
    console.log(`sending body`,prepareBody)
    updateUser(id, prepareBody)
      .then(() => {
        closeUserDetailHandler();
        setResetPagination(false)

        addNotification({
          status: "success",
          text: "The request has been completed",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
          render();
        }, 3000);
      })
      .catch((error) => {
        console.error(error);

        addNotification({
          status: "error",
          text: "Error saving user details. Please check your inputs and try again.",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
      });
  };

  /**
   * User Events
   */
  const runUserEvent = (id, action) => {
    removeNotification();

    userActions(id, action)
      .then((response) => {
        addNotification({
          status: "success",
          text: response.message,
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
      })
      .catch((error) => {
        console.error(error);

        addNotification({
          status: "error",
          text: "Error saving user details. Please check your inputs and try again.",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
      }).finally(reloadTable);
  };

  /**
   * Resend Invitation
   */
  const resendInvitationHandler = (item) => {
    runUserEvent(item.id, "resendInvitation");
  };

  /**
   * Cancel Invitation
   */
  const cancelInvitationHandler = (item) => {
    if (window.confirm(`Are you sure you want to cancel the invitation for ${item.name}?`)) {
      runUserEvent(item.id, "cancelInvitation");
    }
  };

  /**
   * Reset Password
   */
  const resetPasswordHandler = (item) => {
    if (item.status === "legacy") {
      if (!window.confirm(`Are you sure you want to migrate ${item.name}?`)) {
        return;
      }
    }

    if(item.status !== "legacy") {
      runUserEvent(item.id, "resetPassword");
    }else {
      setShowWaitModal(true);

      userInvitation({
        email: item.email,
        name: item.name,
        phone: item.phone_number,
        location_id: item.location,
        migrate: true,
        migration_id: item.id
      }).then((r) => {
        setShowWaitModal(false);
        if(r.status === 'success') {
          addNotification({
            status: "success",
            text: "User successfully migrated",
            btn_text: "OK",
          });
          return;
        }

        addNotification({
          status: "error",
          text: r.response.message,
          btn_text: "OK",
        });
      }).catch((e) => {
        console.error(e)
      })

    }
  };

  /**
   * Deactivate
   */
  const deactivateHandler = (item) => {
    if (window.confirm(`Are you sure you want to deactivate ${item.name}?`)) {
      runUserEvent(item.id, "deactivate");
    }
  };

  return (
    <>
      {showWaitModal &&
          ReactDOM.createPortal(<WaitModal
            message="Migrating legacy account"
          />, document.querySelector("body"))}
      <div className="container">
        {!list.length ? (
          <p>No data</p>
        ) : (
          <>
            <div className={classes.Table__info}>
              Showing {itemOffset + 1} to {itemOffset + summary.items_returned}{" "}
              of {summary.total_items} entries.
            </div>

            <Table head={tHead} loading={loading} dontOrder={['Actions']} orderCallback={setOrder}>
              {(!list.length
    ? []
    : list.map((item) => {
        return {
          name: item.name,
          image: item.image,
          email: item.email,
          status: item.status,
          active_since: setDateFormat(item.active_since, {
            month: "numeric",
            year: "numeric",
            day: "numeric",
          }),
          location: item.location_name || "All Locations",
          last_login: setDateFormat(item.last_login, {
            month: "numeric",
            year: "numeric",
            day: "numeric",
          }),
          id: item.id,
          phone_number: item.phone_number,
          file_upload_allowed: item.file_upload_allowed === true || item.file_upload_allowed === 'true',
          role: item.role,
          //          phone_type: item.phone.phone_type,
        };
      })).map((item) => (
                <UserTableEntry
                  id={item.id}
                  name={item.name}
                  email={item.email}
                  status={item.status}
                  last_login={item.last_login}
                  active_since={item.active_since}
                  location={item.location}
                  role={item.role}
                  item={item}
                  resetPasswordHandler={resetPasswordHandler}
                  openUserDetailHandler={openUserDetailHandler}
                  deactivateHandler={deactivateHandler}
                  resendInvitationHandler={resendInvitationHandler}
                  cancelInvitationHandler={cancelInvitationHandler}
                />
              ))}
            </Table>
          </>
        )}

        <div className={classes.Table__footer}>
          <span onClick={onPaginateHandler}></span>
          {totalPages > 1 ? (
            <Pagination
              onPaginate={onPaginateHandler}
              currentPage={page}
              totalPages={totalPages}
              loading={loading}
              reset={resetPagination}
            />
          ) : null}
        </div>

        <div>
          <Checkbox
            label="Also include deactivated users"
            value={isDeactivated}
            onChange={deactivatedHandler}
          />
          <Checkbox
            label="Hide legacy users?"
            value={!isLegacy}
            onChange={legacyHandler}
          />
        </div>
      </div>

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            width="full"
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={() => removeNotification()}
          />,
          document.querySelector("body")
        )}

      {modalIsShow &&
        ReactDOM.createPortal(
          <UserDetailsModal
            onClose={closeUserDetailHandler}
            onSave={updateUserDetailsHandler}
            data={activeUser}
          />,
          document.querySelector("body")
        )}
    </>
  );
};

export default UsersTable;
