//import * as React from 'react';
import { useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { useQuery } from '@tanstack/react-query';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTrigger } from "../../../hooks/useTrigger";

// const handleBulkActionCancel = () => {
//   console.log("Bulk Action Cancel Clicked")
// }

const CommonTable = ({ columns, fetch, bodyParams, entryPath, setRowSelection, rowSelection, rowActions, rowActionsSize, refreshTrigger }) => {

  const getId = (originalRow, index, parent) => {
    if(originalRow.id) {
      return originalRow.id;
    }else if (originalRow.info_id) {
      return originalRow.info_id;
    }
    return data.items[index].id
  }

  const navigate = useNavigate()

  const [prevBodyParams, setPrevBodyParams] = useState(bodyParams)
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })

  useEffect(() => {
    if (JSON.stringify(bodyParams) !== JSON.stringify(prevBodyParams)) {
      var newPagination = {...pagination}
      newPagination.pageIndex = 0
      setPagination(newPagination)
      setPrevBodyParams({...bodyParams})
    }
  }, [bodyParams, pagination, prevBodyParams])


  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: [
      'table-data',
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      sorting,
      bodyParams
    ],
    queryFn: () => { return fetch(bodyParams, sorting, pagination) },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })


  useTrigger.child(() => {refetch()}, refreshTrigger)


  return (
    <div className="__table_container">
      {!data?.items.length ? (
        <p>No data</p>
      ) : (
          <>
            <Box sx={{borderRadius: '10px', border: 'solid 1px #e0e0e0', padding: '0px 0 15px 0'}}>
              <MaterialReactTable
                columns={columns}
                data={data?.items ?? []}
                initialState={{ density: 'compact'}}
                st
                manualPagination
                manualSorting
                enableColumnResizing
                muiToolbarAlertBannerProps={
                  isError
                    ? {
                        color: 'error',
                        children: 'Error loading data',
                      }
                    : undefined
                }
                muiTablePaperProps={{
                  elevation: 0,
                  sx: {
                    border: 'none',
                  },
                }}
                muiTableHeadCellProps={{
                  //simple styling with the `sx` prop, works just like a style prop in this example
                  sx: {
                    fontSize: '14px',
                    fontFamily: 'Avenir',
                    fontWeight: 500,
                    opacity: .87,
                    background: '#F6F6F6',
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    fontFamily: 'Avenir',
                    opacity: 1,
                    letterSpacing: '0.25px',
                    lineHeight: '20px',
                    fontSize: '14px'
                  },
                }}
                onGlobalFilterChange={setGlobalFilter}
                onPaginationChange={setPagination}
                onSortingChange={setSorting}
                rowCount={data?.summary?.total_items ?? 0}
                state={{
                  globalFilter,
                  isLoading,
                  pagination,
                  showAlertBanner: isError,
                  showProgressBar: isFetching,
                  sorting,
                  rowSelection,
                }}
                enableRowActions={rowActions !== undefined}
                positionActionsColumn="last"
                renderRowActions={rowActions ? ({ row }) => rowActions(row) : undefined}
                enableRowSelection
                onRowSelectionChange={setRowSelection}
                getRowId={(originalRow, index, parent) => getId(originalRow, index, parent)}
                enableColumnFilters={false}
                enableGlobalFilter={false}
                enableTopToolbar={true}
                enableColumnDragging={false}
                enableColumnOrdering={true}
                muiTablePaginationProps={{
                  showFirstButton: true,
                  showLastButton: true,
                }}
                muiTableBodyRowProps={({ row }) => ({
                  onClick: entryPath ? (event) => {
                    navigate(entryPath + row.id)
                  } : undefined,
                  sx: {
                    cursor:entryPath ? 'pointer' : 'auto'
                  }
                })}
                displayColumnDefOptions={{
                  'mrt-row-actions': {
                    size: rowActionsSize || 80
                  }
                }}
              />
            </Box>


          </>
      )}
    </div>
  );
};

export default CommonTable;
