import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import classes from "./Charts.module.scss";
import { useState } from "react";
import { useEffect } from "react";
import { getCardStatistics } from "../../../api/api";
import Card from "../../Card/Card"

const DailyEnrollments = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        getCardStatistics().then(stats => {
            setData(stats);
        })
    }, [])

    return (
        <div className={`col-lg-6`}>
            <Card>
                <div className={`d-flex justify-content-between ` + classes.Charts__Col_Wrp_Title}>
                    <h2>Daily Enrollments</h2>
                </div>
                <div className={classes.Charts__Col_Wrp_Chart}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={400}
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="2 2" />
                            <XAxis dataKey="name" />
                            <YAxis yAxisId="left" />
                            <Tooltip />
                            <Legend iconType={'circle'} />
                            <Line yAxisId="left" type="monotone" dataKey="VITA" stroke="#a064b8" strokeWidth={3} activeDot={{ r: 8 }} />
                            <Line yAxisId="left" type="monotone" dataKey="Payroll + Instant" stroke="#579D36" strokeWidth={3} activeDot={{ r: 8 }} />
                            <Line yAxisId="left" type="monotone" dataKey="Instant Only" stroke="#e67f69" strokeWidth={3} activeDot={{ r: 8 }} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </Card>
        </div>
    )
}

export default DailyEnrollments;