import { useReducer, useEffect, useState } from "react";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";

import useInput from "../../../hooks/useInput";
import { getLocationsExport, deactivateLocations } from "../../../api/api";
import { downloadCSVFile, csvMaker } from "../../../assets/helper";

import Download from "../../../assets/svg/Download";
import classes from "../List.module.scss";
import DynamicFilterDropdown from "../Common/DynamicFilters/FilterDropdown";
import DynamicFilterInputs from "../Common/DynamicFilters/FilterInputs";
import Dropdown from 'rsuite/Dropdown';
import "rsuite/dist/rsuite.min.css";

const fillStates = () => {
  const statesJson = require("../../../assets/json/us-states.json");

  const options = [];

  for (let key in statesJson) {
    options.push({
      value: key,
      label: statesJson[key],
      isDisabled: false,
    });
  }

  options.unshift({
    value: "",
    label: "Select a state...",
    isDisabled: false,
  });

  return options;
};

const stateOptions = fillStates()

const selectInitialState = () => {
  return { options: [], value: ""}
}

const selectReducer = (state, action) => {
  if (action.type === "SET_OPTIONS") {
    return {
      options: action.payload,
      value: state.value,
    }
  }

  if (action.type === "CHANGE") {
    return {
      options: state.options,
      value: action.payload,
    }
  }

  if (action.type === "RESET") {
    return {
      options: state.options,
      value: "",
    }
  }

  return selectInitialState()
}

const LocationSearchForm = ({ searchParams, onSearch, cbo, rowSelection }) => {
  const [deactivatedChecked, setDeactivatedChecked] = useState(false)
  const resets = []

  const handleDeactivated = () => {
    setDeactivatedChecked(!deactivatedChecked)
  }

  const handleBulkActionDeactivate = async () => {
    const deactivateResult = await deactivateLocations({location_ids: Object.keys(rowSelection)})
    console.log(deactivateResult)
  }

  /**
   * Dynamic Filters
   */
  const filterValues = {}

  const [filterState, setFilter] = useState(
    {
      "activeFields": [],
      "minEnrollmentsYtd": {
        label: "Min Enrollments YTD",
        type: "number",
        paramName: "minEnrollmentsYtd",
        placeholder: "50"
      },
      "maxEnrollmentsYtd": {
        label: "Max Enrollments YTD",
        type: 'number',
        paramName: 'maxEnrollmentsYtd',
        placeholder: "150"
      },
      "address_1": {
        label: "Address 1",
        type: 'string',
        paramName: 'address_1',
        placeholder: "123 Main St",
      },
      "address_2": {
        label: "Address 2",
        type: 'string',
        paramName: 'address_2',
        placeholder: "Apt 321",
      },
      "city": {
        label: "City",
        type: 'string',
        paramName: "city",
        placeholder: "Plano"
      },
      "state": {
        label: "State",
        type: "select",
        reference: false,
        paramName: "state",
        options: stateOptions
      },
      "zip": {
        label: "Zip Code",
        type: "number",
        paramName: "zip",
        placeholder: "75075"
      },
      "phone": {
        label: "Phone",
        type: "phone",
        paramName: "phone",
        placeholder: "(555) 555-5555"
      },
    }
  )

  //update filterValues for non-select fields
  filterValues["minEnrollmentsYtd"] = useInput(() => true)
  filterValues["maxEnrollmentsYtd"] = useInput(() => true)
  filterValues['zip'] = useInput((value) => value.length === 5 || value.length === 0 ? true : false)
  filterValues['city'] = useInput(() => true)
  filterValues['phone'] = useInput((value) => value.length < 14 && value.length !== 0 ? false : true)
  filterValues["address_1"] = useInput(() => true)
  filterValues["address_2"] = useInput(() => true)

  //update filterValues for select fields
  var stateReducer = useReducer(selectReducer, selectInitialState())
  filterValues["state"] = {}
  filterValues["state"]["state"] = stateReducer[0]
  filterValues["state"]["dispatch"] = stateReducer[1]

  const setFilterState = (filter) => {
    setFilter({...filter})
  }

  const filterValueKeys = Object.keys(filterValues)
  for (const key of filterValueKeys) {
    if (filterValues[key].reset) {
      resets.push(filterValues[key].reset)
    } else {
      resets.push(() => {filterValues[key].dispatch({type: "RESET"})})
    }
  }
  /**
   * End Dynamic Filters
   */


  /**
   * Location Name
   */

  const {
    value: locationName,
    inputChangeHandler: locationNameChangeHandler,
    inputBlurHandler: locationNameBlurHandler,
    reset: resetLocationName,
  } = useInput(() => true)
  resets.push(resetLocationName)

  /**
   * Location ID
   */

  const {
    value: locationId,
    inputChangeHandler: locationIdChangeHandler,
    inputBlurHandler: locationIdBlurHandler,
    reset: resetLocationId,
  } = useInput(() => true)
  resets.push(resetLocationId)

  /**
   * Last Entry Start Date
   */
  const {
    value: lastEntryStart,
    inputChangeHandler: lastEntryStartChangeHandler,
    inputBlurHandler: lastEntryStartBlurHandler,
    reset: resetLastEntryStart,
  } = useInput(() => true)
  resets.push(resetLastEntryStart)

  /**
   * Last Entry End Date
   */
  const {
    value: lastEntryEnd,
    inputChangeHandler: lastEntryEndChangeHandler,
    inputBlurHandler: lastEntryEndBlurHandler,
    reset: resetLastEntryEnd,
  } = useInput(() => true)
  resets.push(resetLastEntryEnd)


  /**
   * CBO
   */
  const [cboState, dispatchCbo] = useReducer(
    selectReducer,
    selectInitialState()
  )
  resets.push(() => {dispatchCbo({ type: "RESET" })})

  useEffect(() => {
    if (cbo.length > 1) {
      const cboOptionsArr = cbo.map((item) => {
        return {
          value: item.id,
          label: item.name,
          isDisabled: false,
        }
      })

      cboOptionsArr.unshift({
        label: "Select a CBO...",
        isDisabled: false,
        value: "",
      })

      dispatchCbo({ type: "SET_OPTIONS", payload: cboOptionsArr})
    }
  }, [cbo])

  const changeCboHandler = (event) => {
    dispatchCbo({ type: "CHANGE", payload: event.target.value})
  }

  const submitHandler = (event) => {
    event.preventDefault()
    event.target.reset()

    const prepareBody = {
      name: `${locationName}` || null,
      id: `${locationId}` || null,
      lastEntryStart: `${lastEntryStart}` || null,
      lastEntryEnd: `${lastEntryEnd}` || null
    }

    if (cboState.value) {
      prepareBody.cbo_id = cboState.value
    }

    if (!deactivatedChecked) {
      prepareBody.inactive = false
    }

    var isValid = true
    for (const key of filterState.activeFields) {
      if (filterState[key].type !== 'select') {
        if (!filterValues[key].isValid) {
          isValid = false
          filterValues[key].inputBlurHandler()
          continue
        }
        if (filterValues[key].value !== "") {
          if (key === 'phone') {
            prepareBody[filterState[key].paramName] = filterValues[key].value.replace('(', '').replace(')', '')
          } else {
            prepareBody[filterState[key].paramName] = filterValues[key].value
          }
        }
      } else {
        if (filterValues[key].state.value !== "") {
          prepareBody[filterState[key].paramName] = filterValues[key].state.value
        }
      }
    }

    if (isValid) {
      onSearch(prepareBody)
    }
  }

  const downloadHandler = () => {
    const prepareBody = {
      name: `${locationName}` || null,
      id: `${locationId}` || null,
      lastEntryStart: `${lastEntryStart}` || null,
      lastEntryEnd: `${lastEntryEnd}` || null
    }

    if (cboState.value) {
      prepareBody.cbo_id = cboState.value
    }

    if (!deactivatedChecked) {
      prepareBody.inactive = false
    }

    var isValid = true
    for (const key of filterState.activeFields) {
      if (filterState[key].type !== 'select') {
        if (!filterValues[key].isValid) {
          isValid = false
          filterValues[key].inputBlurHandler()
          continue
        }
        if (key === 'phone') {
          prepareBody[filterState[key].paramName] = filterValues[key].value.replace('(', '').replace(')', '')
        } else {
          prepareBody[filterState[key].paramName] = filterValues[key].value
        }
      } else {
        if (filterValues[key].state.value !== "") {
          prepareBody[filterState[key].paramName] = filterValues[key].state.value
        }
      }
    }

    if (isValid) {
      getLocationsExport(prepareBody)
        .then((response) => {
          const csvData = csvMaker(response)
          downloadCSVFile(csvData, "locations")
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const runResets = (e) => {
    resets.forEach(function (f) { f() })
    setDeactivatedChecked(false)
    onSearch({inactive: false})
  }

  return (
    <div>
      <div className={classes.Form__container}>
        <div className="container">
          <div className={classes.Form__text}>
            Enter <b>any</b> search parameter below and click search.
            <span onClick={runResets} className={classes.Form__clear}>
              RESET FILTERS
            </span>
            <DynamicFilterDropdown
              filterState={filterState}
              setFilterState={setFilterState}
            />
          </div>

          <form onSubmit={submitHandler} className={classes.Form}>


            <div className={classes.Form__col}>

              <div className={classes.Form__input__long}>
                <Input
                  label="Location Name:"
                  clsType="small"
                  value={locationName}
                  onChange={locationNameChangeHandler}
                  onBlur={locationNameBlurHandler}
                  placeholder="Location Name"
                />
              </div>

            </div>
            <div className={classes.Form__col}>

              <div className={classes.Form__input__long}>
                <Input
                  label="Location ID:"
                  clsType="small"
                  value={locationId}
                  onChange={locationIdChangeHandler}
                  onBlur={locationIdBlurHandler}
                  placeholder="ABCD0000001"
                />
              </div>

            </div>
            <div className={classes.Form__col}>

              <div className={classes.Select}>
                <Select
                  clsType="small"
                  label="CBO:"
                  value={cboState.value}
                  options={cboState.options}
                  onChange={changeCboHandler}
                />
              </div>

            </div>
            <div className={classes.Form__col}>

              <div className={classes.Form__input__long}>
                <Input
                  label="Last Entry Start Date:"
                  clsType="small"
                  type="date"
                  value={lastEntryStart}
                  onChange={lastEntryStartChangeHandler}
                  onBlur={lastEntryStartBlurHandler}
                  placeholder="MM/DD/YYYY"
                />
              </div>

            </div>
            <div className={classes.Form__col}>

              <div className={classes.Form__input__long}>
                <Input
                  label="Last Entry End Date:"
                  clsType="small"
                  type="date"
                  value={lastEntryEnd}
                  onChange={lastEntryEndChangeHandler}
                  onBlur={lastEntryEndBlurHandler}
                  placeholder="MM/DD/YYYY"
                />
              </div>

            </div>

            <div className={classes.Form__col}>

              <Button color="primary" type="submit" clsType="small">
                Search
              </Button>

            </div>
          </form>

          <DynamicFilterInputs
            filterState={filterState}
            setFilterState={setFilterState}
            resets={resets}
            filterValues={filterValues}
            submitHandler={submitHandler}
          />


          <div className={classes.Form__checkbox_container}>
            <input
              className={classes.Form__checkbox}
              type="checkbox"
              checked={deactivatedChecked}
              onChange={handleDeactivated}
              id="deactivatedCheckbox"
            />
            <label className={classes.Form__checkbox_label} for="deactivatedCheckbox">
              Include deactivated
            </label>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={classes.Row}>
          { Object.keys(rowSelection).length === 0 ? null :
            <>
              <Dropdown
                size="lg"
                title="Bulk Actions"
                className={classes.Bulk_Actions}
                toggleClassName={classes.Bulk_Actions__Toggle}
                menuStyle={{
                    width: '100%'
                }}
              >
                <Dropdown.Item onClick={handleBulkActionDeactivate}>Deactivate</Dropdown.Item>
              </Dropdown>
            </>
          }
          <div className={classes.DownloadCol}>
          {true && (
            <div className={classes.Btn} onClick={downloadHandler}>
              Download <Download />
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationSearchForm;