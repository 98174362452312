import classes from "./MessageBoard.module.scss";
import { Editor } from '@tinymce/tinymce-react';
import {useEffect, useState} from "react";
import IconPencil from "../../../assets/images/icon-pencil.svg";
import { getCbo, getMessageUpdateDate, setOrgMessage } from "../../../api/api.js";
import moment from 'moment-timezone';
import Card from "../../Card/Card"

const MessageBoard = () => {
    const [initialValue, setInitialValue] = useState(undefined);
    const [cancelValue, setCancelValue] = useState(undefined);
    const [updatedValue, setUpdatedValue] = useState(undefined);
    const [lastUpdate, setLastUpdate] = useState('unknown');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        // api call here to get initial value
        getCbo().then((cbo) => {
            if (cbo) {
                setInitialValue(cbo.helpful_information.trim());
                setCancelValue(initialValue);
                // setLastUpdate("03/30/2023");
                setUpdatedValue(initialValue ?? '');
            }
        });

        setDateFromAPI();
    }, [initialValue]);

    const setDateFromAPI = () => {
        //.toLocaleString().toString().replace(',','')
        getMessageUpdateDate().then((date) => {
            setLastUpdate(date.edited);
        })
    }

    const handleEdit = (e) => {
        setIsEditing(true);
        document.getElementById("messageBoard").focus();
    }

    const handleFocus = (e) => {
        setIsEditing(true);
    }

    const handleCancel = (e) => {
        setUpdatedValue(cancelValue);
        setIsEditing(false);
    }

    const handleSave = (e) => {
        console.log(updatedValue);

        // api call to push new data
        setIsEditing(false);
        setCancelValue(updatedValue);
        setOrgMessage(updatedValue);
        // do we want to set this client side or server side?
        // server side
        setDateFromAPI();
    }

    return (
        <div className={classes.Mb}>
            <div className={`container`}>
                <div className={`row`}>
                    <div className={`col-12`}>
                        <Card>
                            <h2 className={classes.Mb__Col_Title}>Message Board</h2>
                            <Editor
                                id='messageBoard'
                                className={classes.Mb__Col_Copy_Board}
                                apiKey='qmlcindz4kb283efhxwlz33yrgs21hcrle2fal5pfjgmi95m'
                                initialValue={initialValue}
                                value={updatedValue}
                                onEditorChange={(newValue, editor) => setUpdatedValue(newValue)}
                                inline={true}
                                onFocus={handleFocus}
                                init={{
                                    plugins: "code lists link",
                                    toolbar: "undo redo | blocks | alignleft aligncenter alignright alignjustify | forecolor backcolor | bold italic underline strikethrough | code | " +
                                        "bullist numlist | link | outdent indent",
                                    font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                                    content_style: 'body { font-family:"Avenir" !important; font-size:16px } .mce-content-body { border: 1px solid #c4c4c4; border-radius: 15px; padding: 25px;}'
                                }}
                            />
                            <div className={`${!isEditing ? classes.Mb__Col_Info_Active + " " : ""}` + classes.Mb__Col_Info}>
                                <p className={classes.Mb__Col_Info_Updated} dangerouslySetInnerHTML={{__html: "Last updated " + moment.utc(lastUpdate).local().format('MM/DD/YYYY h:mm A')}}></p>
                                <button className={`d-flex align-items-center ` + classes.Mb__Col_Info_Edit} onClick={handleEdit}>
                                    <img src={IconPencil} alt="Edit Icon"/>
                                    Edit
                                </button>
                            </div>
                            <div className={`${isEditing ? classes.Mb__Col_Save_Active + " " : ""}` + classes.Mb__Col_Save}>
                                <button className={`d-flex align-items-center ` + classes.Mb__Col_Save_Cancel} onClick={handleCancel}>Cancel</button>
                                <button className={`d-flex align-items-center ` + classes.Mb__Col_Save_Save} onClick={handleSave}>Save</button>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MessageBoard;