import { useContext, useEffect, useState } from "react";
import classes from "./Logo.module.scss";
import FocusLogo from "../../assets/images/focus.svg";
import CoordinatorLogo from "../../assets/images/focus_coordinator.svg"
import SuperCoordinatorLogo from "../../assets/images/focus_supercoordinator.svg"
import AdminLogo from "../../assets/images/focus_admin.svg"
import SecureUpload from "../../assets/images/secure_upload.svg"
import CardEnrollmentUserLevelContext from "../../contexts/userLevelContext";

const Logo = () => {
  const [Image, setImage] = useState(FocusLogo)
  const [Text, setText] = useState("Focus Enrollment Portal")
  const role = useContext(CardEnrollmentUserLevelContext)

  useEffect(() => {
    console.log(`imageUpdate`)
    if (window.location.pathname === '/file-upload-utility') {
      setImage(SecureUpload)
      setText("SecureUpload")
      return
    }
    switch (role) {
      case 'Administrator': {
        setImage(AdminLogo)
        setText("Focus Enrollment Portal (admin)")
        break;
      }
      case 'Super Coordinator': {
        setImage(SuperCoordinatorLogo)
        setText("Focus Enrollment Portal (super coordinator)")
        break;
      }
      case 'Coordinator': {
        setImage(CoordinatorLogo)
        setText("Focus Enrollment Portal (coordinator)")
        break;
      }
      default: {
        setImage(FocusLogo)
        setText("Focus Enrollment Portal")
        break;
      }
    }
  }, [role])

  return (
      <div id="logo" className={classes.Logo}>
        <img src={Image} alt={Text} />
      </div>
    )
};

export default Logo;
