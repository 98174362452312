import React, { useEffect,  useState } from "react"
import AceEditor from "react-ace";

import "ace-builds/src-min-noconflict/mode-mysql";
import "ace-builds/src-noconflict/theme-sqlserver";
import "ace-builds/src-min-noconflict/ext-language_tools";

function SQLEditor({setValue, value, getTables}) {
    const [completor, setCompleter] = useState(null);

    const onUpdated = (v) => {
        setValue(v);
    }

    // run on post
    useEffect(() => {
        if(!getTables || !getTables.getter) {
            return;
        }
        getTables.getter().then(tables => {
            if(!tables) {
                return;
            } 

            const new_completor = {
                getCompletions: function (editor, session, pos, prefix, callback) {
                    const completions = [];
                    let hasAddedTables = false;

                    // Get the token just before our current position.
                    const tokenBeforeCursor = session.getTokenAt(pos.row, pos.column - prefix.length);

                    if (tokenBeforeCursor) {

                        if(tokenBeforeCursor.type === "text" && tokenBeforeCursor.value.trim() === ".") {
                            // Fetch the token before the dot.
                            const tokenBeforeDot = session.getTokenAt(pos.row, tokenBeforeCursor.start);
                            const tableNameBeforeDot = tokenBeforeDot ? tokenBeforeDot.value.trim() : null;

                            const matchedTable = tables.find(t => t.name === tableNameBeforeDot);

                            if (matchedTable) {
                                for (const name of matchedTable.columns) {
                                    if(name.startsWith(session.getTokenAt(pos.row, pos.column).value)) {
                                        completions.push({ value: name, meta: 'column', score: 1000 });
                                    }
                                }
                            }
                        }else if((tokenBeforeCursor.type === "text" && tokenBeforeCursor.value === " ")) {
                            const tokenBeforeSpace = session.getTokenAt(pos.row, tokenBeforeCursor.start);

                            if(tokenBeforeSpace && tokenBeforeSpace.type === 'keyword') {
                                if(tokenBeforeSpace.value.trim() === 'select') {
                                    tables.forEach(table => {
                                        for (const name of table.columns) {
                                            if(name.startsWith(session.getTokenAt(pos.row, pos.column).value)) {
                                                completions.push({ value: name, meta: 'column', score: 500 });
                                            }
                                        }
                                    });
                                    completions.push({value: '*', meta: 'selector', score: 1000})
                                }else if(tokenBeforeSpace.value.trim() === 'from') {
                                    hasAddedTables = true;
                                    tables.forEach(table => {
                                        completions.push({ value: table.name, meta: 'table', score: 1000 });
                                    });
                                }
                            }


                        }

                    }

                    if(!hasAddedTables) {
                        tables.forEach(table => {
                            completions.push({ value: table.name, meta: 'table', score: 200 });
                        });
                    }


                    callback(null, completions);
                },
                identifierRegexps: [/[a-zA-Z_0-9\-\u00A2-\uFFFF]/]
            }
            setCompleter(new_completor);
        })
    }, [getTables])

    //run after completor is finished constructing
    useEffect(() => {
        if(completor == null) {
            return;
        }
        const langTools = require("ace-builds/src-noconflict/ext-language_tools");

        langTools.addCompleter(completor);
    }, [completor])



    return (
        <>
            <style>
                {
                    `#editor {
                        border-radius: 5px;
                        border-width: 2px;
                        border-style: solid;
                        border-color: #C4C4C4;
                    }
                    .ace_placeholder {
                        color: #1943e8 !important;
                    }
                    
                    `
                }
            </style>
            <AceEditor
                id="editor"
                aria-label="editor"
                mode="mysql"
                theme="sqlserver"
                name="editor"
                width="100%"
                fontSize={18}
                minLines={15}
                maxLines={10}
                showPrintMargin={false}
                showGutter
                placeholder="Write your query here..."
                editorProps={{ $blockScrolling: true }}
                setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                }}
                value={value}
                onChange={onUpdated}
                showLineNumbers
            />
        </>
        
    )
}

export default SQLEditor;