import { useState, useEffect } from "react";
import ChevronLeft from "../../../assets/svg/ChevronLeft";
import ChevronRight from "../../../assets/svg/ChevronRight";

import classes from "./Pagination.module.scss";

// pagination = {
//   buttons: {
//     prev_disabled: false,
//     next_disabled: true,
//   },
//   items: [
//     {
//       page: 1,
//       active: true
//     },
//     {
//       page: 2,
//       active: false
//     },
//   ]
// }

const setStartPagination = (totalPages) => {
  let paginationItems = [];

  for (let i = 1; i <= totalPages; i++) {
    if (i <= 3) {
      paginationItems.push({
        page: i,
        active: i === 1 ? true : false,
      });
    }
  }

  return {
    buttons: {
      prev_disabled: true,
      next_disabled: false,
    },
    items: paginationItems,
  };
};

const updatePagination = (value, currentPage, totalPages) => {
  let page = +currentPage;
  let prev_disabled = false;
  let next_disabled = false;
  let newPaginationItems = [];

  if (value === "next") {
    if (page === totalPages) {
      next_disabled = true;
    }

    newPaginationItems = rebuildPagination(page, totalPages);
  } else if (value === "prev") {
    if (page === 1) {
      prev_disabled = true;
    }

    newPaginationItems = rebuildPagination(page, totalPages);
  } else {
    if (+value === 1) {
      prev_disabled = true;
    }

    if (+value === totalPages) {
      next_disabled = true;
    }

    newPaginationItems = rebuildPagination(value, totalPages);
  }

  return {
    buttons: {
      prev_disabled: prev_disabled,
      next_disabled: next_disabled,
    },
    items: newPaginationItems,
  };
};

const rebuildPagination = (num, totalPages) => {
  let items = [];

  items.push({
    page: +num,
    active: true,
  });

  if (+num > 1) {
    items.unshift({
      page: +num - 1,
      active: false,
    });
  }

  if (+num < totalPages) {
    items.push({
      page: +num + 1,
      active: false,
    });
  }

  return items;
};


const Pagination = ({ onPaginate, currentPage, totalPages, loading, reset }) => {
  const [pagination, setPagination] = useState(setStartPagination(totalPages));
  // const [selectedPage, setSelectedPage] = useState(currentPage); // todo delete after API

  useEffect(() => {
    if (reset) {
      setPagination(setStartPagination(totalPages))
    }    
  }, [reset, totalPages])
  
   
  const clickHandler = async (event) => {
    let value = event.target.dataset.page;
    let page = +currentPage;

    if (value === "next") {
      page = page + 1;
    } else if (value === "prev") {
      page = page === 1 ? 1 : page - 1;
    } else {
      page = +value;
    }

    // setSelectedPage(page);
    onPaginate(page, setNewPagination);


    function setNewPagination() {
      const newPagination = updatePagination(value, page, totalPages);

      setPagination(newPagination);
    }
  };

  return (
    <div className={`${classes.Pagination} ${loading ? classes.disabled: ''}`}>
      <button
        type="button"
        disabled={pagination.buttons.prev_disabled}
        className={`${classes.Pagination__btn} ${classes.Pagination__btn__prev}`}
        data-page="prev"
        onClick={clickHandler}
      >
        <ChevronLeft />
        <span>Previous</span>
      </button>

      {pagination.items.map((item) => (
        <button
          type="button"
          className={`${item.active ? classes.active : ""}`}
          key={item.page}
          data-page={item.page}
          onClick={clickHandler}
        >
          {item.page}
        </button>
      ))}

      <button
        type="button"
        disabled={pagination.buttons.next_disabled}
        className={`${classes.Pagination__btn} ${classes.Pagination__btn__next}`}
        data-page="next"
        onClick={clickHandler}
      >
        <span>Next</span>
        <ChevronRight />
      </button>
    </div>
  );
};

export default Pagination;
