import CreditCard from "../../assets/images/credit_card.png";
import Copy from "../../assets/svg/Copy";
import { phoneNumberFormat, zipCodeFormat } from "../../assets/helper";

import classes from "./EnrollmentDetail.module.scss";

const Details = ({ enrollment }) => {
  if (!enrollment) {
    return <div>Loading please wait...</div>;
  }

  const {
    id,
    first,
    last,
    dob,
    extra_embossing,
    identification_type,
    status,
    card,
    card_number,
    card_account,
    location,
    email,
    alternate_email,
    physical_address,
    mailing_address,
    phones,
  } = enrollment;

  const copyHandler = (event) => {
    const copyText = event.target.attributes["data-value"].value;

    navigator.clipboard.writeText(copyText);
  };

  return (
    <>
      <div className={`${classes.Row} ${classes.Heading} ${classes[status]}`}>
        <div className={classes.Col}>
          <img className={classes.Img} src={CreditCard} alt="card" />
        </div>

        <div className={classes.Col}>
          <p className={classes.Text}>
            <b>Confirmation:</b> {id}
          </p>
          <p className={classes.Text}>
            <b>Name:</b> {first} {last}
          </p>
          <p className={classes.Text}>
            <b>DOB:</b> {dob}
          </p>
          <p className={classes.Text}>
            <b>ID Type:</b>{" "}
            <span style={{ textTransform: "uppercase" }}>
              {identification_type}
            </span>
          </p>
        </div>

        <div className={classes.Col}>
          <p className={classes.Text}>
            <b>Status: {status}</b>
          </p>

          <p className={classes.Text}>
            <b>Card Name:</b> {card.name}
          </p>

          { extra_embossing && (
            <p className={classes.Text}>
              <b>Extra Embossing:</b> {extra_embossing}
            </p>
          )}

          <p className={classes.Text}>
            <b>Location Name:</b> {location.name}
          </p>
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col} />
        <div className={classes.Col}>
          <p className={classes.Text}>
            <b>Email(s):</b>
          </p>

          <p className={classes.Text}>
            <b>Primary:</b> {email}
          </p>
          { alternate_email && (
            <p className={classes.Text}>
              <b>Alternate:</b> {alternate_email}
            </p>
          )}
        </div>

        <div className={classes.Col}>
          <p className={classes.Text}>
            <b>Account Details: </b>
          </p>

          { card_number && (
            <div className={classes.Copy}>
              <p className={classes.Text}>
                Instant Issue Number: {card_number}
                <span
                  className={classes.Copy__icon}
                  data-value={card_number}
                  onClick={copyHandler}
                >
                  <Copy />
                </span>
              </p>
            </div>
          )}

          <div className={classes.Copy}>
            <p className={classes.Text}>
              Routing Number: {card.routing_number}
              <span
                className={classes.Copy__icon}
                data-value={card.routing_number}
                onClick={copyHandler}
              >
                <Copy />
              </span>
            </p>
          </div>

          <div className={classes.Copy}>
            <p className={classes.Text}>
              Account Number: {card_account}
              <span
                className={classes.Copy__icon}
                data-value={card_account}
                onClick={copyHandler}
              >
                <Copy />
              </span>
            </p>
          </div>
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col} />
        <div className={classes.Col}>
          <p className={classes.Text}>
            <b>Physical Address:</b>
          </p>

          <p className={classes.Text}>{physical_address.address}</p>
          <p className={classes.Text}>{physical_address.address_2}</p>
          <p className={classes.Text}>
            {physical_address.city}, {physical_address.state},{" "}
            {zipCodeFormat(physical_address.zip)}
          </p>
        </div>

        <div className={classes.Col}>
          <p className={classes.Text}>
            <b>Mailing Address:</b>
          </p>

          <p className={classes.Text}>{mailing_address.address}</p>
          <p className={classes.Text}>{mailing_address.address_2}</p>
          <p className={classes.Text}>
            {mailing_address.city}, {mailing_address.state},{" "}
            {zipCodeFormat(mailing_address.zip)}
          </p>
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col} />

        <div className={classes.Col}>
          {phones.map((item) => (
            <p key={item.phone_number} className={classes.Text}>
              <b style={{ textTransform: "capitalize" }}>{item.phone_type}:</b>{" "}
              {phoneNumberFormat(item.phone_number)}
            </p>
          ))}
        </div>

        <div className={classes.Col} />
      </div>
    </>
  );
};

export default Details;
