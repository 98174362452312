import React, { useMemo, useState, useEffect, useCallback, useRef } from "react";
import WaitModal from "../Enroll/WaitModal";
import ReactDOM from "react-dom";
import Notification from "../UI/Notification/Notification";
import useNotification from "../../hooks/useNotification";
import { useNavigate } from "react-router-dom";
import is from "is_js";
import imageCompression from 'browser-image-compression';
import useInput from "../../hooks/useInput";
import classes from "./AddLocationForm.module.scss";
import { phoneNumberFormat } from "../../../src/assets/helper"
import Space from "../UI/Space/Space";
import Select from "../UI/Select/Select";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import Checkbox from "../UI/Checkbox/Checkbox";
import { ErrorMessageProviderBuilder } from "../UI/Input/ErrorMessageProviderBuilder.ts";
import { getCBOs, getCardTypes, getUsers, createLocationAdmin } from "../../api/api";
import PDF from "../../assets/images/pdf.svg"
import Cancel from "../../assets/svg/Cancel"

const fillStates = () => {
  const statesJson = require("../../assets/json/us-states.json");

  const options = [];

  for (let key in statesJson) {
    options.push({
      value: key,
      label: statesJson[key],
      isDisabled: false,
    });
  }

  options.unshift({
    value: "0",
    label: "Select...",
    isDisabled: true,
  });

  return options;
};

const AddLocationForm = () => {
  const navigate = useNavigate();

  /* notification things */
  const [showWaitModal, setShowWaitModal] = useState(false);
  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: showNotification,
    add: addNotification,
    remove: removeNotification,
  } = useNotification();

  /* State */
  const stateOptions = fillStates();
  const [physicalState, setPhysicalState] = useState({value: stateOptions[0].value, hasError: false})

  const physicalStateIsValid = useCallback(() => {
    return physicalState.value && physicalState.value !== "0";
  }, [physicalState.value])

  const changePhysicalStateHandler = (e) => {
    const stateValue = e.target[e.target.selectedIndex].value
    const stateError = !stateValue || stateValue === "0"
    setPhysicalState({value: stateValue, hasError: stateError})
  }

  /* Organization */
  const [CBOs, setCBOs] = useState([{
    label: '<Create a New CBO>',
    value: 'create_new'
  }]);

  useEffect(() => {
    if (CBOs.length > 1) return;
    getCBOs('active').then((cbos) => {
      const mapped = cbos.map(cbo => {
        return {
          label: cbo.name,
          value: cbo.id
        }
      })
      mapped.unshift({
        label: '<Create a New CBO>',
        value: 'create_new'
      })
      setCBOs(mapped);
    })
  }, [CBOs.length])

  const [organization, setOrganization] = useState({value: CBOs[0].value, hasError: false})

  /* New organization Name */
  const newOrgName = useInput((value) => 
    (value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm)) ||
    (organization.value && organization.value !== "create_new") // not needed if using existing org
  )
  newOrgName.label = "New Organization Name*"
  newOrgName.errorMessageProvider = new ErrorMessageProviderBuilder('This').isntEmpty().isAlphanumeric().build()

  /* Location Name */
  const locationName = useInput((value) => value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm))
  locationName.label = "Location Name*"
  locationName.errorMessageProvider = new ErrorMessageProviderBuilder('Location Name').isntEmpty().isAlphanumeric().build()

  /* Location ID */
  const locationID = useInput((value) => value.trim() !== "" && value.match(/^[a-zA-Z0-9_ ]+$/gm))
  locationID.label = "Location ID*"
  locationID.errorMessageProvider = new ErrorMessageProviderBuilder('Location ID').isntEmpty().addCondition(
    (value, field) => {
      if(!value.match(/^[a-zA-Z0-9_ ]+$/gm)) {
        return `${field} must be alphanumeric or underscore`
      }
    }).build()

  const upperCase = (value) => {
    value = value.toUpperCase()
    return value.replace(/_Z{1,}/, "")
  }

  locationID.value = upperCase(locationID.value)

  /* Address fields */
  const address = useInput((value) => value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm) && value.length <= 30)
  address.label = "Address*"
  address.errorMessageProvider = new ErrorMessageProviderBuilder('Address').isntEmpty().isAlphanumeric().isUpTo(30).build()

  const address_2 = useInput((value) => value.trim() === "" || (value.match(/^[a-zA-Z0-9 ]+$/gm) && value.length <= 30))
  address_2.placeholder = "line 2 (optional)"
  address_2.errorMessageProvider = new ErrorMessageProviderBuilder('Address line 2').isAlphanumeric().isUpTo(30).build()

  const city = useInput((value) => value.trim() !== "" && value.length >= 2 && value.length <= 50 && value.match(/^[a-zA-Z0-9 ]+$/gm))
  city.errorMessageProvider = new ErrorMessageProviderBuilder('City').isntEmpty().isAlphanumeric().isLength(2, 'characters').build()
  city.label = "City*"

  const zipcode = useInput((value) => value.trim().length === 5 && value.match(/^[0-9]+$/gm))
  zipcode.errorMessageProvider = new ErrorMessageProviderBuilder('Zip Code').isntEmpty().isNumeric().isLength(5, 'digits').build()
  zipcode.label = "Zip Code*"
  zipcode.maxLength = 5

  /* Phone Number */
  const phoneNumber = useInput((value) => value.replace(/[^\d]/g, "").length >= 10)
  phoneNumber.type = "phone"
  phoneNumber.label = "Phone Number*"
  phoneNumber.placeholder = "(111) 222-3333"
  phoneNumber.value = phoneNumberFormat(phoneNumber.value)
  phoneNumber.errorMessageProvider = () => {
    if (!phoneNumber.value) return "Phone Number is a required field"
    else if (phoneNumber.value.replace(/[^\d]/g, "").length !== 10) return "Enter a valid phone number"
  }

  /* Extra Embossing */
  const extraEmbossing = useInput((value) => value.trim() === "" || value.match(/^[a-zA-Z0-9 ]+$/gm))
  extraEmbossing.label = "Extra Embossing (up to 25 alphanumeric chars)"
  extraEmbossing.placeholder = "My New Location"
  extraEmbossing.maxLength = 25
  extraEmbossing.errorMessageProvider = new ErrorMessageProviderBuilder('Extra embossing').isAlphanumeric().build()
  extraEmbossing.value = upperCase(extraEmbossing.value)

  /* Limit Enrollments */
  const [limitEnroll, setLimitEnroll] = useState(false);

  const maximumEnroll = useInput((value) => (
    value.trim() !== "" && value.match(/^[0-9]+$/gm)) ||
    !limitEnroll // not needed if not limit
  )
  maximumEnroll.type = "number"
  maximumEnroll.placeholder = "Maximum Enrollments"
  maximumEnroll.errorMessageProvider = new ErrorMessageProviderBuilder('Enrollment limit').isntEmpty().isNumeric().build()

  /* Enable Self-enrollment */
  const [enableSelfEnroll, setEnableSelfEnroll] = useState(false);

  const website = useInput((value) => (
    value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm)) ||
    !enableSelfEnroll // not needed if self enrollment is disabled
  )
  website.label = "Web site address:"
  website.errorMessageProvider = new ErrorMessageProviderBuilder('Web site').isntEmpty().isAlphanumeric().build()

  const supportEmail = useInput((value) => (
    value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm)) ||
    !enableSelfEnroll // not needed if self enrollment is disabled
  )
  supportEmail.label = "Support email:"
  supportEmail.errorMessageProvider = new ErrorMessageProviderBuilder('Support email').isntEmpty().isAlphanumeric().build()

  const cardName = useInput((value) => (
    value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm)) ||
    !enableSelfEnroll // not needed if self enrollment is disabled
  )
  cardName.label = "Card name:"
  cardName.errorMessageProvider = new ErrorMessageProviderBuilder('Card name').isntEmpty().isAlphanumeric().build()

  const siteBanner = useInput((value) => (
    value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm)) ||
    !enableSelfEnroll // not needed if self enrollment is disabled
  )
  siteBanner.label = "Site banner:"
  siteBanner.errorMessageProvider = new ErrorMessageProviderBuilder('Site banner').isntEmpty().isAlphanumeric().build()

  /* File Uploads */
  const siteLogoOptions = {
    id: "site_logo",
    label: "Site logo: (ideal size 250x50px)",
    btn_text: "Add Site Logo",
    img_alt: "site logo upload",
    accept: "image/*",
  }
  const cardImageOptions = {
    id: "card_image",
    label: "Card image: (ideal size 656x416)",
    btn_text: "Add Card Image",
    img_alt: "card image upload",
    accept: "image/*",
  }
  const TC_PDF_Options = {
    id: "tc_pdf",
    label: "Custom Terms & Conditions PDF:",
    btn_text: "Add T&Cs",
    img_alt: "pdf icon",
    accept: "application/pdf",
  }

  const [fileCardImage, setFileCardImage] = useState(cardImageOptions);
  const [fileSiteLogo, setFileSiteLogo] = useState(siteLogoOptions);
  const [fileTC_PDF, setFileTC_PDF] = useState(TC_PDF_Options);

  const inputCardImage = useRef()
  const inputSiteLogo = useRef()
  const inputTC_PDF = useRef()

  const removeFile = (targetID) => {
    if (targetID === 'site_logo') {
      setFileSiteLogo(siteLogoOptions)
      inputSiteLogo.current.value = ""
    } else if (targetID === 'card_image') {
      setFileCardImage(cardImageOptions)
      inputCardImage.current.value = ""
    } else if (targetID === 'tc_pdf') {
      setFileTC_PDF(TC_PDF_Options)
      inputTC_PDF.current.value = ""
    }
  }

  const onFileSelected = (event) => {
    const file = event.target.files[0]
    if (!file || (!file.type.startsWith("image") && file.type !== "application/pdf")) return

    if (event.target.id === 'site_logo') setFileSiteLogo({
      ...fileSiteLogo,
      preview_link: URL.createObjectURL(file),
      file: file
    })
    else if (event.target.id === 'card_image') setFileCardImage({
      ...fileCardImage,
      preview_link: URL.createObjectURL(file),
      file: file
    })
    else if (event.target.id === 'tc_pdf') setFileTC_PDF({
      ...fileTC_PDF,
      preview_link: PDF,
      file: file
    })
  }

  /* Primary / Backup Contacts */
  const contactRoleOptions = [{
    value: "coordinator",
    label: "Coordinator",
  }, {
    value: "super_coordinator",
    label: "Super Coordinator",
  }]

  const initialRoleState = {
    value: contactRoleOptions[0].value,
    hasError: false
  }

  const initialContactOption = useMemo (() =>  [{
    value: "invite",
    label: "<Invite contact>",
  }], [])

  const [contactOptions, setContactOptions] = useState(initialContactOption)

  const initialContactState = useMemo (() => ({
    value: initialContactOption[0].value,
    hasError: false
  }), [initialContactOption])

  useEffect (() => {
    setPrimaryContact(initialContactState)
    setBackupContact(initialContactState)
    const orgID = parseInt(organization.value)
    if (orgID) {
      getUsers({cbo_id: orgID, perPage: -1}).then(users => {
        const mapped = users.items.map(user => {
          return {
            label: `${user.name} (${user.email})`,
            value: user.id
          }
        })
        mapped.unshift(initialContactOption[0])
        setContactOptions(mapped)
      })
    } else {
      setContactOptions(initialContactOption)
    }
  }, [organization.value, initialContactOption, initialContactState])

  const [primaryContact, setPrimaryContact] = useState(initialContactState)
  const [backupContact, setBackupContact] = useState(initialContactState)
  const [primaryContactRole, setPrimaryContactRole] = useState(initialRoleState)
  const [backupContactRole, setBackupContactRole] = useState(initialRoleState)
  const [isNewPrimary, setIsNewPrimary] = useState(true)
  const [isNewBackup, setIsNewBackup] = useState(true)

  useEffect(() => {
    setIsNewPrimary(primaryContact.value && primaryContact.value === "invite")
  }, [primaryContact.value])

  useEffect(() => {
    setIsNewBackup(backupContact.value && backupContact.value === "invite")
  }, [backupContact.value])

  const primaryContactName = useInput((value) => (value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm)) || !isNewPrimary)
  const backupContactName = useInput((value) => (value.trim() !== "" && value.match(/^[a-zA-Z0-9 ]+$/gm)) || !isNewBackup)
  primaryContactName.label = backupContactName.label = "Contact Name*"
  primaryContactName.errorMessageProvider = backupContactName.errorMessageProvider = new ErrorMessageProviderBuilder('Contact Name').isntEmpty().isAlphanumeric().build()

  const primaryContactEmail = useInput((value) => (is.email(value) && value.length < 50) || !isNewPrimary)
  const backupContactEmail = useInput((value) => (is.email(value) && value.length < 50) || !isNewBackup)
  primaryContactEmail.label = backupContactEmail.label = "Contact Email*"
  primaryContactEmail.errorMessageProvider = backupContactEmail.errorMessageProvider = new ErrorMessageProviderBuilder('Contact Email').isntEmpty().isValidEmail().isUpTo(50).build()
  
  const primaryContactPhone = useInput((value) => value === "" || value.replace(/[^\d]/g, "").length >= 10 || !isNewPrimary)
  const backupContactPhone = useInput((value) => value === "" || value.replace(/[^\d]/g, "").length >= 10 || !isNewBackup)
  primaryContactPhone.type = backupContactPhone.type = "phone"
  primaryContactPhone.label = backupContactPhone.label = "Contact Phone"
  primaryContactPhone.placeholder = backupContactPhone.placeholder = "(111) 222-333"
  
  primaryContactPhone.value = phoneNumberFormat(primaryContactPhone.value)
  primaryContactPhone.errorMessageProvider = () => {
    if (primaryContactPhone.value !== "" && primaryContactPhone.value.replace(/[^\d]/g, "").length !== 10) return "Enter a valid phone number"
  }

  backupContactPhone.value = phoneNumberFormat(backupContactPhone.value)
  backupContactPhone.errorMessageProvider = () => {
    if (backupContactPhone.value !== "" && backupContactPhone.value.replace(/[^\d]/g, "").length !== 10) return "Enter a valid phone number"
  }

  const [enablePrimaryFileUpload, setEnablePrimaryFileUpload] = useState(false);
  const [enableBackupFileUpload, setEnableBackupFileUpload] = useState(false);

  const primaryContactFields = {name: primaryContactName, email: primaryContactEmail, phone: primaryContactPhone,
    role: primaryContactRole, setRole: setPrimaryContactRole, file: enablePrimaryFileUpload, setFile: setEnablePrimaryFileUpload}
  const backupContactFields = {name: backupContactName, email: backupContactEmail, phone: backupContactPhone,
    role: backupContactRole, setRole: setBackupContactRole, file: enableBackupFileUpload, setFile: setEnableBackupFileUpload}

  const inputsArray = useMemo (() => 
    [newOrgName, locationName, locationID, address, address_2, city, zipcode, phoneNumber, extraEmbossing, maximumEnroll,
    primaryContactName, backupContactName, primaryContactEmail, backupContactEmail, primaryContactPhone, backupContactPhone,
    website, supportEmail, cardName, siteBanner],
    [newOrgName, locationName, locationID, address, address_2, city, zipcode, phoneNumber, extraEmbossing, maximumEnroll,
    primaryContactName, backupContactName, primaryContactEmail, backupContactEmail, primaryContactPhone, backupContactPhone,
    website, supportEmail, cardName, siteBanner]
  )

  /* Cards */
  const cardGroups = [{
    id: 'standard', label: 'Standard Cards'
  }, {
    id: 'instant_issue', label: 'Instant Issue Cards'
  }]

  const initialCardOption = useMemo (() => [{
    label: "Select...",
    value: "0",
    isDisabled: true
  }], [])

  const [standardCardTypes, setStandardCardTypes] = useState(initialCardOption)
  const [instantCardTypes, setInstantCardTypes] = useState(initialCardOption)

  const initialCard = useMemo (() => [{
    id: `card-${Math.random()}`,
    options: standardCardTypes,
    value: standardCardTypes[0].value,
    group: 'standard',
    hasError: false
  }, {
    id: `card-${Math.random()}`,
    options: instantCardTypes,
    value: instantCardTypes[0].value,
    group: 'instant_issue',
    hasError: false
  }], [standardCardTypes, instantCardTypes])
  const [cards, setCards] = useState(initialCard);

  useEffect(() => {
    // if we populated the card types, initialCard has updated and we need to update the default cards with the new options
    if (standardCardTypes.length > 1 && instantCardTypes.length > 1) setCards(initialCard)
    // else we fetch the card types
    else getCardTypes().then(cardTypes => {
      const mappedInstant = cardTypes.instant_cards.map(card => {
        return {
          label: card.name,
          value: card.id
        }
      })
      const mappedStandard = cardTypes.standard_cards.map(card => {
        return {
          label: card.name,
          value: card.id
        }
      })
      const mappedTypes = [mappedInstant, mappedStandard]
      mappedTypes.forEach(mappedType => {
        mappedType.unshift(initialCardOption[0])
        mappedType.push({
          label: "Remove",
          value: "remove"
        })
      })
      setInstantCardTypes(mappedInstant)
      setStandardCardTypes(mappedStandard)
    })
  }, [standardCardTypes.length, instantCardTypes.length, initialCard, initialCardOption])

  const changeCardHandler = (e) => {
    const cardValue = e.target[e.target.selectedIndex].value
    let cardsUpdated
    if (cardValue === 'remove') cardsUpdated = cards.filter(card => card.id !== e.target.id)
    else cardsUpdated = cards.map(card => {
      if (card.id === e.target.id) return {
        ...card,
        value: cardValue,
        hasError: !cardValue || cardValue === "0"
      }
      else return card
    })
    setCards(cardsUpdated)
  }

  const cardIsValid = useCallback(() => {
    if (!cards.length) return false
    let isValid = true
    cards.forEach(card => {
      if (!card.value || card.value === "0") isValid = false
    })
    return isValid
  }, [cards])

  const setCardError = () => {
    const cardsUpdated = cards.map(card => {
      if (!card.value || card.value === "0") return {
        ...card,
        hasError: true
      }
      else return card
    })
    setCards(cardsUpdated)
  }

  const addCardHandler = (group) => {
    setCards((prevState) => {
      // IMPORTANT -> reset the id to make sure it's different
      let newCard = {}
      if (group.id === "standard") newCard = {...initialCard[0], id: `card-${Math.random()}`}
      else if (group.id === "instant_issue") newCard = {...initialCard[1], id: `card-${Math.random()}`}
      return [...prevState, newCard];
    });
  };

  /* Select Handler */
  const changeSelectionHandler = (e, setFunction) => {
    const valueToSet = e.target[e.target.selectedIndex].value
    setFunction({value: valueToSet, hasError: !valueToSet})
  }

  /* Compress and Encode Images */
  const compressAndEncodeFile = async (originalFile) => {
    console.log('originalFile instanceof Blob', originalFile instanceof Blob); // true
    console.log(`originalFile size ${originalFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 0.250,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    }

    try {
      let compressedFile = originalFile
      if (['image/png', 'image/jpeg'].includes(originalFile.type)) compressedFile = await imageCompression(originalFile, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
      if (!(compressedFile instanceof Blob)) return;

      const filePromises = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile)
        reader.onloadend = async function () {
          const readerResult = reader.result 
          /* removed so the content type would be included with the file
                .replace('data:image/jpeg;base64,', '')
                .replace('data:image/png;base64,', '')
                .replace('data:application/pdf;base64,', '') 
          */
          resolve(readerResult)
        }
        reader.onerror = (error) => reject(error);
      })
      const fileResult = await Promise.all([filePromises]);
      return fileResult[0]
    } catch (error) {
      console.log(error);
    }
  }

  /* Form Valid */
  const [formIsValid, setformIsValid] = useState(false);

  const resetForm = () => {
    // reset selects
    setPhysicalState({value: stateOptions[0].value, hasError: false})
    setOrganization({value: CBOs[0].value, hasError: false})
    setPrimaryContact(initialContactState)
    setPrimaryContactRole(initialRoleState)
    setBackupContact(initialContactState)
    setBackupContactRole(initialRoleState)
    setCards(initialCard) // reset cards
    // reset checkboxes
    setLimitEnroll(false)
    setEnableSelfEnroll(false)
    setEnablePrimaryFileUpload(false)
    setEnableBackupFileUpload(false)
    // reset inputs
    inputsArray.forEach(field => {
      field.reset()
    })
    // reset file upload fields
    setFileCardImage(cardImageOptions);
    setFileSiteLogo(siteLogoOptions);
    setFileTC_PDF(TC_PDF_Options);
  }

  /* Submit */
  useEffect(() => {
    const inputsArrayValid = inputsArray.map(field => field.isValid)
    const isValid = inputsArrayValid.every(Boolean) && physicalStateIsValid() && organization.value && cardIsValid()
    if (isValid) setformIsValid(true);
    else setformIsValid(false);
  }, [inputsArray, physicalStateIsValid, organization.value, cardIsValid])

  const submitHandler = async (event) => {
    event.preventDefault();

    const validateForTesting = true

    if (!formIsValid) {
      inputsArray.forEach(field => {
        if (!field.isValid) field.inputBlurHandler()
      })

      if (!physicalStateIsValid()) {
        setPhysicalState({...physicalState, hasError: true})
      }

      if (!organization.value) {
        setOrganization({...organization, hasError: true})
      }

      if (!cardIsValid()) setCardError()

      // resetForm()
      console.log('form not ok')
    } else {
      const newLocation = {
        organization_id: organization.value === "create_new" ? undefined : organization.value,
        organization_name: organization.value === "create_new" ? newOrgName.value : undefined,
        location_name: locationName.value,
        location_id: locationID.value,
        address: {
          address: address.value,
          address_2: address_2.value,
          city: city.value,
          state: physicalState.value,
          zipcode: zipcode.value,
        },
        phone: phoneNumber.value,
        enabled_cards: cards.map(card => {
          return (card.value)
        }),
        extra_embossing: extraEmbossing.value,
        limit_enroll: limitEnroll ? maximumEnroll.value : -1, // -1 is no limit
        enable_self_enroll: enableSelfEnroll,
        primary_contact: primaryContact.value === "invite" ? {
          name: primaryContactName.value,
          email: primaryContactEmail.value,
          role: primaryContactRole.value,
          phone: primaryContactPhone.value,
          file: enablePrimaryFileUpload,
          new: true
        } : undefined,
        primary_contact_id: primaryContact.value === "invite" ? undefined : primaryContact.value,
        backup_contact: backupContact.value === "invite" ? {
          name: backupContactName.value,
          email: backupContactEmail.value,
          role: backupContactRole.value,
          phone: backupContactPhone.value,
          file: enableBackupFileUpload,
          new: true
        } : {
          id: backupContact.value,
          new: false
        },
        backup_contact_id: backupContact.value === "invite" ? undefined : backupContact.value,
      }

      if (enableSelfEnroll) {
        newLocation.self_enroll_fields = {
          website: website.value,
          support_email: supportEmail.value,
          card_name: cardName.value,
          site_banner: siteBanner.value,
          files: {
            site_logo: fileSiteLogo.file ? await compressAndEncodeFile(fileSiteLogo.file) : null,
            card_image: fileCardImage.file ? await compressAndEncodeFile(fileCardImage.file) : null,
            tc_pdf: fileTC_PDF.file ? await compressAndEncodeFile(fileTC_PDF.file) : null
          }
        }
      }

      if (!validateForTesting) resetForm()

      // console.log(newLocation)
      setShowWaitModal(true);
      try {
        await createLocationAdmin(newLocation);
        window.scrollTo(0, 0)
        // addNotification({
        //   status: "success",
        //   text: `Location has been updated`,
        //   btn_text: "OK",
        // });
        navigate('/locations');
      } catch (e) {
        addNotification({
          status: "error",
          text: `Unable to add this location. ${e}`,
          btn_text: "OK",
        });
  
        setTimeout(() => {
          removeNotification();
        }, 5000);
        setShowWaitModal(false);
        return;
      }
      setShowWaitModal(false);

    }
  }

  /* Form Html Builder */
  const inputFieldHandler = (field) => {
    return <Input
      {...field} // passing all properties works for the ones named the same
      onChange={e => { // pass properties individually when names don't match
        field.inputChangeHandler(e);
        if (field.secondInputChangeHandler) field.secondInputChangeHandler()}
      }
      onBlur={field.inputBlurHandler}
    />
  }

  const singleCardHtml = (group, i) => {
    const cardGroup = cards.filter(card => card.group === group.id)
    return (
      <div className={classes.Col__50} key={i}>
        {cardGroup.map((card, index) => {
          return (<React.Fragment key={card.id}>
            {index > 0 ? <Space value="20" /> : ''}
            <Select
              label={index === 0 ? `${group.label}*` : ''}
              id={card.id}
              options={card.options}
              value={card.value}
              hasError={card.hasError}
              onChange={changeCardHandler}
              errorMessageProvider={() => { if (card.hasError) return "Please select a card"} }
            />
          </React.Fragment>)
        })}

        {!cardGroup.length && (
          <div className={classes.Label}>{group.label}*
            {!cards.length && (
              <span className={classes.Error_label}>At least one card is required</span>
            )}
          </div>
        )}

        <Space value="20" />

        {cardGroup.length >= 4 ? (
          ""
        ) : (
          <span className={classes.Action} onClick={()=>addCardHandler(group)}>
            + Additional Card Type
          </span>
        )}
      </div>
  )};

  const allCardsHtml = (
    <>
    <div className={classes.Row}>
      {cardGroups.map((group, i) => {
        return singleCardHtml(group, i)
      })}
    </div>
    </>
  )

  const allContactFields = (contact) => {
    return (
    <>
      {inputFieldHandler(contact.name)}
      {inputFieldHandler(contact.email)}
      <Space value="20" />

      <Select
        label="Role*"
        options={contactRoleOptions}
        value={contact.role.value}
        hasError={contact.role.hasError}
        onChange={(e) => changeSelectionHandler(e, contact.setRole)}
      />

      <Space value="20" />
      {inputFieldHandler(contact.phone)}
      <Space value="20" />

      <Checkbox
        label="Enable file upload"
        value={contact.file}
        onChange={() => contact.setFile(!contact.file)}
      />
    </>
  )}

  const physicalAddress = (
    <>
      <div className={classes.Row}>
        <div className={classes.Col__100}>
          {inputFieldHandler(address)}
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col__100}>
          {inputFieldHandler(address_2)}
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col__50}>
          {inputFieldHandler(city)}
        </div>

        <div className={classes.Col__50}>
          <Select
            label="State*"
            options={stateOptions}
            value={physicalState.value}
            hasError={physicalState.hasError}
            onChange={changePhysicalStateHandler}
            errorMessageProvider={() => { if (!physicalStateIsValid()) return "Please select a state"} }
          />
        </div>
      </div>

      <div className={classes.Row}>
        <div className={classes.Col__50}>
          {inputFieldHandler(zipcode)}
        </div>

        <div className={classes.Col__50}>
          {inputFieldHandler(phoneNumber)}
        </div>
      </div>
    </>
  );

  const uploadField = (file, inputRef) => (
    <div className={classes.Col__50}>
      <div className={classes.Label}>{file.label}</div>

      <Space value="15" />

      <div className={classes.Upload}>
        <Button color="primary" type="button" onClick={() => inputRef.current.click()}>
          {file.btn_text}
        </Button>

        <div className={classes.Upload_files}>
          <input type='file' accept={file.accept} id={file.id} ref={inputRef} onChange={(e) => {onFileSelected(e)}}/>
          {file.preview_link && (
            <div className={classes.Upload_files_preview}>
              <img src={file.preview_link} alt={file.img_alt} onClick={() => inputRef.current.click()} />
              <span onClick={() => removeFile(file.id)}>
                <Cancel />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className={classes.Row}>
          <div className={classes.Col__50}>
            <Select
              label="Organization*"
              options={CBOs}
              value={organization.value}
              hasError={organization.hasError}
              onChange={(e) => changeSelectionHandler(e, setOrganization)}
            />
          </div>

          <div className={classes.Col__50}>
            {organization.value === "create_new" &&
            inputFieldHandler(newOrgName)}
          </div>
        </div>

        <div className={classes.Row}>
          <div className={classes.Col__50}>
            {inputFieldHandler(locationName)}
          </div>

          <div className={classes.Col__50}>
            {inputFieldHandler(locationID)}

            <Space value="20" />
          </div>
        </div>

        <Space value="-20" />

        {physicalAddress}

        {allCardsHtml}

        <div className={classes.Row}>
          <div className={classes.Col__100}>
            {inputFieldHandler(extraEmbossing)}
          </div>
        </div>

        <div className={classes.Row}>
          <div className={classes.Col__100}>
            <Checkbox
              label="Limit the number of enrollments for this location"
              value={limitEnroll}
              onChange={() => setLimitEnroll(!limitEnroll)}
            />
          </div>
        </div>

        {limitEnroll && (
          <div className={`${classes.Row} ${classes.Row_narrow}`}>
            <div className={classes.Col__50}>
              {inputFieldHandler(maximumEnroll)}
            </div>
          </div>
        )}

        <div className={classes.Row}>
          <div className={classes.Col__100}>
            <Checkbox
              label="Enable self-enrollment site for this location using single-use links with data posted directly to US Bank"
              value={enableSelfEnroll}
              onChange={() => setEnableSelfEnroll(!enableSelfEnroll)}
            />
          </div>
        </div>

        {enableSelfEnroll && (<>
          <div className={`${classes.Row} ${classes.Row_narrow}`}>
            <div className={`${classes.Col__50} ${classes.Col__side_label}`}>
              <span>enrollcfr.org/</span>{inputFieldHandler(website)}
            </div>
            <div className={`${classes.Col__50} ${classes.Col__side_label}`}>
              {inputFieldHandler(supportEmail)}<span>@enrollcfr.org</span>
            </div>
          </div>

          <div className={`${classes.Row} ${classes.Row_narrow}`}>
            <div className={classes.Col__50}>
              {inputFieldHandler(cardName)}
            </div>
            <div className={classes.Col__50}>
              {inputFieldHandler(siteBanner)}
            </div>
          </div>

          <div className={`${classes.Row} ${classes.Row_narrow}`}>
            {fileSiteLogo && uploadField(fileSiteLogo, inputSiteLogo)}

            {fileCardImage && uploadField(fileCardImage, inputCardImage)}
          </div>

          <div className={`${classes.Row} ${classes.Row_narrow}`}>
            {fileTC_PDF && uploadField(fileTC_PDF, inputTC_PDF)}
          </div>
        </>)}

        <div className={classes.Row}>
          <div className={classes.Col__50}>
            <Select
              label="Primary Contact:"
              options={contactOptions}
              value={primaryContact.value}
              hasError={primaryContact.hasError}
              onChange={(e) => changeSelectionHandler(e, setPrimaryContact)}
            />

            <Space value="20" />

            {isNewPrimary && allContactFields(primaryContactFields)}
          </div>

          <div className={classes.Col__50}>
            <Select
              label="Backup Contact:"
              options={contactOptions}
              value={backupContact.value}
              hasError={backupContact.hasError}
              onChange={(e) => changeSelectionHandler(e, setBackupContact)}
            />

            <Space value="20" />

            {isNewBackup && allContactFields(backupContactFields)}
          </div>
        </div>

        <Space value="20" />

        <Button color="primary" type="submit">
          Add Location
        </Button>
        {showWaitModal &&
          ReactDOM.createPortal(<WaitModal message="Please wait while we create this location." />,
            document.querySelector("body"))}
        {showNotification &&
          ReactDOM.createPortal(
            <Notification
              status={notificationStatus}
              text={notificationText}
              btn_text={notificationBtnText}
              onClose={() => removeNotification()}
            />,
          document.querySelector("body")
        )}
      </form>
    </>
  )
}

export default AddLocationForm