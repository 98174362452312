import { useMemo } from "react";
import ReactDOM from "react-dom";
import { fetchLocations } from "../../../api/fetch.ts";
import CommonTable from "../Common/Table"
import { Box } from "@mui/system";
import { IconButton } from '@mui/material';
import { deactivateLocations } from "../../../api/api";
import useNotification from "../../../hooks/useNotification";
import Notification from "../../UI/Notification/Notification";
import { useTrigger } from "../../../hooks/useTrigger";


const LocationTable = ({ bodyParams, rowSelection, setRowSelection }) => {
    const {
        status: notificationStatus,
        text: notificationText,
        btn_text: notificationBtnText,
        isShow: notificationIsShow,
        add: addNotification,
        remove: removeNotification,
    } = useNotification();

    const {trigger: refreshTable, triggerValue: onRefreshTable} = useTrigger.parent();

    const closeNotificationHandler = () => {
        removeNotification();
    };

    const handleDeactivate = async (event, row) => {
        event.stopPropagation();
        console.log("Deactivate")
        const deactivateResult = await deactivateLocations({location_ids: [row.original.id]});
        if(deactivateResult.status === 'success') {
            addNotification({
                text: "Location deactivated successfully",
                btn_text: 'OK',
                status: "success",
            });
            refreshTable();
        }else {
            addNotification({
                text: deactivateResult.response.message,
                btn_text: 'OK',
                status: "error",
            })
        }
        console.log(deactivateResult)
    }

    const rowActions = ( row ) => [
        <>
        <Box display="flex">
            {row.original.inactive || (<>
            <IconButton >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
              >
                  <path
                      d="M4.5 19.4998H5.6L16.675 8.4248L15.575 7.3248L4.5 18.3998V19.4998ZM19.85 7.3498L16.65 4.1498L17.7 3.0998C17.9833 2.81647 18.3333 2.6748 18.75 2.6748C19.1667 2.6748 19.5167 2.81647 19.8 3.0998L20.9 4.1998C21.1833 4.48314 21.325 4.83314 21.325 5.2498C21.325 5.66647 21.1833 6.01647 20.9 6.2998L19.85 7.3498ZM18.8 8.3998L6.2 20.9998H3V17.7998L15.6 5.1998L18.8 8.3998ZM16.125 7.8748L15.575 7.3248L16.675 8.4248L16.125 7.8748Z"
                      fill="black"
                      fill-opacity="0.87"
                  />
              </svg>
            </IconButton>
            <IconButton onClick={(event) => handleDeactivate(event, row)} >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
              >
                  <path
                      d="M6.525 21C6.10833 21 5.75417 20.8542 5.4625 20.5625C5.17083 20.2708 5.025 19.9167 5.025 19.5V5.25H4V3.75H8.7V3H15.3V3.75H20V5.25H18.975V19.5C18.975 19.9 18.825 20.25 18.525 20.55C18.225 20.85 17.875 21 17.475 21H6.525ZM17.475 5.25H6.525V19.5H17.475V5.25ZM9.175 17.35H10.675V7.375H9.175V17.35ZM13.325 17.35H14.825V7.375H13.325V17.35ZM6.525 5.25V19.5V5.25Z"
                      fill="black"
                      fill-opacity="0.87"
                  />
              </svg>
            </IconButton></>)}
        </Box>
        </>
    ]

    const columns = useMemo(
        () => [
            {
                accessorKey: 'location',
                header: 'Location / Location ID',
                size: 200,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue()[0]}
                        <Box
                            sx={(theme) => ({
                                opacity: 0.6,
                                fontSize: '0.75rem'
                            })}
                        >
                            {cell.getValue()[1]}
                        </Box>
                    </Box>
                ),
            },
            {
                accessorKey: 'cbo',
                header: 'CBO',
                size: 200,
                enableSorting: false,
            },
            {
                accessorKey: 'address',
                header: 'Address',
                size: 200,
                enableSorting: false,
                Cell: ({ cell }) => (
                    <Box>
                        {cell.getValue()[0]}
                        <Box
                            sx={(theme) => ({
                                opacity: 0.6,
                                fontSize: '0.75rem',
                            })}
                        >
                            {cell.getValue()[1]}
                        </Box>
                    </Box>
                ),
            },
            {
                accessorKey: 'inactive',
                header: 'Status',
                size: 100,
                Cell: ({ cell }) => (
                    <Box
                        sx={(theme) => ({
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: '#eee',
                            borderRadius: '0.25rem',
                            color: '#666',
                            maxWidth: '11ch',
                            pt: '0.25 rem',
                            pb: '0.25 rem',
                        })}
                    >
                        {cell.getValue() ? "Deactivated" : "Active"}
                    </Box>
                ),
            },
            {
                accessorKey: 'enrollmentsYtd',
                header: 'Enrollments YTD',
                size: 150,
            },
            {
                accessorKey: 'lastEntry',
                header: 'Last Entry',
                size: 120,
            },
        ], []
    )

    return (
        <div className="container">
            <CommonTable
                columns={columns}
                fetch={fetchLocations}
                bodyParams={bodyParams}
                entryPath={"/location-detail/"}
                setRowSelection={setRowSelection}
                rowSelection={rowSelection}
                rowActions={rowActions}
                refreshTrigger={onRefreshTable}
            />
            {notificationIsShow &&
                ReactDOM.createPortal(
                <Notification
                    status={notificationStatus}
                    text={notificationText}
                    btn_text={notificationBtnText}
                    onClose={closeNotificationHandler}
                />,
                document.querySelector("body")
            )}
        </div>
    )
}

export default LocationTable;