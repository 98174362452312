import React, { useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';

import classes from './EnrollmentsByLocation.module.scss';
import Form from 'react-bootstrap/Form';
import { useEffect } from 'react';
import { getEnrollmentsByLocation } from '../../../api/api';
import moment from 'moment';
import Space from '../../UI/Space/Space';
import Card from "../../Card/Card"

type setDate = (date: Date) => void;
type TimeFrame = {
    name: string;
    func?: (setStart: setDate, setEnd: setDate) => void;
};

const timeFrames: { [key: string]: TimeFrame } = {
    ytd: {
        name: 'Year To Date (YTD)',
        func: (setStart, setEnd) => {
            setStart(new Date(new Date().getFullYear(), 0));
            setEnd(new Date());
        },
    },
    ytdr: {
        name: 'Since Last Year Today',
        func: (setStart, setEnd) => {
            setStart(moment(new Date()).subtract(1, 'year').toDate());
            setEnd(new Date());
        },
    },
    mtd: {
        name: 'Month to Date',
        func: (setStart, setEnd) => {
            const today = new Date();
            setStart(new Date(today.getFullYear(), today.getMonth(), 1));
            setEnd(today);
        },
    },
    mtdr: {
        name: 'Last 30 Days',
        func: (setStart, setEnd) => {
            setStart(moment(new Date()).subtract(30, 'd').toDate());
            setEnd(new Date());
        },
    },
    qtd: {
        name: 'Quarter to Date',
        func: (setStart, setEnd) => {
            const today = new Date();
            const quarters = [
                new Date(today.getFullYear(), 0),
                new Date(today.getFullYear(), 3),
                new Date(today.getFullYear(), 6),
                new Date(today.getFullYear(), 9),
            ];

            for (const quarter of quarters) {
                if (today.valueOf() < quarter.valueOf()) {
                    continue;
                }

                setStart(quarter);
            }

            setEnd(today);
        },
    },
    qtdr: {
        name: 'Last 90 Days',
        func: (setStart, setEnd) => {
            setStart(moment(new Date()).subtract(90, 'd').toDate());
            setEnd(new Date());
        },
    },
    cus: { name: 'Custom Time Frame' },
} as const;

const EnrollmentsByLocation = () => {
    // const [sorting, setSorting] = useState([]);
    const [startDate, setStartDate] = useState(
        new Date(moment(new Date()).subtract(30, 'days').valueOf())
    );
    const [endDate, setEndDate] = useState(new Date());
    const [data, setData] = useState(null);
    const [timeFrame, setTimeFrame] = useState('ytd');

    useEffect(() => {
        if (!startDate || !endDate) {
            return;
        }
        if (isNaN(startDate.valueOf()) || isNaN(endDate.valueOf())) {
            return;
        }
        getEnrollmentsByLocation(startDate, endDate).then((e) => {
            const formattedData = e;
            for(const data of formattedData) {
                data.last_timestamp = moment(data.last_timestamp).format('MM/DD/YY h:mm:ss A')
                data.location_id = (<strong>{data.location_id}</strong>)
                data.cbo = (<strong>{data.cbo}</strong>)
            }
            setData(e);
        });
    }, [startDate, endDate]);

    useEffect(() => {
        updateTimeFrames('ytd');
    }, [])

    // useEffect(() => {
    //     //do something with the sorting state when it changes

    //     // process custom sorting api call
    //     if (sorting && sorting[0]) {
    //         console.log(sorting[0])
    //         console.log(sorting[0].id);
    //         console.log(sorting[0].desc)
    //     }
    //     }, [sorting]);

    //should be memoized or stable

    // uh, i dont think this needs to memoized
    // why was it done this way? it has no dependancies - Rudy
    const columns = useMemo(
        () => [
            {
                accessorKey: 'cbo', //access nested data with dot notation
                header: 'CBO',
                size: 200,
            },
            {
                accessorKey: 'location',
                header: 'Location',
                size: 200,
            },
            {
                accessorKey: 'location_id', //normal accessorKey
                header: 'Location ID',
                size: 150,
            },
            {
                accessorKey: 'vita',
                header: 'VITA',
                size: 70,
            },
            {
                accessorKey: 'payroll',
                header: 'Payroll',
                size: 70,
            },
            {
                accessorKey: 'instant',
                header: 'Instant',
                size: 100,
            },
            {
                accessorKey: 'total',
                header: 'Total',
                size: 70,
            },
            {
                accessorKey: 'last_timestamp',
                header: 'Last Entry',
                size: 150,
                sortingFn: 'datetime',
            },
        ],
        []
    );

    const updateTimeFrames = (val: string) => {
        const tf = timeFrames[val];

        if (tf.func) {
            tf.func(setStartDate, setEndDate);
            setData(null);
        }
        setTimeFrame(val);
    };

    const getOptions = () => {
        const options: any[] = [];
        let i = 0;
        for (const key in timeFrames) {
            options.push(<option key={i++} value={key}>{timeFrames[key].name}</option>);
        }

        return options;
    };

    return (
        <div className={`container ` + classes.EBL}>
            <div className={`row`}>
                <div className={`col-12`}>
                    <Card>
                        <div className={`row`}>
                            <div
                                className={`col-md-6 ` + classes.EBL_Col_Title}
                            >
                                <h2 className={classes.EBL_Col_Title_Title}>
                                    Enrollment by Location
                                </h2>
                            </div>
                            <div className={`col-md-6`}>
                                <div className={classes.EBL_Col_Title_Filter}>
                                    <form action="#" id={`erlDateRange`}>
                                        <div
                                            className={`row`}
                                            style={{ justifyContent: 'right' }}
                                        >
                                            <div className={`col-5`}>
                                                <Form.Label
                                                    htmlFor="erlTimeframe"
                                                    className={
                                                        classes.EBL_Col_Title_Filter_Label
                                                    }
                                                >
                                                    Timeframe:
                                                </Form.Label>
                                                <Form.Select
                                                    id={`erlTimeframe`}
                                                    className={
                                                        classes.EBL_Col_Title_Filter_Field
                                                    }
                                                    onChange={(e) => {
                                                        updateTimeFrames(
                                                            e.target.value
                                                        );
                                                    }}
                                                    value={timeFrame}
                                                >
                                                    {getOptions()}
                                                </Form.Select>
                                            </div>
                                            {timeFrame === 'cus' && (
                                                <>
                                                    <div className={`col-3`}>
                                                        <Form.Label
                                                            htmlFor="erlStartDate"
                                                            className={
                                                                classes.EBL_Col_Title_Filter_Label
                                                            }
                                                        >
                                                            Start Date:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            id="erlStartDate"
                                                            placeholder="MM/DD/YYYY"
                                                            aria-describedby="startDate"
                                                            className={
                                                                classes.EBL_Col_Title_Filter_Field
                                                            }
                                                            value={moment(
                                                                startDate
                                                            ).format(
                                                                'yyyy-MM-DD'
                                                            )}
                                                            onChange={(e) =>
                                                                setStartDate(
                                                                    moment(
                                                                        e.target
                                                                            .value
                                                                    ).toDate()
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className={`col-3`}>
                                                        <Form.Label
                                                            htmlFor="erlEndDate"
                                                            className={
                                                                classes.EBL_Col_Title_Filter_Label
                                                            }
                                                        >
                                                            End Date:
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="date"
                                                            id="erlEndDate"
                                                            placeholder="MM/DD/YYYY"
                                                            aria-describedby="endDate"
                                                            className={
                                                                classes.EBL_Col_Title_Filter_Field
                                                            }
                                                            value={moment(
                                                                endDate
                                                            ).format(
                                                                'yyyy-MM-DD'
                                                            )}
                                                            onChange={(e) => {
                                                                setEndDate(
                                                                    moment(
                                                                        e.target
                                                                            .value
                                                                    ).toDate()
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* <div className={`row`}>
                            <div className={`col-12 ` + classes.EBL_Col_Count}>
                                <p>Showing 1 to 6 of 253 locations.</p>
                            </div>
                        </div> */}
                        <Space value="20" />
                        {data ? (
                            <div className={`row`}>
                                <div className={`col-12`}>
                                    <MaterialReactTable
                                        // className={classes.EBL_Table as string}
                                        columns={columns}
                                        data={data}
                                        initialState={{ density: 'compact' }}
                                        enableColumnResizing
                                        muiTablePaperProps={{
                                            elevation: 0,
                                            sx: {
                                                border: 'none',
                                            },
                                        }}
                                        muiTableHeadCellProps={{
                                            //simple styling with the `sx` prop, works just like a style prop in this example
                                            sx: {
                                                fontSize: '14px',
                                                fontFamily: 'Avenir',
                                                fontWeight: 500,
                                                opacity: 0.87,
                                                background: '#F6F6F6',
                                            },
                                        }}
                                        muiTableBodyCellProps={{
                                            sx: {
                                                fontFamily: 'Avenir',
                                                fontWeight: 350,
                                                opacity: 1,
                                                letterSpacing: '0.25px',
                                            },
                                        }}
                                        muiTablePaginationProps={{
                                            // rowsPerPageOptions: [5, 10],
                                            showFirstButton: true,
                                            showLastButton: true,
                                        }}
                                        enableColumnFilters={false}
                                        enableGlobalFilter={false}
                                        enableTopToolbar={true}
                                        enableColumnDragging={false}
                                        enableColumnOrdering={true}
                                        // muiTableBodyRowProps={({ row }) => ({
                                        //     onClick: (event) => {
                                        //         navigate(
                                        //             entryPath +
                                        //                 data.items[row.id]
                                        //                     .action_id
                                        //         );
                                        //     },
                                        //     sx: {
                                        //         cursor: 'pointer',
                                        //     },
                                        // })}
                                        // onSortingChange={setSorting}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div>Loading...</div>
                        )}
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default EnrollmentsByLocation;
