import { useState } from 'react';
import useInput from '../../hooks/useInput';
import Modal from '../UI/Modal/Modal';
import { Editor } from '@tinymce/tinymce-react';
import Input from '../UI/Input/Input';
import Checkbox from '../UI/Checkbox/Checkbox';
import { useEffect } from 'react';
import Button from '../UI/Button/Button';
import classes from './CBOs.module.scss';
import { ErrorMessageProviderBuilder } from '../UI/Input/ErrorMessageProviderBuilder.ts';

/**
 *
 * @param {{onClose: () => void, onSaveAndQuit: (data) => void, title: string, buttonLabel: string, initialData: React.MutableRefObject<{name: string, description: string, useLimit: boolean, limit: number, message_board: string}>}} param0
 * @returns
 */
function CBOModal({ onClose, onSaveAndQuit, title, buttonLabel, initialData }) {
    const {
        value: name,
        inputChangeHandler: nameChangeHandler,
        inputBlurHandler: nameBlurHandler,
        hasError: nameHasError,
        isValid: nameIsValid,
    } = useInput(v => v.trim() !== '');

    const {
        value: description,
        inputChangeHandler: descriptionChangeHandler,
        inputBlurHandler: descriptionBlurHandler,
    } = useInput(() => true);

    const [useLimit, setUseLimit] = useState(false);

    const {
        value: enrollmentLimit,
        inputChangeHandler: enrollmentLimitChangeHandler,
        inputBlurHandler: enrollmentLimitBlurHandler,
    } = useInput(() => true);

    const [messageBoardContent, setMessageBoardContent] = useState();

    function saveAndQuit() {
        nameBlurHandler();
        if(!nameIsValid) {
            nameBlurHandler();
            return;
        }
        onSaveAndQuit({
            name,
            description,
            enable_limit: useLimit,
            limit: isNaN(enrollmentLimit) ? -1 : parseInt(enrollmentLimit) || -1,
            message_board: messageBoardContent,
        })
    }

    function toggleUseLimit() {
        const newValue = !useLimit;

        if(!useLimit && newValue && enrollmentLimit === -1) {
            enrollmentLimitChangeHandler({target:{value: 100}});
        }

        setUseLimit(newValue)
    }

    useEffect(() => {
        if(name || description || enrollmentLimit || messageBoardContent || !initialData) {
            return;
        }
        nameChangeHandler({target:{value:initialData.current.name}})
        descriptionChangeHandler({target:{value:initialData.current.description}})
        setUseLimit(initialData.current.enable_limit)
        enrollmentLimitChangeHandler({target:{value:initialData.current.limit}})
        setMessageBoardContent(initialData.current.message_board)

    }, [initialData, nameChangeHandler, descriptionChangeHandler, enrollmentLimitChangeHandler, description, messageBoardContent, name, enrollmentLimit])


    return (
        <Modal onClose={onClose} title={title} className={classes.Modal__Body}>
            <div className={classes.Modal__Content}>
                <Input
                    label="Name:*"
                    placeholder="Name"
                    value={name}
                    onChange={nameChangeHandler}
                    onBlur={nameBlurHandler}
                    hasError={nameHasError}
                    errorMessageProvider={new ErrorMessageProviderBuilder('Name').isntEmpty().build()}
                />
                <Input
                    label="Description:"
                    placeholder="Describe your CBO"
                    value={description}
                    onChange={descriptionChangeHandler}
                    onBlur={descriptionBlurHandler}
                />
                <Checkbox
                    label={'Limit the number of enrollments for this CBO'}
                    onChange={() => toggleUseLimit()}
                    value={useLimit}
                />
                {useLimit && (
                    <div className={classes.Modal__limit}>
                        <Input
                            value={enrollmentLimit}
                            onChange={enrollmentLimitChangeHandler}
                            onBlur={enrollmentLimitBlurHandler}
                            placeholder='Maximum Enrollments'
                        />
                    </div>
                )}
                <div>
                    <Editor
                        id="messageBoard"
                        className={classes.Modal__Mb}
                        apiKey="qmlcindz4kb283efhxwlz33yrgs21hcrle2fal5pfjgmi95m"
                        value={messageBoardContent}
                        onEditorChange={(newValue, editor) =>
                            setMessageBoardContent(newValue)
                        }
                        init={{
                            plugins: 'code lists link',
                            toolbar:
                                'undo redo | blocks | alignleft aligncenter alignright alignjustify | forecolor backcolor | bold italic underline strikethrough | code | ' +
                                'bullist numlist | link | outdent indent',
                            toolbar_mode: 'sliding',
                            font_size_formats:
                                '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                            statusbar: false,
                            height: '200px',
                            menubar: false
                        }}
                    />
                </div>
                <Button onClick={saveAndQuit} className={classes.Modal__Button}>{buttonLabel}</Button>
            </div>
        </Modal>
    );
}

export default CBOModal;
