import { useState, useReducer } from 'react';
import useInput from '../hooks/useInput';
import {
  useFilters,
  FilterItemBuilder,
} from '../components/List/Common/DynamicFilters/FilterStateBuilder.ts';
import { getCBOs, getCardTypes } from '../api/api.js';
import { fetchEnrollmentReport, getProgramIds } from "../api/enrollments.ts";
import ReportPageTemplate from '../components/Reports/ReportPageTemplate.js';
import { getAllLocations } from '../api/locations.ts';

const selectInitialState = () => {
  return { options: [], value: '' };
};

const selectReducer = (state, action) => {
  if (action.type === 'SET_OPTIONS') {
    return {
      options: action.payload,
      value: state.value,
    };
  }

  if (action.type === 'CHANGE') {
    return {
      options: state.options,
      value: action.payload,
    };
  }

  if (action.type === 'RESET') {
    return {
      options: state.options,
      value: '',
    };
  }

  return selectInitialState();
};

const EnrollmentReportSearch = () => {
  const filters = useFilters(
    (filterValuesRef) => {
      return {
        cbo: new FilterItemBuilder()
          .setLabel('CBO')
          .setParamName('cbo')
          .setPermanent()
          .select()
          .setReference()
          .setGetterFunc(getCBOs)
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        location: new FilterItemBuilder()
          .setLabel('Locations')
          .setParamName('location')
          .setPermanent()
          .select()
          .setReference()
          .setGetterFunc(getAllLocations)
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        card_type: new FilterItemBuilder()
          .setLabel('Card Type')
          .setParamName('card_type')
          .select()
          .setReference()
          .setGetterFunc(async () => {
            console.log('getting');
            const types = await getCardTypes();

            return [...types.instant_cards, ...types.standard_cards];
          })
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        program: new FilterItemBuilder()
          .setLabel('Program')
          .setParamName('program')
          .select()
          .setReference()
          .setGetterFunc(async () => {
            return [...(await getProgramIds())]
          })
          .setValueAndLabelParams('program_id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        begin_date: new FilterItemBuilder()
          .setLabel('Begin Date')
          .setParamName('begin_date')
          .setType('date')
          .setPlaceholder('MM/DD/YYYY')
          .setErrorMessage('Begin and end are swapped.')
          .useBuildCombo(
            () => true,
            useInput
          ),
        end_date: new FilterItemBuilder()
          .setLabel('End Date')
          .setParamName('end_date')
          .setType('date')
          .setPlaceholder('MM/DD/YYYY')
          .setErrorMessage('Begin and end are swapped.')
          .useBuildCombo(
            () => true,
            useInput
          ),
        include_deactivated: new FilterItemBuilder()
          .setLabel('Include Deactivated')
          .setParamName('include_deactivated')
          .setPermanent()
          .checkbox()
          .startDisabled()
          .useBuildCombo(useState),
      };
    },
    ['cbo', 'location', 'include_deactivated']
  );

  
  return (
    <>
      <ReportPageTemplate
        name="Total Enrollments"
        description="Rolling up enrollment totals."
        filters={filters}
        fetchRequest={fetchEnrollmentReport}
        fileName={'enrollment report'}
        mapping_function={r => {
          if(r.location_name === "Total") {
            const bolded = {}

            for(const key in r) {
              bolded[key] = <strong>{r[key]}</strong>
            }

            return bolded;
          }

          return r;
        }}
        columns={[
          {
            accessorKey: 'location_name',
            header: 'Location',
            size: 350,
          },
          {
            accessorKey: 'card_name',
            header: 'Card Type',
            size: 300,
          },
          {
            accessorKey: 'accepted',
            header: 'Accepted',
            size: 120,
          },
          {
            accessorKey: 'canceled',
            header: 'Canceled',
            size: 120,
          },
          {
            accessorKey: 'failure',
            header: 'Failure',
            size: 100,
          },
          {
            accessorKey: 'total',
            header: 'Total',
            size: 100,
          }
        ]}
      />
    </>
  );
};

export default EnrollmentReportSearch;
