import { useReducer } from 'react';
import useInput from '../hooks/useInput.js';
import {
  useFilters,
  FilterItemBuilder,
} from '../components/List/Common/DynamicFilters/FilterStateBuilder.ts';
import { getCardTypes } from '../api/api.js';

import { fetchUserEnrollmentReport } from '../api/users.ts';
import ReportPageTemplate from '../components/Reports/ReportPageTemplate.js';

const selectInitialState = () => {
  return { options: [], value: '' };
};

const selectReducer = (state, action) => {
  if (action.type === 'SET_OPTIONS') {
    return {
      options: action.payload,
      value: state.value,
    };
  }

  if (action.type === 'CHANGE') {
    return {
      options: state.options,
      value: action.payload,
    };
  }

  if (action.type === 'RESET') {
    return {
      options: state.options,
      value: '',
    };
  }

  return selectInitialState();
};

const ProcessingSummary = () => {
  const filters = useFilters(
    (filterValuesRef) => {
      return {
        begin_date: new FilterItemBuilder()
          .setLabel('Begin Date')
          .setParamName('begin_date')
          .setType('date')
          .setPlaceholder('MM/DD/YYYY')
          .setErrorMessage('Begin and end are swapped.')
          .setPermanent()
          .useBuildCombo(
            () => true,
            useInput
          ),
        end_date: new FilterItemBuilder()
          .setLabel('End Date')
          .setParamName('end_date')
          .setType('date')
          .setPlaceholder('MM/DD/YYYY')
          .setErrorMessage('Begin and end are swapped.')
          .setPermanent()
          .useBuildCombo(
            () => true,
            useInput
          ),
        username: new FilterItemBuilder()
          .setLabel('User Name')
          .setParamName('username')
          .setType('string')
          .useBuildCombo((v) => true, useInput
        ),
        confirmation_number: new FilterItemBuilder()
          .setLabel('Confirmation #')
          .setParamName('confirmation_number')
          .setType('number')
          .useBuildCombo((v) => true, useInput
        ),
        card_type: new FilterItemBuilder()
          .setLabel('Card Type')
          .setParamName('card_type')
          .select()
          .setReference()
          .setGetterFunc(async () => {
            console.log('getting');
            const types = await getCardTypes();

            return [...types.instant_cards, ...types.standard_cards];
          })
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
      };
    },
    ['begin_date', 'end_date']
  );


  return (
    <>
      <ReportPageTemplate
        name="Processing Summary"
        description="Focusing on the users submitting enrollments."
        filters={filters}
        fetchRequest={fetchUserEnrollmentReport}
        fileName={"processing summary"}
        columns={[
          {
            accessorKey: 'username',
            header: 'User Name',
          },
          {
            accessorKey: 'confirmation_number',
            header: 'Confirmation #',
            size: 300,
          },
          {
            accessorKey: 'card_type',
            header: 'Card Type',
          },
          {
            accessorKey: 'card_number',
            header: 'Card Number',
          },
          {
            accessorKey: 'account_number',
            header: 'Acct Num',
          },
          {
            accessorKey: 'submitted',
            header: 'Date',
          }
        ]}
      />
    </>
  );
};

export default ProcessingSummary;
