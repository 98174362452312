import classes from "./Modal.module.scss";
import { motion } from 'framer-motion';

/**
 *
 * @param {{titlePosition?: string, onClose: () => void, title: string, children, className?: string, hideTitle?: boolean}} props
 * @returns
 */
const Modal = (props) => {
  const titleCls = [
    classes.Modal__title,
    props.titlePosition ? classes[props.titlePosition] : "",
  ];

  return (
    <div className={classes.Modal}>
      <div className={classes.Modal__wrapper}>
        <motion.div initial={{opacity: 0, y: '100vh', scale: 0.2 }} animate={{opacity: 1, y: 0, scale: 1 }} transition={{ duration: 0.3 }} className={classes.Modal__body  + ' ' + props.className}>
          { props.hideTitle ||
            <div className={classes.Modal__header}>
              <div className={classes.Modal__close} onClick={props.onClose}>
                ×
              </div>
              <h2 className={titleCls.join(" ")}>{props.title}</h2>
            </div>
          }
          <div className={classes.Modal__content}>{props.children}</div>
        </motion.div>
      </div>

      <motion.div initial={{opacity: 0 }} animate={{opacity: 1 }} transition={{ duration: 0.2 }} className={classes.Modal__overlay}></motion.div>
    </div>
  );
};

export default Modal;
