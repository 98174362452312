import { useState } from "react";
import { NavLink } from "react-router-dom";

import Table from "../UI/Table/Table";
import Pagination from "../UI/Pagination/Pagination";
import { getEnrollments } from "../../api/api";
import { setDateFormat } from "../../assets/helper";
import classes from "./SearchForm.module.scss";

const SearchTable = ({ enrollments, bodyParams }) => {
  const tHead = [
    "First",
    "Last",
    "Email",
    "Location",
    "Status",
    "Date Submitted",
    "Action",
  ];

  const totalPages = enrollments.summary.total_pages;
  const itemsPerPage = enrollments.summary.items_returned;

  const [list, setList] = useState(enrollments.items);
  const [summary, setSummary] = useState(enrollments.summary);
  const [itemOffset, setItemOffset] = useState(itemsPerPage);
  const [loading, setLoading] = useState(false);

  const data = !list.length
    ? []
    : list.map((item) => {
        return {
          first: item.first,
          last: item.last,
          email: item.email,
          location: item.location,
          status: item.status,
          date: setDateFormat(item.submitted, {
            month: "numeric",
            year: "numeric",
            day: "numeric",
          }),
          action_id: item.id,
        };
      });

  const onPaginateHandler = (value, callback) => {
    setLoading(true);

    getEnrollments({ ...bodyParams, page: value })
      .then((response) => {
        setList(response.items);
        setSummary(response.summary);
        setItemOffset(itemsPerPage * response.summary.current_page);

        callback();
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="container">
      {!list.length ? (
        <p>No data</p>
      ) : (
        <>
          <div className={classes.Table__info}>
            Showing {itemOffset - summary.items_returned + 1} to {itemOffset} of{" "}
            {summary.total_items} enrollments.
          </div>

          <Table head={tHead} loading={loading} dontOrder={tHead}>
            {data.map((item) => (
              <tr key={item.action_id}>
                <td>{item.first}</td>
                <td>{item.last}</td>
                <td>
                  <a href={`mailto:${item.email}`}>{item.email}</a>
                </td>
                <td>{item.location}</td>
                <td>{item.status}</td>
                <td>{item.date}</td>
                <td>
                  <NavLink
                    to={`/enrollment-detail/${item.action_id}`}
                    className={classes.Table__action}
                  >
                    View Details
                  </NavLink>
                </td>
              </tr>
            ))}
          </Table>
        </>
      )}

      <div className={classes.Table__footer}>
        {totalPages > 1 ? (
          <Pagination
            onPaginate={onPaginateHandler}
            currentPage={summary.current_page}
            totalPages={totalPages}
            loading={loading}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SearchTable;
