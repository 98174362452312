import classes from "./Input.module.scss";
import { useEffect, useRef } from "react";
import React from "react";

interface InputValuePair {
  value: React.MutableRefObject<string>;
  input: React.RefObject<HTMLInputElement>;
}

type keyInputValuePairs = {[key: string]: InputValuePair}

const DateInput = (props) => {
  const monthValue = useRef<string>('');
  const dayValue = useRef<string>('');
  const yearValue = useRef<string>('');
  const monthInput = useRef<HTMLInputElement>(null);
  const dayInput = useRef<HTMLInputElement>(null);
  const yearInput = useRef<HTMLInputElement>(null);
  let cls = [classes.Input, props.clsType ? classes[props.clsType] : ""];
  const id = `DateField-${Math.random()}`;

  if (props.hasError === true) {
    // console.log('has')
    cls.push(classes.invalid);
  }else {
    // console.log('no errors')
    if(cls.includes(classes.invalid)) {
      cls = cls.splice(cls.indexOf(classes.invalid), 1);
    }
  }

  useEffect(() => {
    if(yearInput.current === null || monthInput.current === null || dayInput.current === null) {
      return;
    }


    const leftArrow = 37;
    const rightArrow = 39;
    const slash = 191;
    const backspace = 8;
    yearInput.current.addEventListener('keydown', (e) => {
      if(yearInput.current === null || dayInput.current === null) {
        return;
      }


      if([leftArrow, backspace].includes(e.keyCode)) {
        if(yearInput.current.selectionStart === 0) {
          e.preventDefault();
          dayInput.current.focus();
          dayInput.current.selectionStart = 2;
        }
      }
    })
    monthInput.current.addEventListener('keydown', (e) => {
      
      if(monthInput.current === null || dayInput.current === null) {
        return;
      }
      if([slash, rightArrow].includes(e.keyCode)) {
        e.preventDefault();
        dayInput.current.focus();
        dayInput.current.selectionStart = 0;
      }
      else if(e.keyCode === leftArrow) {
        if(monthInput.current.selectionStart && monthInput.current.selectionStart === 0) {
          e.preventDefault();
          dayInput.current.focus();
          dayInput.current.selectionStart = 0;
          dayInput.current.selectionEnd = 0;
        }
      }
    })
    dayInput.current.addEventListener('keydown', (e) => {
      if(yearInput.current === null || dayInput.current === null || monthInput.current === null) {
        return;
      }
      const selstart = dayInput.current.selectionStart;

      if(selstart === null) {
        return;
      }

      
      
      if([leftArrow, backspace].includes(e.keyCode)) {
        if(selstart === 0) {
          e.preventDefault();
          monthInput.current.focus();
          monthInput.current.selectionStart = 2;
        }
      }else if([rightArrow, slash].includes(e.keyCode)) {
        if(selstart >= dayValue.current.length) {
          e.preventDefault();
          yearInput.current.focus();
          yearInput.current.selectionStart = 0;
        }
      }
    })
  }, [])

  if(yearInput.current !== null && monthInput.current !== null && dayInput.current !== null) {
    if(props.value === '') {
      yearValue.current = ''
      monthValue.current = ''
      dayValue.current = ''
      yearInput.current.value = '';
      monthInput.current.value = '';
      dayInput.current.value = '';
    }
  }



  const globalChange = (c: React.ChangeEvent<HTMLInputElement>) => {
    // c.preventDefault();
    const val: keyInputValuePairs = {
      month: {value: monthValue, input: monthInput},
      day: {value: dayValue, input: dayInput},
      year: {value: yearValue, input:yearInput}
    };

    const type = c.target.id.split('-')[2];
    let refs: InputValuePair | null = null;

    switch(type) {
      case 'year': refs = val.year; break;
      case 'month': refs = val.month; break;
      case 'day': refs = val.day; break;
    }

    if(refs === null) {
      return;
    }
    
    refs.value.current = c.target.value.replace(/[^0-9]/g, '');

    if(type !== 'year') {
      const value = refs.value.current;

      if(value === null) {
        return;
      }

      if(value.length === 2) {
        if(type === 'month') {
          const dayin = val.day.input.current;
          if(dayin !== null) {
            dayin.focus();
            dayin.selectionStart = 0;
          }
        }else {
          const yearin = val.year.input.current
          if(yearin !== null) {
            yearin.focus();
            yearin.selectionStart = 0;
          }
        }
      }
    }

    c.target.value = `${yearValue.current}-${monthValue.current.padStart(2, '0')}-${dayValue.current.padStart(2,'0')}`
    // console.log(val)
    // console.log(c.target.value)
    props.onChange(c);
  }

  // const icon = setIcon(props);
  
  return (
    <div className={cls.join(" ")}>
      {props.label && (
        <label htmlFor={id}>
          {props.label + " "}
          {props.hasError && (
            <span className={classes.Input__error}>
              {props.errorMessageProvider
                ? props.errorMessageProvider([monthValue, dayValue, yearValue])
                : props.errorMessage || "Enter valid data"}
            </span>
          )}
        </label>
      )}
      <div className={classes.dateContainer}>
        <input
          id={id + "-month"}
          // type={inputType}
          placeholder={props.placeholder ? props.placeholder.month : 'MM'}
          value={monthValue.current}
          onChange={globalChange}
          // onBlur={props.onBlur}
          disabled={props.disabled}
          required={props.required}
          role="presentation"
          autoComplete="off"
          ref={monthInput}
          maxLength={2}
          className={classes.date}
        />
        <span>/</span>
        <input
          id={id + "-day"}
          // type={inputType}
          placeholder={props.placeholder ? props.placeholder.day : 'DD'}
          value={dayValue.current}
          onChange={globalChange}
          // onBlur={props.onBlur}
          disabled={props.disabled}
          required={props.required}
          role="presentation"
          autoComplete="off"
          ref={dayInput}
          maxLength={2}
          className={classes.date}
        />
        <span>/</span>
        <input
          id={id + "-year"}
          // type={inputType}
          placeholder={props.placeholder ? props.placeholder.year : 'YYYY'}
          value={yearValue.current}
          onChange={globalChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
          required={props.required}
          role="presentation"
          autoComplete="off"
          ref={yearInput}
          maxLength={4}
          className={[classes.date, classes.year].join(' ')}
        />
      </div>
    </div>
  );
};

export default DateInput;
