import { useReducer, useEffect } from "react";
import Input from "../UI/Input/Input";
import Button from "../UI/Button/Button";
import Select from "../UI/Select/Select";

import useInput from "../../hooks/useInput";
import { getEnrollmentsExport } from "../../api/api";
import { downloadCSVFile, csvMaker } from "../../assets/helper";

import Download from "../../assets/svg/Download";
import classes from "./SearchForm.module.scss";

const initialLocationState = {
  options: [],
  activeLocation: null,
  value: "",
};

const locationReducer = (state, action) => {
  if (action.type === "SET_OPTIONS") {
    return {
      options: action.payload,
      activeLocation: state.activeLocation,
      value: state.value,
    };
  }

  if (action.type === "SET_ACTIVE_LOCATION") {
    return {
      options: state.options,
      activeLocation: action.payload,
      value: state.value,
    };
  }

  if (action.type === "CHANGE") {
    return {
      options: state.options,
      activeLocation: state.activeLocation,
      value: action.payload,
    };
  }

  return initialLocationState;
};

const SearchForm = ({ enrollments, searchParams, onSearch, location }) => {
  const firstParam = searchParams ? searchParams.first : null;
  const lastParam = searchParams ? searchParams.last : null;
  const dobParam = searchParams ? searchParams.dob : null;

  /**
   * Name
   */
  const {
    value: firstName,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
  } = useInput(() => true, firstParam);

  const {
    value: lastName,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
  } = useInput(() => true, lastParam);

  /**
   * Date
   */
  const {
    value: dobValue,
    inputChangeHandler: dobChangeHandler,
    inputBlurHandler: dobBlurHandler,
  } = useInput(() => true, dobParam);

  const {
    value: startDateValue,
    inputChangeHandler: startDateChangeHandler,
    inputBlurHandler: startDateBlurHandler,
  } = useInput(() => true);

  const {
    value: endDateValue,
    inputChangeHandler: endDateChangeHandler,
    inputBlurHandler: endDateBlurHandler,
  } = useInput(() => true);

  /**
   * ID
   */
  const {
    value: idValue,
    inputChangeHandler: idChangeHandler,
    inputBlurHandler: idBlurHandler,
  } = useInput(() => true);

  /**
   * Location
   */
  const [locationState, dispatchLocation] = useReducer(
    locationReducer,
    initialLocationState
  );

  useEffect(() => {
    if (location.length > 1) {
      const optionsArr = location.map((item) => {
        return {
          value: item.id,
          label: item.name,
          isDisabled: false,
        };
      });

      optionsArr.unshift({
        value: "0",
        label: "All Locations",
        isDisabled: false,
      });

      dispatchLocation({ type: "SET_OPTIONS", payload: optionsArr });
      dispatchLocation({ type: "SET_ACTIVE_LOCATION", payload: null });
      dispatchLocation({ type: "CHANGE", payload: "0" });
    } else {
      dispatchLocation({ type: "SET_ACTIVE_LOCATION", payload: location[0] });
    }
  }, [location]);

  const changeLocationHandler = (event) => {
    dispatchLocation({ type: "CHANGE", payload: event.target.value });

    const currentLocation = location.find(
      (item) => item.id === event.target.value
    );
    dispatchLocation({ type: "SET_ACTIVE_LOCATION", payload: currentLocation });
  };

  const submitHandler = (event) => {
    event.preventDefault();

    event.target.reset();

    console.log(typeof(locationState.value), locationState.value)

    const prepareBody = {
      first: `${firstName}` || null,
      last: `${lastName}` || null,
      dob: `${dobValue}` || null,
      id: `${idValue}` || null,
      start_date: `${startDateValue}` || null,
      end_date: `${endDateValue}` || null,
      location_id: parseInt(locationState.value) !== 0 && locationState.value ? `${locationState.value}` : undefined,
    };

    onSearch(prepareBody);
  };

  const downloadHandler = () => {
    const prepareBody = {
      first: `${firstName}` || null,
      last: `${lastName}` || null,
      dob: `${dobValue}` || null,
      start_date: `${startDateValue}` || null,
      end_date: `${endDateValue}` || null,
      id: `${idValue}` || null,
      location_id: parseInt(locationState.value) !== 0 && locationState.value ? `${locationState.value}` : undefined,
    };

    getEnrollmentsExport(prepareBody)
      .then((response) => {
        const csvData = csvMaker(response);
        downloadCSVFile(csvData, "enrollments");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // Nothing else worked, and i was running out of time, so i just made a function to clear all the inputs
  const handleClear = (e) => {
    window.location.reload();
  }

  return (
    <div>
      <div className={classes.Form__container}>
        <div className="container">
          <p className={classes.Form__text}>
            Enter <b>any</b> search parameter below and click search.   
            <span onClick={handleClear} className={classes.Form__clear}>
                RESET FILTERS
            </span>    
          </p>

          <form onSubmit={submitHandler} className={classes.Form}>
            <div className={classes.Form__col}>
              <div className={classes.Form__input__long}>
                <Input
                  label="First Name:"
                  clsType="small"
                  value={firstName}
                  onChange={firstNameChangeHandler}
                  onBlur={firstNameBlurHandler}
                />
              </div>

              <div className={classes.Form__input__long}>
                <Input
                  label="Last Name:"
                  clsType="small"
                  value={lastName}
                  onChange={lastNameChangeHandler}
                  onBlur={lastNameBlurHandler}
                />
              </div>
            </div>

            <div className={classes.Form__col}>
              <div className={classes.Form__input__small}>
                <Input
                  type="date"
                  clsType="small"
                  label="Date of Birth*"
                  value={dobValue}
                  onChange={dobChangeHandler}
                  onBlur={dobBlurHandler}
                />
              </div>

              <div className={classes.Form__input__small}>
                <Input
                  type="number"
                  clsType="small"
                  label="Enrollment Id:"
                  value={idValue}
                  onChange={idChangeHandler}
                  onBlur={idBlurHandler}
                />
              </div>
            </div>

            <div className={classes.Form__col}>
              <div className={classes.Form__input__small}>
                <Input
                  type="date"
                  clsType="small"
                  label="Start Date"
                  value={startDateValue}
                  onChange={startDateChangeHandler}
                  onBlur={startDateBlurHandler}
                />
              </div>
              <div className={classes.Form__input__small}>
                <Input
                  type="date"
                  clsType="small"
                  label="End Date"
                  value={endDateValue}
                  onChange={endDateChangeHandler}
                  onBlur={endDateBlurHandler}
                />
              </div>
            </div>

            <div className={classes.Form__col}>
              <Button color="primary" type="submit" clsType="small">
                Search
              </Button>
            </div>
          </form>
        </div>
      </div>

      <div className="container">
        <div className={classes.Row}>
          <div className={classes.Select}>
            {location.length > 1 && (
              <Select
                options={locationState.options}
                value={locationState.value}
                onChange={changeLocationHandler}
              />
            )}
          </div>

          {enrollments && (
            <div className={classes.Btn} onClick={downloadHandler}>
              Download <Download />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
