// import { useState, useEffect } from "react";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading.tsx";
import Card from "../components/Card/Card";
import Space from "../components/UI/Space/Space";
import AddLocationForm from "../components/Location/AddLocationForm";

// import { getLocations } from "../api/api";

import classes from "./pages.module.scss";

const AddLocation = () => {
//   const [location, setLocation] = useState([]);

//   useEffect(() => {
//     getLocations()
//       .then((response) => {
//         setLocation(response);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, []);

  return (
    <Layout>
      <Heading
        title="Add Location"
        subtitle="Organizations"
        description="Adding a new location to the system."
      />

      <Space value="50" />

      <div className="container">
        <div className={`${classes.Row} ${classes.Enroll__row}`}>
          <div className={classes.Enroll__left}>
            <Card>
              <AddLocationForm />
            </Card>
          </div>

          <div className={classes.Enroll__right}>
            <Card>
              <h2 className={classes.Enroll__subtitle}>Organization</h2>

              <p className={classes.Enroll__text}>
                Carefully select the organization to best preserve the hierarchy
                of organizations.  Only create a new CBO if you are sure the
                organization is new to CFR.
              </p>
            </Card>

            <Space value="25" />

            <Card>
              <h2 className={classes.Enroll__subtitle}>Location ID</h2>

              <p className={classes.Enroll__text}>
              Location IDs are alphanumeric. Use an organizational abbreviation with a sequential number, ensuring it's unique.
              For example, if your organization is 'Green Community', and it's the 15th location, an appropriate ID could be 'GC015'.
              </p>
            </Card>

            <Space value="25" />

            <Card>
              <h2 className={classes.Enroll__subtitle}>Self-Enrollment</h2>

              <p className={classes.Enroll__text}>
                This explains information about the self-enrollment site and how
                this works and the information required. This is not a feature
                that will typically be enabled, so be careful before selecting it.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AddLocation;
