import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import DeleteModal from './DeleteModal';
import Notification from '../../UI/Notification/Notification';

import useTimeAgo from '../../../hooks/useTimeAgo';
import useNotification from '../../../hooks/useNotification';
import {
    deleteFile,
    getFiles,
    getFile,
    postStatusChange,
} from '../../../api/api';
import { downloadCSVFile } from '../../../assets/helper';

import SpreadSheet from '../../../assets/svg/SpreadSheet';
import classes from './Files.module.scss';
import UpdateModal from './UpdateModal';
import { Tooltip } from '@mui/material';

const FileItem = ({ item, setFilesList, shouldDisplayStatus }) => {
    const time = useTimeAgo(item.uploaded);

    const [showModal, setShowModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const {
        status: notificationStatus,
        text: notificationText,
        btn_text: notificationBtnText,
        isShow: notificationIsShow,
        add: addNotification,
        remove: removeNotification,
    } = useNotification();

    const fetchData = () => {
        getFiles(1, 6)
            .then((response) => {
                setFilesList(response);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const openModalHandler = () => {
        setShowModal(true);
    };

    const closeModalHandler = () => {
        setShowModal(false);
    };

    const onUpdateStatus = (statusId, message) => {
        postStatusChange(item.id, statusId, message).then(() => {
            setShowUpdateModal(false);
        });
    };

    const deleteFileHandler = () => {
        removeNotification();

        deleteFile(item.id)
            .then((response) => {
                fetchData();
                closeModalHandler();

                addNotification({
                    status: 'success',
                    text: response.message,
                    btn_text: 'OK',
                });

                setTimeout(() => {
                    removeNotification();
                }, 3000);
            })
            .catch((error) => {
                console.error(error);

                addNotification({
                    status: 'error',
                    text: 'Error deleting. Please try again.',
                    btn_text: 'OK',
                });

                setTimeout(() => {
                    removeNotification();
                }, 3000);
            });
    };

    const downloadHandler = () => {
        removeNotification();

        let downloaded = getFile(item.id);

        downloadCSVFile(atob(downloaded.base64), downloaded.name);
    };

    const CustomSpreadsheet = React.forwardRef(function spreadSheet(
        props,
        ref
    ) {
        return (
            <div {...props} ref={ref}>
                <SpreadSheet />
            </div>
        );
    });
    /* | id: {item.id} */
    return (
        <>
            <div className={classes.File}>
                <div className={classes.File__icon}>
                    <Tooltip
                        arrow
                        title={
                            <React.Fragment>
                                {item.comments.length > 0 &&
                                    item.comments.map((comment) => (
                                        <p>
                                            {comment.commenter} - {comment.date}
                                            : ({comment.status}) {comment.text}
                                        </p>
                                    ))
                                }

                                {item.comments.length === 0 &&
                                  (<span>No comments.</span>)
                                }
                            </React.Fragment>
                        }
                    >
                        <CustomSpreadsheet />
                    </Tooltip>
                </div>
                <div>
                    <p className={classes.File__text}>{item.name} </p>
                    <p className={classes.File__text}>
                        {item.uploaded_by} updated {time}
                    </p>
                    {shouldDisplayStatus && (
                        <p>
                            Status: <strong>{item.status}</strong>
                        </p>
                    )}
                    <div>
                        <span className="link" onClick={downloadHandler}>
                            download file
                        </span>{' '}
                        |{' '}
                        <span className="link" onClick={openModalHandler}>
                            delete
                        </span>
                        {shouldDisplayStatus && (
                            <>
                                {' | '}
                                <span
                                    className="link"
                                    onClick={() => setShowUpdateModal(true)}
                                >
                                    update status
                                </span>
                            </>
                        )}
                    </div>
                </div>
            </div>

            {showModal &&
                ReactDOM.createPortal(
                    <DeleteModal
                        onClose={closeModalHandler}
                        onDelete={deleteFileHandler}
                        fileName={item.name}
                    />,
                    document.querySelector('body')
                )}

            {showUpdateModal &&
                ReactDOM.createPortal(
                    <UpdateModal
                        onClose={() => setShowUpdateModal(false)}
                        file={item}
                        onSubmit={onUpdateStatus}
                    />,
                    document.querySelector('body')
                )}

            {notificationIsShow &&
                ReactDOM.createPortal(
                    <Notification
                        status={notificationStatus}
                        text={notificationText}
                        btn_text={notificationBtnText}
                        onClose={() => removeNotification()}
                    />,
                    document.querySelector('body')
                )}
        </>
    );
};

export default FileItem;
