import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useEffect, useState } from 'react';
import { getMe } from '../api/users.ts';
import { useNotification } from '../hooks/NotificationContext.tsx';

/**
 * is the current card enrollment user
 * @type React.Context<MeObject>
 */
const UserContext = createContext(null);

export function UserManager({ children }) {
  const { getAccessTokenSilently } = useAuth0();

  const [user, setUser] = useState(null);
  const { addNotification } = useNotification();
  const [failCount, setFailCount] = useState(0);
  const [wasRateLimited, setWasRateLimited] = useState(false);

  useEffect(() => {
    const thisUrl = window.location.toString().split('?');
    if(thisUrl[0].endsWith('/notexist')) {
      setUser({});
      return;
    }
    Promise.race([
      getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        scope: 'read:current_user',
      })
        .then(async function (token) {
          if (token) {
            localStorage.setItem('AccessToken', token);
            return await getMe().then((me) => {
              if (!me) {
                return false;
              }
              try {
                if (me.cause && (me.cause === 'User is deactivated' || me.cause === 'User Does not exist') && !thisUrl[0].endsWith('/notexist')) {
                  window.location = '/notexist';
                  return;
                }

                if(me.cause && me.cause === 'Rate Limit') {
                  setWasRateLimited(true);
                  return;
                }
              }catch {
                // do nothing
              }
              setUser(me);

              if (me.messages.length > 0) {
                me.messages.forEach((message) => {
                  addNotification({
                    text: message.message,
                    color: message.type,
                    btnText: 'Dismiss',
                  });
                });
              }

              return true;
            });
          }
        })
        .catch(async (e) => {
          await new Promise((r) =>
            setTimeout(() => {
              r(null);
            }, wasRateLimited ? 7000 : 1000)
          )
          setFailCount((fc) => fc + 1 )

          if(failCount > 3) {
            window.location = '/notexist';
          }
        }),
      new Promise((r) =>
        setTimeout(() => {
          r(null);
        }, 7000) // me request takes at most 7 seconds
      ),
    ]).then((res) => {
      if (!res) {
        setFailCount((fc) => fc + 1);
      }
    });

    // I dont care if addNotification changes
    // eslint-disable-next-line
  }, [failCount]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
}

export default UserContext;
