import { useContext } from 'react';
import { createContext, useEffect, useState } from 'react';
import UserContext from './userContext';

/**
 * is the current card enrollment user level
 * @type React.Context<"Administrator" | "Super Coordinator" | "Coordiantor" | null>
 */
const CardEnrollmentUserLevelContext = createContext(null);

export function CardEnrollmentUserLevelManager({ children }) {
  const [role, setRole] = useState(localStorage.getItem('ceul') || null);
  const user = useContext(UserContext);

  useEffect(() => {
    if (user == null) {
      return;
    }
    setRole(user.card_enrollment_user_level);
  }, [user]);

  return (
    <CardEnrollmentUserLevelContext.Provider value={role}>
      {children}
    </CardEnrollmentUserLevelContext.Provider>
  );
}

export default CardEnrollmentUserLevelContext;
