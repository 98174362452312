import FilterListIcon from '@mui/icons-material/FilterList';
import classes from "../../List.module.scss";
import { useState, useEffect, useRef } from 'react';

/**
 * 
 * @param {{filterState: {[key: string]: import('./FilterState').FilterItem}}} param0 
 * @returns 
 */
const DynamicFilterDropdown = ({ filterState, setFilterState }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef(null)
    const [dropdownWidth, setDropdownWidth] = useState(0)

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        window.addEventListener('click', handleClickOutside)

        return () => {
            window.removeEventListener('click', handleClickOutside)
        }
    }, [])

    useEffect(() => {
      var maxLength = 0
      for (const key of Object.keys(filterState)) {
        if (key !== 'activeFields' && !filterState.activeFields.includes(key) && filterState[key].label.length > maxLength) {
          maxLength = filterState[key].label.length
        }
      }

      setDropdownWidth(maxLength)
    }, [filterState])

    const handleClickFilter = (key) => {
        var updatedFilterState = filterState
        updatedFilterState.activeFields.push(key)
        setFilterState(updatedFilterState)
    }

    return (
        <span ref={dropdownRef}>
            <span className={classes.Form__filter} onClick={function () {setIsOpen(!isOpen)}}>
                <span>
                    ADD FILTER
                </span>
                <FilterListIcon className={classes.Form__filter__icon}/>
                {isOpen && (
                    <div className={classes.Form__filter__dropdown__menu}>
                        {Object.keys(filterState).map((key) => key !== "activeFields" ? !(filterState.activeFields.includes(key)) ? (
                            <div
                                style={{width: `${dropdownWidth + 1}ch`}}
                                key={key}
                                value={"test"}
                                className={classes.Form__filter__dropdown__item}
                                onClick={() => handleClickFilter(key)}
                            >
                                {filterState[key].label}
                            </div>
                        ) : null : null)}
                    </div>
                )}
            </span>
        </span>
    )
}

export default DynamicFilterDropdown;
