import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSearchParams } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading.tsx";
import EnrollmentFilters from "../components/List/Enrollment/Filters";
import EnrollmentTable from "../components/List/Enrollment/Table";
import Notification from "../components/UI/Notification/Notification";

import useNotification from "../hooks/useNotification";
import { getLocationsSimplified, getCBOs } from "../api/api";

let isInitial = true;

const EnrollmentSearch = () => {

  const [rowSelection, setRowSelection] = useState({})

  let [searchParams] = useSearchParams();
  const params = {};

  searchParams.forEach((key, value) => {
    params[value] = key;
  });

  const [bodyParams, setBodyParams] = useState({excludeFailures: "true"});
  const [location, setLocation] = useState([]);
  const [cbo, setCbo] = useState([])

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    //add: addNotification,
    remove: removeNotification,
  } = useNotification();

  const closeNotificationHandler = () => {
    removeNotification();
    onSearch(bodyParams);
  };

  useEffect(() => {
    getLocationsSimplified()
      .then((response) => {
        setLocation(response);
      })
      .catch((error) => {
        console.error(error);
      });

    getCBOs()
      .then((response) => {
        setCbo(response);
      })
      .catch((error) => {
        console.error(error)
      })
  }, []);

  const onSearch = (body) => {
    setBodyParams(body);
  };

  useEffect(() => {
    if (isInitial && Object.keys(params).length) {
      isInitial = false;

      onSearch(params);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Layout>
        <Heading
          title="Search"
          subtitle="Enrollments"
          description="Find previous enrollments and get additional details."
        />
        <div style={{minHeight: 70 + 'vh'}}>
          <EnrollmentFilters
            searchParams={params}
            onSearch={onSearch}
            location={location}
            cbo={cbo}
            rowSelection={rowSelection}
          />
          <EnrollmentTable
            bodyParams={bodyParams}
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
          />
        </div>
      </Layout>

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            width="full"
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={closeNotificationHandler}
          />,
          document.querySelector("body")
        )}
    </>
  );
};

export default EnrollmentSearch;
