import React, { useEffect, useState } from 'react';
import Select from '../UI/Select/Select';
import Space from '../UI/Space/Space';
import { getSavedQueries } from '../../api/api';
import { SavedQueriesObject, getSavedQuery, modifySavedQuery } from '../../api/queries.ts';
import { useSearchParams } from 'react-router-dom';

const SavedQueries = ({ onQueryChanged, value, setValue, refreshQueries, updateQueryRef }: { value, setValue, onQueryChanged: (q: SavedQueriesObject | null) => void, refreshQueries: any, updateQueryRef: React.MutableRefObject<(query: string) => void>}) => {
    let [searchParams] = useSearchParams();
    const [queries, setQueries] = useState<SavedQueriesObject[] | null>(null);
    const [hasChecked, setChecked] = useState(false);

    const refresh = () => {
        getSavedQueries().then(async (saved: SavedQueriesObject[]) => {
            let modSaved = saved;
            const id = searchParams.get('query_id');
            if(id) {
                if(modSaved.find(v => v.id === Number.parseInt(id)) == null) {
                    modSaved.push(await getSavedQuery(Number.parseInt(id)));
                }
            }
            setQueries(modSaved);
            setChecked(true);
            selectQuery(typeof value == "string" ? Number.parseInt(value) : value, modSaved);
        })
    }

    //eslint-disable-next-line
    useEffect(refresh, []);
    if(refreshQueries)
        refreshQueries.current = refresh;

    const getQueryOptions = () => {
        if(!queries) {
            return [{label: 'Loading...', value: '-1'}];
        }
        else if(queries.length === 0 && hasChecked) {
            return [{label: 'No Saved Queries', value: '-1'}]
        }
        const options = queries.map<any>((v) => {return{label: v.name, value: v.id}});
        if(!options) {
            return [{label: 'Loading...', value: '-1'}];
        }

        const retOps = [{label: 'My Saved Queries', value: '-1'}];
        retOps.push(...options);
        return retOps;
    };

    const onSavedLoaded = (e) => {
        selectQuery(Number.parseInt(e.target.value))
    }

    const selectQuery = (id, query_list: SavedQueriesObject[] | null =null) => {
        const qs = query_list || queries;
        if(qs === null) {
            onQueryChanged(null);
            return;
        }
        const query = qs.find(v => v.id === id);
        if(!query) {
            onQueryChanged(null);
            return;
        }
        setValue(query.id)
        onQueryChanged(query);
    }

    const updateQuery = async (query: string) => {
        if(!queries) {
            return;
        }
        const selectedQuery = queries.find(v => v.id === value);

        if(!selectedQuery) {
            return;
        }

        await modifySavedQuery(selectedQuery.id, query, selectedQuery.name, selectedQuery.description)
        refresh();
    }
    updateQueryRef.current = updateQuery;

    return (
        <>
            <Select options={getQueryOptions()} onChange={onSavedLoaded} value={value}/>
            <Space value={20} />
        </>
    );
};

export default SavedQueries;
