// import { useState, useEffect } from "react";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading.tsx";
import Card from "../components/Card/Card";
import Space from "../components/UI/Space/Space";
import LocationDetailForm from "../components/Location/LocationDetailForm";
import classes from "./pages.module.scss";
import { useContext, useState } from "react";
import UserContext from "../contexts/userContext";

const LocationDetail = () => {
  const [websiteURL, setWebsiteURL] = useState()
  const {id} = useContext(UserContext)

  return (
    <Layout>
      <Heading
        title="Location Detail"
        subtitle="Organizations"
        description="Reviewing and updating location details."
      />

      <Space value="50" />

      <div className="container">
        <div className={`${classes.Row} ${classes.Enroll__row}`}>
          <div className={classes.Enroll__left}>
            <Card>
              <LocationDetailForm onWebsitePathUpdated={setWebsiteURL}/>
            </Card>
          </div>

          <div className={classes.Enroll__right}>
            <Card>
              <h2 className={classes.Enroll__subtitle}>Organization</h2>

              <p className={classes.Enroll__text}>
                Carefully select the organization to best preserve the hierarchy
                of organizations.  Only create a new CBO if you are sure the
                organization is new to CFR.
              </p>
            </Card>

            <Space value="25" />

            <Card>
              <h2 className={classes.Enroll__subtitle}>Location ID</h2>

              <p className={classes.Enroll__text}>
              Location IDs are alphanumeric. Use an organizational abbreviation with a sequential number, ensuring it's unique.
              For example, if your organization is 'Green Community', and it's the 15th location, an appropriate ID could be 'GC015'.
              </p>
            </Card>

            <Space value="25" />

            <Card>
              <h2 className={classes.Enroll__subtitle}>Self-Enrollment</h2>

              <p className={classes.Enroll__text}>
              You may enable a self-enrollment site for this location by selecting the box on this form.
              Only enable this option if you are sure this is needed, as there are very few locations that have it enabled.{' '}
              {websiteURL && (<div style={{wordWrap: 'break-word',marginTop: '20px',color: '#AA0000'}}><b>To generate single-use links,</b> you will need to go to <a href={`https://connect.communityfinancialresources.org/focus?site=${websiteURL}&coach=${id}`} target="_blank" rel="noreferrer">https://connect.communityfinancialresources.org/focus?site={websiteURL}&coach={id}</a></div>)}
              </p>
            </Card>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LocationDetail;
