import { useEffect, useState } from 'react';
import classes from './Table.module.scss';
import SortArrow from '../../../assets/svg/SortArrow.tsx';

const Table = (props) => {
    const [order, setOrder] = useState({ col: null, direction: 'ASC' });

    const onOrderChange = (item) => {
        if (props.dontOrder && props.dontOrder.includes(item)) {
            return;
        }

        if (order.col === item) {
            const orderCopy = Object.assign({}, order);
            orderCopy.direction =
                orderCopy.direction === 'ASC' ? 'DESC' : 'ASC';
            setOrder(orderCopy);
            return;
        }

        setOrder({ col: item, direction: 'ASC' });
    };

    useEffect(() => {
        if (props.orderCallback) {
            props.orderCallback(order);
        }
    }, [props, order]);

    return (
        <div className={classes.Table}>
            <table>
                <thead>
                    <tr>
                        {props.head.map((item) => (
                            <th
                                key={item}
                                onClick={() => onOrderChange(item)}
                                style={{
                                    cursor:
                                        props.dontOrder &&
                                        !props.dontOrder.includes(item)
                                            ? 'pointer'
                                            : 'default',
                                }}
                            >
                                {item}

                                {item === order.col && (
                                    <div style={{ display: 'inline', paddingLeft: '5px'}}>
                                        <SortArrow
                                            style={{ display: 'inline' }}
                                            direction={
                                                order.direction === 'DESC'
                                                    ? 'up'
                                                    : 'down'
                                            }
                                        />
                                    </div>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>

                <tbody>{props.children}</tbody>
            </table>

            {props.loading && (
                <span className={classes.Loading}>Loading...</span>
            )}
        </div>
    );
};

export default Table;
