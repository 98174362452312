import { useState } from "react";
import Modal from "../UI/Modal/Modal";
import Button from "../UI/Button/Button";
import WaitModal from "../Enroll/WaitModal";
import { downloadCSVFile } from "../../assets/helper";
import moment from "moment";

function RunReport({reportFunction, reportName, reportRef}) {
    const [confirmWindowOpen, setConfirmWindowOpen] = useState(false)
    const [runningReport, setRunningReport] = useState(false)

    reportRef.current = () => {
        setConfirmWindowOpen(true);
    }

    if(!confirmWindowOpen) {
        return (<></>)
    }

    function runReport() {
        setRunningReport(true);
        reportFunction().then((csv) => {
            setRunningReport(false);
            setConfirmWindowOpen(false);
            
            downloadCSVFile(csv, `${reportName.toLowerCase()} report ${moment().format("MM-DD-YYYY hh-mmA")}`)
        }).catch(() => {
            setRunningReport(false);
            alert('report failed to execute...')
        });

    }

    return (
        <>
            {runningReport &&
                <WaitModal message="Running Report..."/>
            }
            <Modal onClose={() => setConfirmWindowOpen(false)} title="Run Report?">
                <div style={{height: '20vh', display: 'flex', justifyContent: 'space-between', flexDirection: 'column'}}>
                    <h2>Run {reportName} Report?</h2>
                    <Button onClick={runReport}>Confirm</Button>
                </div>
            </Modal>
        </>
    )
}

export default RunReport;