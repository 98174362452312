import React from "react";
import GridPageTemplate from "./GridPageTemplate";
/**
 * 
 * @param {{filters: import("../List/Common/DynamicFilters/FilterState").FilterItem[], columns: {[key: string]: any}, description: string, fetchRequest, fileName: string, mapping_function: any, customTable: React.FC<any>}} param0 
 * @returns 
 */
const ReportPageTemplate = ({filters, columns, name, description, fetchRequest, fileName, mapping_function, customTable, child, onSubmit, useCustomTable}) => {
    return (
        <GridPageTemplate
            filters={filters}
            columns={columns}
            name={name}
            description={description}
            fetchRequest={fetchRequest}
            fileName={fileName}
            mapping_function={mapping_function}
            button_text={"Run Report"}
            subtitle={"REPORTS"}
            rowActionsSize={80}
            customTable={customTable}
            child={child}
            onSubmit={onSubmit}
            useCustomTable={useCustomTable}
        />
    )
}

export default ReportPageTemplate;