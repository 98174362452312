import classes from "./Charts.module.scss";
import {Bar, BarChart, CartesianGrid, Cell, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import React, {useState} from "react";
import Form from "react-bootstrap/Form";
import { getTransactionReport } from "../../../api/api";
import { useEffect } from "react";
import Card from "../../Card/Card"

const MonthlyCashflow = () => {
    const [data, setData] = useState(null);

    const formatTransactionData = (d, curchart) => {
        let fill = '#579D36';

        if(d.amount < 0) {
            fill = '#E67F68';
        }

        if(curchart === 'chart3') {
            fill = '#aaa';
        }
        return {
            name: d.name,
            amount: d.amount,
            cellProps: {
                fill: fill
            }
        }
    }

    const handleSelect = (e) => {
        if(!['chart1','chart2','chart3'].includes(e.target.value)) {
            return;
        }
        getTransactionReport(e.target.value).then((d) => {
            setData(d.map(entry => formatTransactionData(entry, e.target.value)))
        })
    }

    const formatYAxis = (tickItem) => {
        return "$ " + tickItem;
    }


    useEffect(() => {
        getTransactionReport('chart1').then((d) => {
            setData(d.map(formatTransactionData))
        })
    }, [])

    const formatNumber = (num) => {
        const roundedComma = (Math.round(num * 100) / 100).toLocaleString();

        if(num < 1) {
            return '-$' + roundedComma.replace('-','')
        }

        return '$' + roundedComma;
    }

    return (
        <div className={`col-lg-6 ` + classes.Charts__Col_Cashflow}>
            <Card>
                <div className={`d-flex justify-content-between ` + classes.Charts__Col_Wrp_Title}>
                    <h2>Monthly Cashflow</h2>

                    <Form.Select
                        onChange={handleSelect}
                        className={classes.Charts__Col_Wrp_Title_Select}
                    >
                        <option value={`chart1`}>Net Flow Funds</option>
                        <option value={`chart2`}>Net Flow Savings</option>
                        <option value={`chart3`}>Net Fees</option>
                    </Form.Select>
                </div>
                <div className={classes.Charts__Col_Wrp_Chart}>
                    {data !== null && 
                    <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={400}
                        data={data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        
                    >
                        <CartesianGrid strokeDasharray="2 2" />
                        <XAxis dataKey="name" />
                        <YAxis tickFormatter={formatYAxis}  />
                        <Tooltip formatter={v => v && formatNumber(v)} />
                        <ReferenceLine y={0} stroke="#000" />

                        <Bar dataKey="amount">
                            {data.map((entry, index) => (
                                <Cell fill={entry.cellProps.fill} key={index} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>   
                }
                    
                </div>
            </Card>
        </div>
    )
}

export default MonthlyCashflow;