import { setDateFormat } from '../assets/helper.js';
import { MessageObject, PaginateApiResponse, getBaseUrl, getHeaders } from './common.ts';
import {
  addPaginationToFetchUrl,
  addParamsToFetchUrl,
  addSortingToFetchUrl,
} from './fetch.ts';

export const fetchEnrollmentByLocation = async (
  params,
  sorting,
  pagination
) => {
  const fetchURL = new URL('/api/v1/org/enrollments/simplified', getBaseUrl());

  addPaginationToFetchUrl(pagination, fetchURL);
  addParamsToFetchUrl(params, fetchURL);
  addSortingToFetchUrl(sorting, fetchURL);

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders(),
  });

  let json = await response.json();

  json.items = !json.items.length
    ? []
    : json.items.map((item) => {
        var capitalizedStatus = item.status;
        if (capitalizedStatus.length > 0) {
          capitalizedStatus =
            item.status.charAt(0).toUpperCase() + item.status.slice(1);
        }
        return {
          first: item.first,
          last: item.last,
          email: item.email,
          location: item.location,
          status: capitalizedStatus,
          date: setDateFormat(item.submitted, {
            month: 'numeric',
            year: 'numeric',
            day: 'numeric',
          }),
          action_id: item.id,
        };
      });

  return json;
};

export interface EnrollmentReportItem {
    location_name: string;
    location_id:   string;
    cbo_id:        number;
    card_name:     string;
    card_id:       number;
    total:         number;
    accepted:      number;
    canceled:      number;
    failure:       number;
}

export async function fetchEnrollmentReport(params, sorting, pagination): Promise<PaginateApiResponse<EnrollmentReportItem>> {
  const fetchURL = new URL('/api/v1/enrollments/report', getBaseUrl());

  addPaginationToFetchUrl(pagination, fetchURL);
  addParamsToFetchUrl(params, fetchURL);
  addSortingToFetchUrl(sorting, fetchURL)

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders(),
  });

  let json = await response.json();

  return json;
};

export interface ProgramItem {
  program_id: number;
  name: string;
}

export async function getProgramIds(): Promise<ProgramItem[]> {
  const options = {
    headers: await getHeaders(),
  };

  const url = new URL('/api/v1/enrollments/programs', getBaseUrl());
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  const json = await resp.json() as ProgramItem[];

  console.log(json)

  const ids = json.map(c => c.program_id)

  const filtered_json = json.filter((value, index) => 
    ids.indexOf(value.program_id) === index
  )

  return filtered_json;
}

export async function updateEnrollmentStatus(enrollmentId: string, status: string): Promise<MessageObject> {
  const fetchURL = new URL(`/api/v1/enrollment/${enrollmentId}`, getBaseUrl());

  const response = await fetch(fetchURL.href, {
    method: 'PATCH',
    headers: await getHeaders(),
    body: JSON.stringify({ status }),
  });

  if (!response.ok) {
    throw new Error('Failed to update enrollment status');
  }

  return response.json();
}