import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Layout from '../Layout/Layout.js';
import Heading from '../Heading/Heading.tsx';
import Notification from '../UI/Notification/Notification.js';
import startCase from 'lodash.startcase';
import useNotification from '../../hooks/useNotification.js';
import ReportSearchForm from '../List/Report/Filters.js';
import ReportTable from '../List/Report/Table.js';
import MaterialReactTable from 'material-react-table';

const GridPageTemplate = ({
  filters,
  columns,
  name,
  description,
  fetchRequest,
  fileName,
  mapping_function,
  button_text,
  subtitle,
  rowActions,
  rowActionsSize,
  headerButtonProps,
  bulkActions,
  onBulkAction,
  refreshTrigger,
  csvDownloadMappingFunction,
  modalMode,
  disclaimer,
  child,
  onSubmit,
  useCustomTable
}) => {
  const [bodyParams, setBodyParams] = useState({ excludeFailures: 'true' });
  const [rowSelection, setRowSelection] = useState({});

  const mapping_func = mapping_function || ((r) => r);
  const csv_mapping_func = csvDownloadMappingFunction || ((r) => r);

  const modified_fetch_request = async (bodyParams, sorting, pagination) => {
    // console.log(bodyParams, sorting, pagination)
    const res = await fetchRequest(bodyParams, sorting, pagination);

    console.log(res)

    if(res.items.csv) {
      return res.items.csv;
    }

    res.items = res.items.map(
      bodyParams.isCSV ? csv_mapping_func : mapping_func
    );

    return res;
  };

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    //add: addNotification,
    remove: removeNotification,
  } = useNotification();

  const closeNotificationHandler = () => {
    removeNotification();
    onSearch(bodyParams);
  };

  const onSearch = (body) => {
    // console.log('body', body)
    setBodyParams(body);
  };

  function executeBulkAction(action) {
    onBulkAction(action, rowSelection);
  }

  function getColumns(data) {
    const columns = [];

    const known_identifiers = {
      'cbo': 'CBO'
    }

    const replaces = {
      'FK': ''
    }

    if (data.length > 0) {
      for (let key in data[0]) {
        let friendlyName = ''

        for (const replacement in replaces) {
          if (key.includes(replacement)) {
            key.replace(replacement, replaces[replacement])
          }
        }

        if (known_identifiers.hasOwnProperty(key)) {
          friendlyName = known_identifiers[key];
        } else {
          friendlyName = startCase(key);
        }


        columns.push({
          accessorKey: key, //access nested data with dot notation
          header: friendlyName,
          size: 100,
        });
      }
    }

    return columns;
  }

  const content = (
    <>
      <ReportSearchForm
        onSearch={onSearch}
        filters={filters}
        fetchRequest={modified_fetch_request}
        fileName={fileName}
        params={bodyParams}
        button_text={button_text}
        bulkActions={bulkActions}
        onBulkAction={executeBulkAction}
        disclaimer={disclaimer}
        onSubmit={onSubmit}
      />

      
      {useCustomTable ? <div style={{ display: 'flex', justifyContent: 'center' }}><div style={{ width: '90%' }}><MaterialReactTable
          key={window.location.href}
          columns={getColumns(useCustomTable.data || []).sort(useCustomTable.sorting)}
          data={(useCustomTable.data && useCustomTable.data.map(d => ({ ...d, Label: (d.Label.startsWith('*') && d.Label[d.Label.length - 1] === '*') ? (<strong>{d.Label.substring(1, d.Label.length - 1)}</strong>) : d.Label }))) || []}
          initialState={{ density: 'compact' }}
          enableColumnResizing
          rowsPerPage={50}
          muiTablePaperProps={{
            elevation: 0,
            sx: {
              border: 'none',
            },
          }}
          muiTableHeadCellProps={{
            sx: {
              fontSize: '14px',
              fontFamily: 'Avenir',
              fontWeight: 500,
              opacity: 0.87,
              background: '#F6F6F6',
            },
          }}
          muiTableBodyCellProps={{
            sx: {
              fontFamily: 'Avenir',
              fontWeight: 350,
              opacity: 1,
              letterSpacing: '0.25px',
            },
          }}
          muiTablePaginationProps={{
            // rowsPerPageOptions: [5, 10],
            showFirstButton: true,
            showLastButton: true,
            rowsPerPageOptions: false
          }}
          enableColumnFilters={false}
          enableGlobalFilter={false}
          enableTopToolbar={true}
          enableColumnDragging={false}
          enableColumnOrdering={true}
          state={{ pagination: useCustomTable.pagination }}
          onPaginationChange={useCustomTable.setPagination}
        /></div></div> : 
      <ReportTable
      key={window.location.href}
      bodyParams={bodyParams}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      fetchReport={modified_fetch_request}
      columns={columns}
      rowActions={rowActions}
      rowActionsSize={rowActionsSize}
      refreshTrigger={refreshTrigger}
    />}
    </>
  );

  return (
    <>
      {modalMode && [content]}
      {modalMode || (
        <Layout>
          <Heading
            title={name}
            subtitle={subtitle}
            description={description}
            btn_text={headerButtonProps && headerButtonProps.btn_text}
            onClick={headerButtonProps && headerButtonProps.onClick}
          />
          <div style={{ minHeight: 70 + 'vh' }}>{[content]}</div>
        </Layout>
      )}

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            width="full"
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={closeNotificationHandler}
          />,
          document.querySelector('body')
        )}
    </>
  );
};

export default GridPageTemplate;
