import classes from '../../List.module.scss';
import Input from '../../../UI/Input/Input';
import Select from '../../../UI/Select/Select';
import PhoneInput from '../../../UI/Input/PhoneInput';
import { useEffect, useRef } from 'react';
import Checkbox from "../../../UI/Checkbox/Checkbox";
import React from 'react';
import { motion } from 'framer-motion';

/**
 *
 * @param {{predicate: (v: FilterItem) => boolean, filterState: {[key: string]: import('./FilterState').FilterItem}, setFilterState, resets, filterValues, submitHandler: () => void}} param0
 * @returns
 */
const DynamicFilterInputs = ({
    filterState,
    setFilterState,
    resets,
    filterValues,
    submitHandler,
	predicate
}) => {
    const firstRenderRef = useRef(false);
    useEffect(() => {
        const setDropdownOptions = async () => {
            firstRenderRef.current = true;
            const valueKeys = Object.keys(filterValues);
            for (const key of valueKeys) {
				if(predicate && !predicate(filterState[key])) {
					continue;
				}
                if (filterState[key].type === 'select') {
                    if (filterState[key].reference) {
                        //get options and use dispatch to set options
                        const response = await filterState[key].getterFunc();
                        const optionsArr = response.map((item) => {
                            return {
                                value: item[filterState[key].valueParam],
                                label: item[filterState[key].labelParam],
                                isDisabled: false,
                            };
                        });

                        optionsArr.unshift({
                            value: '',
                            label:
                                'Select a ' +
                                filterState[key].label.toLowerCase() +
                                '...',
                            isDisabled: false,
                        });

                        filterValues[key].dispatch({
                            type: 'SET_OPTIONS',
                            payload: optionsArr,
                        });
                    } else {
                        filterValues[key].dispatch({
                            type: 'SET_OPTIONS',
                            payload: filterState[key].options,
                        });
                    }
                }
            }
        };

        if (!firstRenderRef.current) {
            setDropdownOptions().catch(console.error);
        }
    }, [filterState, filterValues, predicate]);

    const selectChangeHandler = (event, key) => {
        filterValues[key].dispatch({
            type: 'CHANGE',
            payload: event.target.value,
        });
    };

    function defaultInput(key) {
        return (
            <div className={classes.Form__col}>
                <div className={classes.Form__input__long}>
                    <Input
                        key={key}
                        type={filterState[key].type}
                        label={filterState[key].label + ':'}
                        hasError={filterValues[key].hasError}
                        errorMessage="Invalid"
                        clsType="small"
                        value={filterValues[key].value}
                        onChange={filterValues[key].inputChangeHandler}
                        placeholder={filterState[key].placeholder}
                        errorMessageProvider={filterState[key].error_message_provider}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                submitHandler();
                            }
                        }}
                        cancel={filterState[key].non_removable ? undefined : (() => {
                            var newFilterState = { ...filterState };
                            filterValues[key].reset();
                            var index =
                                newFilterState.activeFields.indexOf(key);
                            if (index !== -1) {
                                newFilterState.activeFields.splice(index, 1);
                            }
                            setFilterState(newFilterState);
                        })}
                    />
                </div>
            </div>
        );
    }

    function phoneInput(key) {
        return (
            <div className={classes.Form__col}>
                <div className={classes.Form__input__long}>
                    <PhoneInput
                        key={key}
                        label={filterState[key].label}
                        value={filterValues[key].value}
                        clsType="small"
                        hasError={filterValues[key].hasError}
                        errorMessage="Invalid"
                        onChange={filterValues[key].inputChangeHandler}
                        disableValidation={false}
                        placeholder={filterState[key].placeholder}
                        onBlur={filterState[key].inputBlurHandler}
                        disabled={false}
                        required={false}
                        errorMessageProvider={filterState[key].error_message_provider}
                        cancel={filterState[key].non_removable ? undefined : () => {
                            var newFilterState = { ...filterState };
                            filterValues[key].reset();
                            var index =
                                newFilterState.activeFields.indexOf(key);
                            if (index !== -1) {
                                newFilterState.activeFields.splice(index, 1);
                            }
                            setFilterState(newFilterState);
                        }}
                    />
                </div>
            </div>
        );
    }

    function selectInput(key) {
        return (
            <div className={classes.Form__col}>
                <div className={classes.Select}>
                    <Select
                        clsType="small"
                        label={filterState[key].label + ':'}
                        value={filterValues[key].state.value}
                        options={filterValues[key].state.options}
                        onChange={(event) => selectChangeHandler(event, key)}
                        errorMessageProvider={filterState[key].error_message_provider}
                        cancel={filterState[key].non_removable ? undefined : () => {
                            var newFilterState = { ...filterState };
                            filterValues[key].dispatch({ type: 'RESET' });
                            var index =
                                newFilterState.activeFields.indexOf(key);
                            if (index !== -1) {
                                newFilterState.activeFields.splice(index, 1);
                            }
                            setFilterState(newFilterState);
                        }}
                    />
                </div>
            </div>
        );
    }

    function toggleInput(key) {
        return (
            <div className={classes.Form__col + ' ' + classes.Checkbox}>
                <div>
                    <Checkbox
                        clsType="small"
                        label={filterState[key].label}
                        value={filterValues[key][0]}
                        onChange={() => filterValues[key][1](!filterValues[key][0])}
                        cancel={filterState[key].non_removable ? undefined : () => {
                            var newFilterState = { ...filterState };
                            filterValues[key][1](filterState[key].start_enabled);
                            var index =
                                newFilterState.activeFields.indexOf(key);
                            if (index !== -1) {
                                newFilterState.activeFields.splice(index, 1);
                            }
                            setFilterState(newFilterState);
                        }}
                    />
                </div>
            </div>
        );
    }

    function customInput(key) {
        return (
        <div className={classes.Form__col}>
            <div>
                {React.createElement(filterState[key].component, {filter_item: filterState[key], filter_value: filterValues[key]})}
                {/* <PredefinedSelection filter_item={filterState[key]} filter_value={filterValues[key]} /> */}
            </div>
        </div>)
    }

    return (
        <form
            className={classes.Form__dynamic__inputs}
            onSubmit={submitHandler}
        >
            {filterState['activeFields'].filter(f => filterState[f].visible || filterState[f].visible === undefined).map((key, idx) => {
                if(predicate && !predicate(filterState[key])) {
					return (<></>);
				}

				// I dont like switch statements
                const dict = {
                  select: selectInput,
                  phone: phoneInput,
                  toggle: toggleInput,
                  defualt: defaultInput,
                  custom: customInput
                };
                
                return <motion.div
                    key={key}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.2, delay: idx * 0.1 }}
                    style={{ display: 'flex' }}
                >
                    {(dict[filterState[key].type] || dict.defualt)(key)}
                </motion.div>
            })}
        </form>
    );
};

export default DynamicFilterInputs;
