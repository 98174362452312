import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout/Layout';
import Card from '../components/Card/Card';
import Heading from '../components/Heading/Heading.tsx';
import Space from '../components/UI/Space/Space.js';
import Select from '../components/UI/Select/Select.js';
import Button from '../components/UI/Button/Button.js';
import moment from 'moment';
import DropZonePure from '../components/FileUpload/DropZone/DropZonePure.js';
import { DataImportTemplate, UploadOrTestResults, getTemplate, getTemplates, importData } from '../api/data.ts';
import _ from 'lodash';
import { useNotification } from '../hooks/NotificationContext.tsx';
import { downloadCSVFile, downloadTextFile } from '../assets/helper.js';

const DataImport: React.FC = () => {
  const [template, setTemplate] = useState<DataImportTemplate | null>(null);
  const [templates, setTemplates] = useState<DataImportTemplate[]>([])
  const [files, setFiles] = useState<Blob[]>([]);
  const [testResults, setTestResults] = useState<UploadOrTestResults | undefined>();
  const [uploadResults, setUploadResults] = useState<UploadOrTestResults | undefined>();
  const [extraData, setExtraData] = useState<{ [key: string]: any }>();
  const { addNotification } = useNotification()

  const [isUploading, setIsUploading] = useState(false);

  const sendTest = () => {
    setIsUploading(true);
    files[0].text()
      .then(csv => {
        return importData(csv, template?.name, extraData, true);
      })
      .then((res) => {
        setTestResults(res);
        addNotification({ text: 'Data Import test successful', btnText: 'OK', color: 'ok' });
      })
      .catch((err) => {
        setTestResults(err);
        addNotification({ text: err.message, btnText: 'OK', color: 'error' });
      })
      .finally(() => setIsUploading(false));
  }

  const importDataForReal = () => {
    setIsUploading(true);
    files[0].text()
      .then(csv => {
        return importData(csv, template?.name, extraData, false);
      })
      .then((res) => {
        setUploadResults(res);
        addNotification({ text: 'Data Import test successful', btnText: 'OK', color: 'ok' });
      })
      .catch((err) => {
        setUploadResults(err);
        addNotification({ text: typeof (err) === 'string' ? err : err.message, btnText: 'OK', color: 'error' });
      }).finally(() => setIsUploading(false));
  }

  useEffect(() => {
    getTemplates().then((t) => {
      setTemplates(t || []);
    });
  }, [])

  return (
    <>
      <Layout>
        <Heading
          title="Import"
          subtitle="data"
          description="Let's load more data into the system!"
        />
        <Space value={20} />
        <div style={{ marginLeft: '5%', marginRight: '5%' }}>
          <Card>
            <h2 style={{ fontWeight: 300, color: 'black' }}>
              Step 1. Plan your upload
            </h2>
            <p>
              Select the type of upload you would like to perform to see the
              upload requirements.
            </p>
            <Space value={15} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ width: '50%' }}>
                <div style={{ display: 'flex', height: '45px' }}>
                  <div style={{ width: '250px', marginRight: 10 }}>
                    <Select
                      options={[{ label: 'None Selected', value: -1 }, ...(templates ? templates.map((v, i) => ({ label: v.name.split('_').map(s => _.capitalize(s)).join(' '), value: i })) : [])]}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setTemplate(parseInt(e.target.value) === -1 ? null : templates[e.target.value])}
                    />
                  </div>{' '}
                  {template &&
                    <Button color="lightgreen" onClick={() => getTemplate(template?.name).then(t => downloadCSVFile(t, `${template?.name}`))}>Download Sample Format</Button>
                  }
                </div>
                <div style={{ width: '250px' }}>
                  {template && Object.keys(template.extra_data).map((k) => {
                    const extraDataObject = template.extra_data[k];

                    if (extraDataObject.type === 'dropdown') {
                      return (<>
                        <Space value={10} />
                        <Select options={extraDataObject.options} onChange={(e) => { setExtraData(d => ({ ...d, [k]: e.target.value })) }} />
                      </>)
                    }

                    return <></>
                  })}
                </div>
                <Space value={10} />
                <p>Format: {template?.format}</p>
                <p>Columns: {template?.columnDefinitions.length}</p>
                <p>
                  Format Last Updated:{' '}
                  {moment(template?.updated).format('MM/DD/YYYY')}
                </p>
              </div>
              <div>
                <strong>Column Definitions</strong>
                <div style={{ overflowY: 'auto', maxHeight: '150px' }}>
                  {template?.columnDefinitions.map((v, i) => (
                    <>
                      Column {i + 1}: {v}
                      <br />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </Card>
          <Space value={20} />
          <Card>
            <h2 style={{ fontWeight: 300, color: 'black' }}>
              Step 2. Upload {template?.format ? template?.format : 'Data'}
            </h2>
            <div>
              <p>
                Drag files to the dotted space below or click “Choose File” to
                select your file.
              </p>
              <DropZonePure
                files={files}
                setFiles={setFiles}
                onSubmit={() => { }}
                hasUploadButton={false}
                text={`Drag ${template?.format || ''} Files Here`}
              />
            </div>
          </Card>
          <Space value={20} />
          <div style={{ display: 'flex', gap: '10px' }}>
            <Card>
              <h2 style={{ fontWeight: 300, color: 'black' }}>
                Step 3. Test data
              </h2>
              <div>
                <p>Now that the data is uploaded, let’s try an import.</p>
                <Space value={20} />
                <Button onClick={sendTest} disabled={isUploading}>Start Test</Button>
                {testResults &&
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10 }}
                  >
                    {testResults.success &&
                      <p> We found {testResults.rowsAffected} rows of data to upload.  If that’s what you were expecting, you are ready for Step 4!</p>
                    }
                    <Button color='lightgreen' onClick={() => downloadTextFile(testResults.log, `${template?.name}-log-${moment().format('MMDDYY')}`)}>Download Log</Button>
                  </div>}
              </div>
            </Card>
            <Card>
              <h2 style={{ fontWeight: 300, color: 'black' }}>
                Step 4. Perform import
              </h2>
              <p>If your test was successful in Step 3, let’s start the import!</p>
              <Space value={20} />
              <Button onClick={importDataForReal} disabled={isUploading}>Import Data</Button>
              {uploadResults &&
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', paddingTop: 10}}
                >
                  {uploadResults.success &&
                    <p> Congratulations!  We imported {uploadResults.rowsAffected} rows of data.  If you have questions, you can always download the log.</p>}
                  <Button color='lightgreen' onClick={() => downloadTextFile(uploadResults.log, `${template?.name}-log-${moment().format('MMDDYY')}`)}>Download Log</Button>
                </div>}
            </Card>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DataImport;
