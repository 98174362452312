import React from "react";

const Accepted = () => {
  return (
    <svg
      width="31"
      height="23"
      viewBox="0 0 31 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2515 -0.000488281H2.56843C1.15051 -0.000488281 0.00012207 1.0752 0.00012207 2.40104V20.0123C0.00012207 21.3381 1.15051 22.4138 2.56843 22.4138H28.2515C29.6694 22.4138 30.8198 21.3381 30.8198 20.0123V2.40104C30.8198 1.0752 29.6694 -0.000488281 28.2515 -0.000488281ZM9.41724 4.80257C11.306 4.80257 12.8416 6.23849 12.8416 8.00461C12.8416 9.77074 11.306 11.2067 9.41724 11.2067C7.52846 11.2067 5.99283 9.77074 5.99283 8.00461C5.99283 6.23849 7.52846 4.80257 9.41724 4.80257ZM15.4099 16.6501C15.4099 17.1805 14.8749 17.6107 14.2114 17.6107H4.62307C3.95959 17.6107 3.42453 17.1805 3.42453 16.6501V15.6895C3.42453 14.0985 5.03507 12.8077 7.02015 12.8077H7.28768C7.94581 13.0628 8.6628 13.2079 9.41724 13.2079C10.1717 13.2079 10.894 13.0628 11.5468 12.8077H11.8143C13.7994 12.8077 15.4099 14.0985 15.4099 15.6895V16.6501ZM27.3954 14.0084C27.3954 14.2286 27.2027 14.4087 26.9673 14.4087H19.2624C19.027 14.4087 18.8343 14.2286 18.8343 14.0084V13.2079C18.8343 12.9878 19.027 12.8077 19.2624 12.8077H26.9673C27.2027 12.8077 27.3954 12.9878 27.3954 13.2079V14.0084ZM27.3954 10.8064C27.3954 11.0265 27.2027 11.2067 26.9673 11.2067H19.2624C19.027 11.2067 18.8343 11.0265 18.8343 10.8064V10.0059C18.8343 9.78575 19.027 9.60563 19.2624 9.60563H26.9673C27.2027 9.60563 27.3954 9.78575 27.3954 10.0059V10.8064ZM27.3954 7.60436C27.3954 7.8245 27.2027 8.00461 26.9673 8.00461H19.2624C19.027 8.00461 18.8343 7.8245 18.8343 7.60436V6.80385C18.8343 6.58371 19.027 6.40359 19.2624 6.40359H26.9673C27.2027 6.40359 27.3954 6.58371 27.3954 6.80385V7.60436Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Accepted;
