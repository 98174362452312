import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/scss/main.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider, withAuthenticationRequired } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";

const MyProtectedComponent = withAuthenticationRequired(App);
const isTraining = process.env.REACT_APP_ENV === "training"

if(process.env.REACT_APP_ENV === 'production') {
  Sentry.init({
    dsn: "https://f4f22def2d6b4c55ae19b26071889c12@o1121726.ingest.sentry.io/4505167602712576",
  });
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPE}
      screen_hint={ isTraining ? "signup" : "login"}
    >
      <MyProtectedComponent>
        <App />
      </MyProtectedComponent>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
