import React, { useContext, useEffect } from 'react';
import UserContext from '../contexts/userContext';
import LoadingBox from '../components/UI/LoadingBox/LoadingBox';

interface LoadingScreenProps {
  children: React.ReactNode;
}

const LoadingScreen: React.FC<LoadingScreenProps> = ({ children }) => {
  const dbUser = useContext(UserContext);

  const quotes = [
    `"Hi hungry! I'm dad!" - Dad`,
    `"We cannot solve our problems with the same thinking we used when we created them." - Albert Einstein`,
    `"Know thy self, know thy enemy. A thousand battles, a thousand victories." - Sun tzu`,
    `"I was just looking at my ceiling. Not sure if it's the best ceiling in the world, but it's definitely up there." - Dad`,
    `"It is during our darkest moments that we must focus to see the light." - Aristotle`,
    `"You miss 100% of the shots you don't take." -Wayne Gretzky`,
  ];

  const isLoading = !!!dbUser;
  const [isTakingTooLong, setIsTakingTooLong] = React.useState(false);
  const rand = React.useRef(Math.round(Math.random() * (quotes.length - 1)));

  useEffect(() => {
    setTimeout(() => {
      setIsTakingTooLong(true);
    }, 14000);
  }, []);

  /**
   * 
   */

  return (
    <>
      {!isLoading && children}
      {isLoading && (
        <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 30,
              width: 600
            }}
          >
            <img
              src="https://connect.communityfinancialresources.org/images/logo.svg"
              alt="Community Financial Resources"
              style={{ display: 'block', maxWidth: '350px',  textAlign: 'center', fontSize: '1.2em', lineHeight: '1.3', fontWeight: 'normal' }}
            />
            <h3 style={{ textAlign: 'center', fontSize: '1.2em', lineHeight: '1.3', maxWidth: '450px', margin: 'auto' }}>
              We take security of your information seriously. Please wait
              while we validate your login.
            </h3>
          </div>
          <LoadingBox
            loadingType="ripple"
            isLoading={isLoading}
          />
          <div style={{ width: 600 }}>
            {!isTakingTooLong ? (
              <h5 style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>{quotes[rand.current].split('" -').map((v, i) => i === 0 ? <div style={{textAlign: 'center', fontSize: 17, fontWeight: 700}}>{v}"</div> : <div style={{textAlign: 'center', fontSize: 17}}>-{v}</div>)}</h5>
            ) : (
              <h3 style={{ marginBottom: 40, textAlign: 'center' }}>
                This is taking Longer than expected... Try refreshing the page,
                or clearing your browser data.
              </h3>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default LoadingScreen;
