import React, { useEffect, useState } from "react";
import classes from "./Users.module.scss";
import { getUserProfilePicture } from "../../api/api";

type StatusType = "active" | "deactivated" | "invited" | "legacy" | "migrated";
type RoleType = "Administrator" | "Super Coordinator" | "Coordinator";
type VoidFunction = (item: any) => void;

const UserTableEntry = ({
  id,
  name,
  email,
  status,
  last_login,
  active_since,
  location,
  role,
  item,
  resetPasswordHandler,
  openUserDetailHandler,
  deactivateHandler,
  resendInvitationHandler,
  cancelInvitationHandler,
}: {
  id: string;
  name: string;
  email: string;
  status: StatusType;
  last_login: string;
  active_since: string;
  location: string;
  role: RoleType;
  item: any;
  resetPasswordHandler: VoidFunction;
  openUserDetailHandler: VoidFunction;
  deactivateHandler: VoidFunction;
  resendInvitationHandler: VoidFunction;
  cancelInvitationHandler: VoidFunction;
}) => {
  const [pfp, setPfp] = useState<string | 'empty' | false>('empty');

  useEffect(() => {
    if(pfp === 'empty') {
      getUserProfilePicture(id).then((r) => {
        if(r === null) {
          setPfp(false);
          return;
        }
        setPfp(r);
      });
    }
  })

  useEffect(() => {
    setPfp('empty');
  }, [id])

  return (
    <tr key={id}>
      <td style={{display:'flex', flexDirection: 'row', alignItems: 'center'}}>
        {['active', 'migrated'].includes(status) && pfp !== 'empty' && pfp !== false ?
          <img src={pfp} alt={name + "'s profile picture"} width={50} height={50} style={{marginRight: 10 + 'px', borderRadius: 2 + 'px'}}/>
          :
          <></>
        }
        <div>
          {name}
          <br />
          <span className={classes.Table__username}>{id.trim()}</span>
        </div>
      </td>
      <td>
        <a href={`mailto:${email}`} className={classes.Table__email}>
          {email}
        </a>
      </td>
      <td className={`${classes.Table} ${classes.status}`}>{status}</td>
      <td>{last_login === "12/31/1969" ? "" : last_login}</td>
      <td>{active_since === "12/31/1969" ? "" : active_since}</td>
      <td>
        {location}
        <br />
        <span className={classes.Table__username}>{role}</span>
      </td>
      <td>
        {item.status === "invited" ? (
          <div className={classes.Table__action}>
            <span onClick={() => openUserDetailHandler(item)}>
              View Details
            </span>
            <span onClick={() => resendInvitationHandler(item)}>
              Resend Invitation
            </span>
            <span onClick={() => cancelInvitationHandler(item)}>
              Cancel Invitation
            </span>
          </div>
        ) : (
          <div className={classes.Table__action}>
            <span onClick={() => openUserDetailHandler(item)}>
              View Details
            </span>
            {
              <span onClick={() => resetPasswordHandler(item)}>
                {(() => {
                  if(item.status === 'legacy') {
                    return "Migrate Account";
                  }else if(['cancelled', 'deactivated'].includes(item.status)) {
                    return '';
                  }
                  return "Reset Password";
                })()}
              </span>
            }
            {
              !['cancelled', 'deactivated'].includes(item.status) &&
              <span onClick={() => deactivateHandler(item)}>Deactivate</span>
            }
            
          </div>
        )}
      </td>
    </tr>
  );
};

export default UserTableEntry;