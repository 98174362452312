import classes from "./Modal.module.scss";
import { motion } from 'framer-motion';

const ModalBig = (props) => {
  const titleCls = [
    classes.ModalBig__title,
    props.titlePosition ? classes[props.titlePosition] : "",
  ];

  return (
    <div className={classes.Modal}>
      <div className={classes.Modal__wrapper}>
        <motion.div initial={{opacity: 0, y: '100vh', scale: 0.2 }} animate={{opacity: 1, y: 0, scale: 1 }} transition={{ duration: 0.3 }} className={classes.ModalBig__body}>
          <div className={classes.ModalBig__header}>
            <div className={classes.ModalBig__close} onClick={props.onClose}>
              ×
            </div>
            <h2 className={titleCls.join(" ")}>{props.title}</h2>
          </div>

          <div className={classes.ModalBig__content}>{props.children}</div>
        </motion.div>
      </div>

      <motion.div initial={{opacity: 0 }} animate={{opacity: 1 }} transition={{ duration: 0.2 }} className={classes.Modal__overlay}></motion.div>
    </div>
  );
};

export default ModalBig;
