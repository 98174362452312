import React from "react";

const Copy = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7192 2.78857H7.21895C7.08841 2.78858 6.96322 2.84044 6.87092 2.93274C6.77862 3.02505 6.72676 3.15024 6.72675 3.28078V6.72626H3.28126C3.15073 6.72627 3.02554 6.77813 2.93323 6.87043C2.84093 6.96274 2.78907 7.08793 2.78906 7.21846V17.7187C2.78907 17.8493 2.84093 17.9745 2.93323 18.0668C3.02554 18.1591 3.15073 18.2109 3.28126 18.2109H13.7815C13.9121 18.2109 14.0373 18.1591 14.1296 18.0668C14.2219 17.9745 14.2737 17.8493 14.2737 17.7187V14.2733H17.7192C17.8498 14.2733 17.975 14.2214 18.0673 14.1291C18.1596 14.0368 18.2114 13.9116 18.2114 13.7811V3.28078C18.2114 3.15024 18.1596 3.02505 18.0673 2.93274C17.975 2.84044 17.8498 2.78858 17.7192 2.78857ZM13.2893 17.2265H3.77346V7.71067H13.2893V17.2265ZM17.227 13.2889H14.2737V7.21846C14.2737 7.08793 14.2219 6.96274 14.1296 6.87043C14.0373 6.77813 13.9121 6.72627 13.7815 6.72626H7.71115V3.77298H17.227V13.2889Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Copy;
