import { useReducer } from "react";

const initialInputState = (value) => {
  return {
    value: value || "",
    isTouched: value ? true : false,
    isFocused: false
  };
};

const inputStateReducer = (state, action) => {
  if (action.type === "INPUT") {
    return {
      value: action.value,
      isTouched: state.isTouched,
      isFocused: true
    };
  }
  if (action.type === "BLUR") {
    return {
      isTouched: true,
      value: state.value,
      isFocused: false
    };
  }

  if (action.type === "RESET") {
    return {
      value: "",
      isTouched: false,
      isFocused: false
    };
  }

  return initialInputState;
};

/**
 * 
 * @param {(value: string | null) => boolean} validateValue a function that returns whether or on the given input is valid 
 * @param {any=} value 
 * @param {any=} type 
 * @returns {{value: string, isTouched: (e?: any) => void, isValid: boolean, isFocused: (e?: any) => void, hasError: boolean, inputChangeHandler: (e?: any) => void, inputBlurHandler: (e?: any) => void, reset: () => void}}
 */
const useInput = (validateValue, value, type) => {
  const [inputState, dispatch] = useReducer(
    inputStateReducer,
    initialInputState(value)
  );
  
  let hasError;
  let valueIsValid;

  valueIsValid = Boolean(validateValue(inputState.value));
  hasError = !valueIsValid && inputState.isTouched;

  const inputChangeHandler = (event) => {
    if (type === "number") {
      const re = /^[0-9\b]+$/;

      if (event.target.value === "" || re.test(event.target.value)) {
        dispatch({ type: "INPUT", value: event.target.value });
      }
    } else {
      dispatch({ type: "INPUT", value: event.target.value });
    }
  };

  const inputBlurHandler = () => {
    dispatch({ type: "BLUR" });
  };

  const reset = () => {
    dispatch({ type: "RESET" });
  };

  const setValue = (value) => {
    if (value) {
      inputChangeHandler({target: { value }})
    } else {
      inputChangeHandler({target: { value: "" }})
    }
  }

  return {
    value: inputState.value,
    setValue,
    isTouched: inputState.isTouched,
    isValid: valueIsValid,
    isFocused: inputState.isFocused,
    hasError,
    inputChangeHandler,
    inputBlurHandler,
    reset
  };
};

export default useInput;
