import { useReducer } from "react";

const initialState = {
  status: "",
  text: "",
  btn_text: "",
  isShow: false,
};

const notificationReducer = (state, action) => {
  if (action.type === "ADD") {
    return {
      status: action.status,
      text: action.text,
      btn_text: action.btn_text,
      isShow: true,
    };
  }

  if (action.type === "REMOVE") {
    return initialState;
  }

  return initialState;
};

const useNotification = () => {
  const [notificationState, dispatch] = useReducer(
    notificationReducer,
    initialState
  );

  const add = ({status, text, btn_text,}) => {
    dispatch({ type: "ADD", status, text, btn_text });
  };

  const remove = () => {
    dispatch({ type: "REMOVE" });
  };

  return {
    status: notificationState.status,
    width: notificationState.width,
    text: notificationState.text,
    btn_text: notificationState.btn_text,
    isShow: notificationState.isShow,
    add,
    remove,
  };
};

export default useNotification;
