import classes from './Card.module.scss';
import { motion } from 'framer-motion';

const content = {
    initial: { y: -20, opacity: 0 },
    animate: {
        y: 0,
        opacity: 1,
        transition: {
            duration: 0.7,
            ease: [0.6, -0.05, 0.01, 0.99],
            staggerChildren: 0.1,
            delayChildren: 2.8,
        },
    },
};

const Card = (props) => {
    return (
        <motion.div
            variants={content}
            initial="initial"
            animate="animate"
            className={`${classes.Card} ${props.className || ''}`}
        >
            {props.children}
        </motion.div>
    );
};

export default Card;
