import { useState, useEffect } from "react";
import Modal from '../UI/Modal/Modal';
import Input from '../UI/Input/Input';
import useInput from '../../hooks/useInput';
import Button from '../UI/Button/Button';
import Space from '../UI/Space/Space';
import { getSavedQueries } from '../../api/api';
// @ts-ignore
import { ErrorMessageProviderBuilder } from "../UI/Input/ErrorMessageProviderBuilder.ts";
import React from "react";
import { SavedQueriesObject } from "../../api/queries.ts";
import SQLEditor from "./SQLEditor.js";


const SaveModal = ({onSubmit, closeModal, preset_info}: {onSubmit: (queryName, queryDescription, query) => Promise<void>, closeModal: () => void, preset_info?: SavedQueriesObject}) => {
    const verifier = new ErrorMessageProviderBuilder("name").isUpTo(50).isntEmpty().addCondition(
        (value) => (alreadySaved !== null && (alreadySaved.includes(value) && preset_info?.name !== value) ? `You already have a query named "${value}" please choose a different name` : false)
    );
    const [alreadySaved, setAlreadySaved] = useState<any[] | null>(null)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    useEffect(() => {
        setButtonDisabled(true);
        getSavedQueries().then(saved => {
            setAlreadySaved(saved.map(s => s.name));
            setButtonDisabled(false);
        })
    }, [])

    const {
        value: name,
        hasError: nameHasError,
        inputBlurHandler: nameBlur,
        inputChangeHandler: nameChangeHandler
    } = useInput(value => {
        return verifier.passes(value);
    }, preset_info && preset_info.name)

    const {
        value: description,
        inputChangeHandler: descriptionChangeHandler
    } = useInput(value => {
        return verifier.passes(value);
    }, preset_info && preset_info.description)
    
    const [query, setQuery] = useState((preset_info && preset_info.sql_query) || '');

    useEffect(() => {
        console.log(verifier.build()(name))
    }, [verifier, name])
    

    const submitHandler = () => {
        if(nameHasError) {
            return;
        }
        nameBlur();
        setButtonDisabled(true);
        onSubmit(name, description, query).then(() => {
            setButtonDisabled(false)
        })
    }


    return (
        <Modal onClose={closeModal} title="Save Query As...">
            <div style={{width: preset_info ? '60vw' : '30vw'}}></div>
            <Space value={25}/>
            <Input label={'Query Name *'} value={name} onChange={nameChangeHandler} hasError={nameHasError} onBlur={nameBlur} errorMessageProvider={verifier.build()}/>
            <Input label={'Description'} value={description} onChange={descriptionChangeHandler}/>
            <Space value={35}/>
            {preset_info && <>
                <SQLEditor setValue={setQuery} value={query} getTables={() => []}/>
                <Space value={35}/>
            </>}
            <div style={{display: 'flex', justifyContent:'center', width: 100 + '%'}}>
                <Button onClick={submitHandler} disabled={buttonDisabled}>Save Query</Button>
            </div>
        </Modal>
    )
}


export default SaveModal;