import { useState } from "react";
import ActivityItem from "./ActivityItem";
import { getActivity } from "../../../api/api";
import classes from "./Activity.module.scss";

const Activity = ({ activityList }) => {
  const [list, setList] = useState(activityList.items);
  const [summary, setSummary] = useState(activityList.summary);

  const loadMoreHandler = () => {
    const pageNumber = +summary.current_page + 1;

    getActivity(pageNumber, 6).then((response) => {
      setList((prevState) => {
        return [...prevState, ...response.items];
      });

      setSummary(response.summary);
    }).catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className={classes.Activity__wrap}>
      {list.map((item) => (
        <ActivityItem key={item.enrollment_id + `_${Math.random()}`} item={item} />
      ))}

      {summary.more_items ? (
        <span
          className={`link ${classes.Activity__more}`}
          onClick={loadMoreHandler}
        >
          view more activity {'>'}
        </span>
      ) : null}
    </div>
  );
};

export default Activity;
