import classes from "./Banner.module.scss";

const Banner = (props) => {
  return (
    <div
      className={classes.Banner}
      style={{ backgroundImage: `url(${props.image})` }}
    >
      <div className={`container`}>
        <h1 className={classes.Banner__title}>{props.title}<span>{props.subtitle}</span></h1>
        <p className={classes.Banner__description}>{props.description}</p>
      </div>
    </div>
  );
};

export default Banner;
