import React from "react";

const IconMenu = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 6C4.65 6 6 4.65 6 3C6 1.35 4.65 0 3 0C1.35 0 0 1.35 0 3C0 4.65 1.35 6 3 6ZM12 24C13.65 24 15 22.65 15 21C15 19.35 13.65 18 12 18C10.35 18 9 19.35 9 21C9 22.65 10.35 24 12 24ZM3 24C4.65 24 6 22.65 6 21C6 19.35 4.65 18 3 18C1.35 18 0 19.35 0 21C0 22.65 1.35 24 3 24ZM3 15C4.65 15 6 13.65 6 12C6 10.35 4.65 9 3 9C1.35 9 0 10.35 0 12C0 13.65 1.35 15 3 15ZM12 15C13.65 15 15 13.65 15 12C15 10.35 13.65 9 12 9C10.35 9 9 10.35 9 12C9 13.65 10.35 15 12 15ZM18 3C18 4.65 19.35 6 21 6C22.65 6 24 4.65 24 3C24 1.35 22.65 0 21 0C19.35 0 18 1.35 18 3ZM12 6C13.65 6 15 4.65 15 3C15 1.35 13.65 0 12 0C10.35 0 9 1.35 9 3C9 4.65 10.35 6 12 6ZM21 15C22.65 15 24 13.65 24 12C24 10.35 22.65 9 21 9C19.35 9 18 10.35 18 12C18 13.65 19.35 15 21 15ZM21 24C22.65 24 24 22.65 24 21C24 19.35 22.65 18 21 18C19.35 18 18 19.35 18 21C18 22.65 19.35 24 21 24Z" fill="#6D6D6E"/>
        </svg>
    );
};

export default IconMenu;
