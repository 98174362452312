import Button from "../../UI/Button/Button";
import Space from "../../UI/Space/Space";

import classes from "./Files.module.scss";

const DeleteModal = ({ onClose, onDelete, fileName }) => {
  const closeClickHandler = () => {
    onClose(false);
  };

  const deleteFileHandler = () => {
    onDelete();
  };

  return (
    <div className={classes.Modal}>
      <div className={classes.Modal__wrapper}>
        <div className={classes.Modal__body}>
          <div className={`${classes.Modal__content} ${classes.small}`}>
            <h2 className={classes.Modal__title}>Confirm Delete</h2>

            <p className={classes.Modal__text}>
              You are about to perminantly delete this file: <b>{fileName}</b>
            </p>

            <Space value="20" />

            <p className={classes.Modal__text}>
              This operation can not be undone. Are you sure you want to
              continue?
            </p>

            <div className={classes.Modal__footer}>
              <Button color="secondary" onClick={closeClickHandler}>
                Cancel Delete
              </Button>

              <Button color="primary" onClick={deleteFileHandler}>
                Delete File
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.Modal__overlay}></div>
    </div>
  );
};

export default DeleteModal;
