import classes from "./Button.module.scss";

/* 
    color = [primary, secondary]
*/

/**
 * 
 * @param {{color?: 'primary' | 'secondary' | 'lightgreen' | string, clsType?: string, className?: string, type?: 'button' | 'submit' | 'reset', onClick?: React.MouseEventHandler<HTMLButtonElement>, disabled?: boolean, children}} props 
 * @returns 
 */
const Button = (props) => {
  const cls = [
    classes.Button,
    props.color ? classes[props.color] : classes.primary,
    props.clsType ? classes[props.clsType] : "",
    props.className || ''
  ];

  const type = props.type || "button";

  return (
    <button
      type={type}
      onClick={props.onClick}
      className={cls.join(" ")}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
};

export default Button;
