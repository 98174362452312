import ModalBig from "../UI/Modal/ModalBig";

import Cancel from "../../assets/svg/Cancel";
import Failure from "../../assets/svg/Failure";
import Accepted from "../../assets/svg/Accepted";
import New from "../../assets/svg/New";
import classes from "./EnrollmentDetail.module.scss";

const StatusCodeModal = ({ onClose }) => {
  return (    
    <ModalBig
      title="Enrollment Status Codes"
      titlePosition="left"
      onClose={onClose}
    >
      <div className={classes.Modal__body}>
        <div className={classes.Modal__left}>
          <div className={classes.Modal__item}>
            <div className={classes.Modal__icon}>
              <New />
            </div>
            <div className={classes.Modal__info}>
              <h2>New</h2>
              <p>
                Enrollment just entered into CFR card - for tracking purposes.
              </p>
            </div>
          </div>

          <div className={classes.Modal__item}>
            <div className={classes.Modal__icon}>
              <Accepted />
            </div>
            <div className={classes.Modal__info}>
              <h2>Accepted</h2>
              <p>
                Enrollment has been accepted by the card processor. Please note:
                there may be other verification steps after it is accepted by
                the processor.
              </p>
            </div>
          </div>
        </div>

        <div className={classes.Modal__col}>
          <div className={classes.Modal__item}>
            <div className={classes.Modal__icon}>
              <Failure />
            </div>
            <div className={classes.Modal__info}>
              <h2>Failure</h2>
              <p>
                Error was returned from the card process for this enrollment.
              </p>
            </div>
          </div>

          <div className={classes.Modal__item}>
            <div className={classes.Modal__icon}>
              <Cancel />
            </div>
            <div className={classes.Modal__info}>
              <h2>Cancel</h2>
              <p>
                Administrator has logged cancellation of this enrollment. Please
                note this does not necessarily mean the card was cancelled by
                the card processor.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalBig>
  );
};

export default StatusCodeModal;
