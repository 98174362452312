import { MaterialReactTable } from 'material-react-table';
import classes from './CBOs.module.scss';
import moment from 'moment'
import { useState } from 'react';
import { executeCBOBulkAction } from '../../api/api';
import { Box } from '@mui/material';

function EditIcon({ cboId, editCbo }) {
    return (
        <div onClick={() => editCbo(cboId)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M4.5 19.4998H5.6L16.675 8.4248L15.575 7.3248L4.5 18.3998V19.4998ZM19.85 7.3498L16.65 4.1498L17.7 3.0998C17.9833 2.81647 18.3333 2.6748 18.75 2.6748C19.1667 2.6748 19.5167 2.81647 19.8 3.0998L20.9 4.1998C21.1833 4.48314 21.325 4.83314 21.325 5.2498C21.325 5.66647 21.1833 6.01647 20.9 6.2998L19.85 7.3498ZM18.8 8.3998L6.2 20.9998H3V17.7998L15.6 5.1998L18.8 8.3998ZM16.125 7.8748L15.575 7.3248L16.675 8.4248L16.125 7.8748Z"
                    fill="black"
                    fill-opacity="0.87"
                />
            </svg>
        </div>
    );
}
function TrashIcon({ cboId, deleteCBO }) {
    return (
        <div onClick={() => deleteCBO(cboId)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M6.525 21C6.10833 21 5.75417 20.8542 5.4625 20.5625C5.17083 20.2708 5.025 19.9167 5.025 19.5V5.25H4V3.75H8.7V3H15.3V3.75H20V5.25H18.975V19.5C18.975 19.9 18.825 20.25 18.525 20.55C18.225 20.85 17.875 21 17.475 21H6.525ZM17.475 5.25H6.525V19.5H17.475V5.25ZM9.175 17.35H10.675V7.375H9.175V17.35ZM13.325 17.35H14.825V7.375H13.325V17.35ZM6.525 5.25V19.5V5.25Z"
                    fill="black"
                    fill-opacity="0.87"
                />
            </svg>
        </div>
    );
}

/**
 *
 * @param {{data: any, bulkActionRef: ((action: any[]) => Promise, callback: (res: any) => void) => void, editCbo: () => void, deleteCBO: () => void, addNotification: (config: any) => {}, refreshData: () => void}} param0
 * @returns
 */
function CBOSearchTable({data, bulkActionRef, editCbo, deleteCBO, addNotification, refreshData}) {

    const [rowSelection,setRowSelection ] = useState([])

    const columns = [
        {
            accessorKey: "name",
            header: "CBO Name"
        },
        {
            accessorKey: "description",
            header: "CBO Description"
        },
        {
            accessorKey: "location_count",
            header: "Active Locations"
        },
        {
            accessorKey: "ytd_enrollment_count",
            header: "YTD Enrollments"
        },

        {
            accessorKey: "latest_enrollment_timestamp",
            header: "Last Enrollment Date",
            accessorFn: (row) => new Date(row.latest_enrollment_timestamp),
            Cell: ({ cell }) => {
                const val = moment(cell.getValue());
                if(!val.isValid()) {
                    return "N/a";
                }
                return val.format('M / DD / YYYY')
            },
            sortingFn: 'datetime',
        }
    ]

    /**
     * This function executes a bulk action on all currently selected rows
     * @param {string} actionTitle The name of the action to be executed
     */
    async function bulkAction(actionTitle) {
        // console.log('row select', rowSelection)
        // rowSelection.forEach((row) => {
        //     action(row).then(res => {
        //         callback(res);
        //     })
        // })

        const ids = Object.keys(rowSelection).map(id => parseInt(id));

        if(ids.length === 0) {
            addNotification({
                status: "error",
                text: `No CBOs were selected`,
                btn_text: "OK",
            });

            
            return;
        }

        if(!window.confirm(`Are you sure you want to ${actionTitle} ${ids.length} cbo${ids.length === 1 ? '' : 's'}`)) {
            return;
        }
        

        const cbos = data.filter((d) => ids.includes(d.id))

        const cbosWithLocations = cbos.filter(cbo => cbo.location_count > 0);

        if(cbosWithLocations.length > 0) {
            addNotification({
                status: "error",
                text: `The CBO "${cbosWithLocations[0].name}" has one or more locations associated.`,
                btn_text: "OK",
            });
            return;
        }

        try {
            const response = await executeCBOBulkAction(actionTitle, ids)

            if(!response.ok) {
                addNotification({
                    status: "error",
                    text: response.globalError,
                    btn_text: "OK",
                });
            }

            if(Object.keys(response.failures).length === 0) {
                addNotification({
                    status: "success",
                    text: "All Changes succeeded!",
                    btn_text: "OK",
                });

                setRowSelection({});
            }else {
                addNotification({
                    status: "error",
                    text: JSON.stringify(response.failures),
                    btn_text: "OK",
                });
            }

            refreshData();
        }catch(e) {
            console.error(e)
            addNotification({
                status: "error",
                text: "Failed for unknown reason",
                btn_text: "OK",
            });
        }




    }

    bulkActionRef.current = bulkAction;

    return (
        <>
            <div className={classes.Table__container}>
                <div className={classes.Table__container__inner}>
                    <Box sx={{borderRadius: '10px', border: 'solid 1px #e0e0e0', padding: '0px 0 15px 0'}}>
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        // initialState={{density: 'compact'}}
                        enableColumnResizing
                        enableRowSelection
                        muiTablePaperProps={{
                            elevation: 0,
                            sx: {
                                border: 'none',
                            },
                        }}
                        muiTableHeadCellProps={{
                            sx: {
                                fontSize: '14px',
                                fontFamily: 'Avenir',
                                fontWeight: 500,
                                opacity: 0.87,
                                background: '#F6F6F6',
                            },
                        }}
                        muiTableBodyCellProps={{
                            sx: {
                                fontFamily: 'Avenir',
                                fontWeight: 350,
                                opacity: 1,
                                letterSpacing: '0.25px',
                            },
                        }}
                        muiTablePaginationProps={{
                            // rowsPerPageOptions: [5, 10],
                            showFirstButton: true,
                            showLastButton: true,
                        }}
                        positionActionsColumn='last'
                        enableRowActions
                        renderRowActions={({ cell, row, table }) => {

                            return (<div className={classes.Table__iconContainer}>
                                <EditIcon cboId={row.original.id} editCbo={editCbo} />
                                <TrashIcon cboId={row.original.id} deleteCBO={deleteCBO} />
                            </div>)
                        }}
                        enableColumnFilters={false}
                        enableGlobalFilter={false}
                        enableTopToolbar={true}
                        enableColumnDragging={false}
                        enableColumnOrdering={true}
                        onRowSelectionChange={setRowSelection}
                        getRowId={r => r.id}
                        state={{ rowSelection }}
                    />
                    </Box>
                </div>
            </div>
        </>
    );
}

export default CBOSearchTable;