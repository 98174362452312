import classes from "./Input.module.scss";
import { MdHelp, MdCheckCircle } from "react-icons/md";
import Calendar from "../../../assets/svg/Calendar";
import React, { useEffect, useRef, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton } from "@mui/material";

function formatPhoneNumber(value, disableValidation) {
  // if input value is falsy eg if the user deletes the input, then just return
  // if validation is disabled for testing just return
  if (!value || disableValidation) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}

function setIcon(props) {
  if (props.type === "date") {
    return (
      <span className={`${classes.Icon} ${classes.Icon__date}`}>
        <Calendar />
      </span>
    );
  }

  if (!props.touched) {
    return null;
  }

  if (props.hasError) {
    return <ErrorIcon />;
  } else {
    return <SuccessIcon />;
  }
}

const ErrorIcon = () => {
  return (
    <span className={`${classes.Icon} ${classes.Icon__error}`}>
      <MdHelp />
    </span>
  );
};

const SuccessIcon = () => {
  return (
    <span className={`${classes.Icon} ${classes.Icon__success}`}>
      <MdCheckCircle />
    </span>
  );
};

const PhoneInput = (props) => {
  const input = useRef("null")
  const [inputValue, setInputValue] = useState(props.value);
  const inputType = props.type || "text";
  const cls = [classes.Input, props.clsType ? classes[props.clsType] : ""];
  const htmlFor = `${inputType}-${Math.random()}`;

  if (props.hasError) {
    cls.push(classes.invalid);
  }

  const icon = setIcon(props);

  const handleInput = (e) => {
    // this is where we'll call the phoneNumberFormatter function
    const formattedPhoneNumber = formatPhoneNumber(e.target.value, props.disableValidation);
    // we'll set the input value using our setInputValue
    const newEvent = e
    newEvent.target.value = formattedPhoneNumber
    props.onChange(newEvent)
    setInputValue(formattedPhoneNumber);
  };

  useEffect(() => {
    if (props.value === "") {
      setInputValue("")
    }
  }, [props.value])

  return (
    <div className={cls.join(" ")}>
      {props.label && (
        <label htmlFor={htmlFor}>
          {props.label}{" "}
          {props.hasError ? (
            <span className={classes.Input__error}>
              {props.errorMessageProvider ? props.errorMessageProvider(input) : props.errorMessage || "Enter valid data"}
            </span>
          ) : null}
        </label>
      )}
      <input
        type={inputType}
        id={htmlFor}
        placeholder={props.placeholder}
        value={inputValue}
        onChange={(e) => handleInput(e)}
        onBlur={props.onBlur}
        disabled={props.disabled}
        required={props.required}
        role="presentation"
        autoсomplete="off"
      />

      {icon}
      {props.cancel && (
        <IconButton className={classes.Icon__cancel} aria-label="cancel" onClick={props.cancel} >
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
};

export default PhoneInput;
