import classes from "./HelpfulInformation.module.scss";

const HelpfulInformation = ({ cbo }) => {
  return (
    <div className={classes.HelpfulInformation}>
      <div dangerouslySetInnerHTML={{ __html: cbo.helpful_information }} />

      { cbo.helpful_links.length > 0 && (
        <div>
          <span>Important Links:</span>
          <br />
          {cbo.helpful_links.map((link) => (
            <a key={link.url} href={link.url} target="_blank" rel="noreferrer">
              {link.text}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default HelpfulInformation;
