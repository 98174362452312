import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './LoadingBox.css'; // Import the CSS for styling

/**
 *
 * @param {{keepButHidden?: boolean, spinnerStyle?: React.CSSProperties, isLoading: boolean, children?: React.ReactNode, loadingType: string}} param0
 * @returns
 */
const LoadingBox = ({
  children,
  isLoading,
  spinnerStyle = {},
  keepButHidden = false,
  loadingType,
}) => {
  const spinnerVariants = {
    initial: { rotate: 0 },
    animate: { rotate: 360 },
    exit: { scale: 0, transition: { duration: 0.5 } },
  };

  const boxVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
  };

  const loading = {
    spinner: (
      <div style={{ ...spinnerStyle }}>
        <motion.div
          className={'spinner'}
          variants={spinnerVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          key={'spinner'}
          transition={{ repeat: Infinity, duration: 1 }}
        />
      </div>
    ),
    ripple: (
      <div style={{ ...spinnerStyle }}>
        <img src="/Ripple@1x-1.0s-200px-200px.gif" alt="loading" />
      </div>
    ),
  };

  return (
    <AnimatePresence>
      {keepButHidden ? (
        <>
          {isLoading && loading[loadingType || 'spinner']}
          <motion.div
            className="content-box"
            variants={boxVariants}
            initial="initial"
            animate="animate"
            key={'content'}
            style={{ display: isLoading ? 'none' : 'block' }}
          >
            {children}
          </motion.div>
        </>
      ) : isLoading ? (
        loading[loadingType || 'spinner']
      ) : (
        <motion.div
          className="content-box"
          variants={boxVariants}
          initial="initial"
          animate="animate"
          key={'content'}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default LoadingBox;
