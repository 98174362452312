import { useNavigate, createSearchParams } from "react-router-dom";

import Button from "../UI/Button/Button";
import Space from "../UI/Space/Space";

import classes from "./EnrollForm.module.scss";

const DuplicateModal = ({ onClose, first, last, dob }) => {
  const navigate = useNavigate();

  const closeClickHandler = () => {
    onClose(false);
  };

  const redirectDashboardHandler = () => {
    navigate("/");
  };

  const redirectSearchHandler = () => {
    window.open(`/search?${createSearchParams({
      first: first || "",
      last: last || "",
      dob: dob || "",
    })}`).focus();
  };

  return (
    <div className={classes.Modal}>
      <div className={classes.Modal__wrapper}>
        <div className={classes.Modal__body}>
          <div className={`${classes.Modal__content} ${classes.small}`}>
            <h2 className={classes.Modal__title}>Duplicate Suspected</h2>

            <p className={classes.Modal__text}>
              Based on the name and date of birth, this enrollment is suspected
              to be a duplicate of another enrollment found in the system for
              your location in the last 3 years.
            </p>

            <Space value="10" />

            <span className="link" onClick={redirectSearchHandler}>
              Click here to view prior enrollment details.
            </span>

            <div className={classes.Modal__footer}>
              <Button color="secondary" onClick={redirectDashboardHandler}>
                Cancel Enrollment
              </Button>

              <Button color="primary" onClick={closeClickHandler}>
                Enroll Anyway
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.Modal__overlay}></div>
    </div>
  );
};

export default DuplicateModal;
