import React from "react";

const Download = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.9872 12.4441V16.5878H3.01226V12.4441H0.326294V17.8058C0.326294 18.4781 0.926734 19.0233 1.67128 19.0233H18.3282C19.072 19.0233 19.6731 18.4787 19.6731 17.8058V12.4441H16.9872Z"
        fill="currentColor"
      />
      <path
        d="M9.75385 12.0333L5.90836 7.81878C5.90836 7.81878 5.32327 7.31776 5.95773 7.31776C6.5922 7.31776 8.12466 7.31776 8.12466 7.31776C8.12466 7.31776 8.12466 6.98072 8.12466 6.46095C8.12466 4.97907 8.12466 2.28216 8.12466 1.18271C8.12466 1.18271 8.03859 0.883789 8.53496 0.883789C9.03532 0.883789 11.2269 0.883789 11.5852 0.883789C11.9428 0.883789 11.9348 1.13551 11.9348 1.13551C11.9348 2.20169 11.9348 4.99178 11.9348 6.42525C11.9348 6.88996 11.9348 7.1913 11.9348 7.1913C11.9348 7.1913 13.1637 7.1913 13.9349 7.1913C14.7048 7.1913 14.1251 7.71592 14.1251 7.71592C14.1251 7.71592 10.8533 11.6551 10.397 12.0684C10.0687 12.3679 9.75385 12.0333 9.75385 12.0333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Download;
