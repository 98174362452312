import { useReducer } from 'react';
import useInput from '../hooks/useInput.js';
import {
  useFilters,
  FilterItemBuilder,
} from '../components/List/Common/DynamicFilters/FilterStateBuilder.ts';
import { getCBOs, getLocations } from '../api/api.js';

import { fetchUserReport } from '../api/users.ts';
import ReportPageTemplate from '../components/Reports/ReportPageTemplate.js';

const selectInitialState = () => {
  return { options: [], value: '' };
};

const selectReducer = (state, action) => {
  if (action.type === 'SET_OPTIONS') {
    return {
      options: action.payload,
      value: state.value,
    };
  }

  if (action.type === 'CHANGE') {
    return {
      options: state.options,
      value: action.payload,
    };
  }

  if (action.type === 'RESET') {
    return {
      options: state.options,
      value: '',
    };
  }

  return selectInitialState();
};

const AccountExport = () => {
  const filters = useFilters(
    (filterValuesRef) => {
      return {
        last_activity_start: new FilterItemBuilder()
          .setLabel('Last Activity Start Date')
          .setParamName('last_activity_start')
          .setType('date')
          .setPlaceholder('MM/DD/YYYY')
          .setErrorMessage('Begin and end are swapped.')
          .useBuildCombo(
            () => true,
            useInput
          ),
        username: new FilterItemBuilder()
          .setLabel('User Name')
          .setParamName('username')
          .setType('string')
          .useBuildCombo((v) => true, useInput
        ),
        name: new FilterItemBuilder()
          .setLabel('Name')
          .setParamName('name')
          .setType('string')
          .useBuildCombo((v) => true, useInput
        ),
        role: new FilterItemBuilder()
          .setLabel('Role')
          .setParamName('role')
          .select()
          .setOptions([
            { value: 'Administrator', label: 'Administrator' },
            { value: 'Super Coordinator', label: 'Super Coordinator' },
            { value: 'Coordinator', label: 'Coordinator' },
          ])
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        cbo_id: new FilterItemBuilder()
          .setLabel('CBO')
          .setParamName('cbo_id')
          .select()
          .setReference()
          .setGetterFunc(getCBOs)
          .setValueAndLabelParams('id', 'name')
        .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        location_id: new FilterItemBuilder()
          .setLabel('Locations')
          .setParamName('location_id')
          .select()
          .setReference()
          .setGetterFunc(getLocations)
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
      };
    },
    []
  );


  return (
    <>
      <ReportPageTemplate
        name="Account Export"
        description="Large export of all CBOs, Locations and Accounts."
        filters={filters}
        fetchRequest={fetchUserReport}
        fileName={"account export"}
        
        columns={[
          {
            accessorKey: 'cbo_name',
            header: 'CBO Name / Status',
          },
          {
            accessorKey: 'loc_name',
            header: 'Location Name / ID',
          },
          {
            accessorKey: 'acct_name',
            header: 'User Name / Username',
          },
          {
            accessorKey: 'acct_role',
            header: 'User Level',
          },
          {
            accessorKey: 'acct_lastlogin',
            header: 'Last Activity',
          }
        ]}
      />
    </>
  );
};

export default AccountExport;
