import 'bootstrap/dist/css/bootstrap.min.css';
import classes from "./HamburgerMenu.module.scss";
import IconMenu from "../../assets/svg/IconMenu";
import IconLogout from "../../assets/svg/IconLogout";
import Dropdown from "react-bootstrap/Dropdown";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {useAuth0} from "@auth0/auth0-react";
import {getMe} from "../../api/api";
import ReactDOM from "react-dom";
import ProfileModal from "../ProfileModal/ProfileModal";
import { useNavigate } from 'react-router-dom';
import UserContext from '../../contexts/userContext';
import { getBaseUrl } from '../../api/common.ts';

interface SiteLink {
    icon: string
    url: string
    name: string
    roles: string[]
}

type SiteLinks = {[key: string]: SiteLink};

const getMenuLinks = (siteLinks: SiteLinks, navigate, user) => {
    // const siteLinks = require("../../assets/json/" + process.env.REACT_APP_LINKS);
    const openInSameTab = (url) => {
        window.open(url, '_self', 'noreferrer');
    };

    const links: ReactNode[] = [];

    const evalSiteLink = (roles: string[], userRoles: string[]) => {
        for(const role of roles) {
            if(role.includes('*')) {
                let prefix = role.split('*')[0];

                let unprefixed = role.substring(prefix.length);

                if(unprefixed === "*") {
                    for(const userRole of userRoles) {
                        if(userRole.startsWith(prefix)) {
                            return true;
                        }
                    }
                }
            }
        }
        for(const userRole of userRoles) {
            if(roles.includes(userRole)) {
                return true;
            }
        }

        return false;
    }
    
    for (let key in siteLinks) {
        let name = siteLinks[key].name;
        if(user) {
            if(!evalSiteLink(siteLinks[key].roles, user.roles)) {
                continue;
            }
        }else {
            continue;
        }

        // let icon = require("../../assets/images/"++'.svg');
        let alt = "Icon " + name.replace("<br/>", " ");
        let url = siteLinks[key].url;

        function handleClick() {
            if (url.indexOf("http") !== -1) {// if external link open in new tab
                openInSameTab(url);
            } else {
                navigate(url);
            }
        }

        links.push(
            <Dropdown.Item key={key} className={classes.HM_Dropdown_Menu_Item} onClick={handleClick}>
                <div className={classes.HM_Dropdown_Menu_Item_Img_Wrp}>
                    <img src={new URL(`/api/v1/data/links/icon/${siteLinks[key].icon}`, getBaseUrl() || '').toString()} alt={alt} />
                </div>
                <Dropdown.ItemText className={classes.HM_Dropdown_Menu_Item_Text} dangerouslySetInnerHTML={{__html: name}}></Dropdown.ItemText>
            </Dropdown.Item>
        );
    }

    return links;
};

const HamburgerMenu = () => {
    const [siteLinks, setSiteLinks] = useState<SiteLinks | undefined>()
    const [show, setShow] = useState(false);
    const {user, logout} = useAuth0();
    const [showModal, setShowModal] = useState(false);
    const activeUser = useContext(UserContext);
    const navigate = useNavigate();
    const links = siteLinks && getMenuLinks(siteLinks, navigate, activeUser);

    useEffect(() => {
        fetch(new URL('/api/v1/data/links', getBaseUrl())).then(r => r.json().then(setSiteLinks))
    }, [])

    const openModalHandler = async () => {
        await getMe();
        setShowModal(true)
    };

    const closeModalHandler = () => {
        setShowModal(false)
    };

    return (
        <>
        {
            showModal ?
            ReactDOM.createPortal(
                <ProfileModal
                    onClose={closeModalHandler}
                    data={activeUser}
                />,
                document.querySelector("body") as HTMLBodyElement
            ) : ''
        }
        <Dropdown className={classes.HM_Dropdown} align={{ md: 'end' }} onToggle={(isOpen) => {
            window.scroll(0,0);
            setShow(isOpen);
        }}>
            <Dropdown.Toggle className={classes.HM_Dropdown_Toggle} variant="none">
                <IconMenu />
            </Dropdown.Toggle>
            <Dropdown.Menu className={`${classes.HM_Dropdown_Menu} ${ show ? " " + classes.HM_Dropdown_Menu_Active : "" }`}>
                <Dropdown.Item className={classes.HM_Dropdown_Menu_Item} onClick={() => openModalHandler() }>
                    <div className={classes.HM_Dropdown_Menu_Item_Img_Wrp}>
                        <img className={classes.HM_Dropdown_Menu_Item_Profile} src={user?.picture} alt={user?.name}/>
                    </div>
                    <Dropdown.ItemText className={classes.HM_Dropdown_Menu_Item_Text}>My Profile</Dropdown.ItemText>
                </Dropdown.Item>
                {links}
                <Dropdown.Item className={classes.HM_Dropdown_Menu_Item} onClick={() => {
                    localStorage.clear();
                    logout({ returnTo: window.location.origin });
                }}>
                    <div className={classes.HM_Dropdown_Menu_Item_Img_Wrp}>
                        <IconLogout />
                    </div>
                    <Dropdown.ItemText className={classes.HM_Dropdown_Menu_Item_Text}>Log Out</Dropdown.ItemText>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        </>
    );
};

export default HamburgerMenu;