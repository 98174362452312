import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout/Layout';
import Heading from '../components/Heading/Heading.tsx';
import { getCbo, getFullEnrollmentStatus } from '../api/api';
import classes from './pages.module.scss';
import Space from '../components/UI/Space/Space';
import Card from '../components/Card/Card';
import { useNavigate } from 'react-router-dom';

const EnrollDisabled = () => {
    const [updates, setUpdates] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        getCbo().then((cbo) => {
            if (cbo) {
                setUpdates(cbo.helpful_information.trim() + cbo.message_board.trim());
            }
        });
    });

    useEffect(() => {
        getFullEnrollmentStatus().then(status => {
          console.log('checking status')
          if(status.enabled) {
            navigate('/enroll');
          }

          setMessage(status.reason);
        })
      }, [navigate])

    return (
        <Layout>
            <Heading
                title="Enroll"
                description={message || "Card Enrollments have been temporarily disabled"}
            />
                <Space value="50" />
            <div className="container">
                <div className={`${classes.Row} ${classes.Enroll__row}`}>
                    <div className={classes.Enroll__left}>
                        <Card>
                            <h4>We apologize for the inconvenience</h4>
                            <Space value={25}/>

                            <p>
                                Enrollments have been temporarily disabled. Here are the latest updates from CFR: <br /> <br />
                                <div dangerouslySetInnerHTML={{ __html: updates }} />
                            </p>
                        </Card>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EnrollDisabled;
