import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Dashboard from './pages/Dashboard';
import Enroll from './pages/Enroll';
import Search from './pages/Search';
import EnrollmentList from './pages/EnrollmentList';
import LocationList from './pages/LocationList';
import Users from './pages/Users';
import EnrollmentDetail from './pages/EnrollmentDetail';
import FileUpload from './pages/FileUpload';
import Help from './pages/Help';
import CBOs from './pages/CBOs';
import NotExist from './pages/NotExist.tsx';
import NotFound from './pages/NotFound.tsx';
import EnrollDisabled from './pages/EnrollDisabled.tsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import QueryTool from './pages/QueryTool';
import AddLocation from './pages/AddLocation';
import { CardEnrollmentUserLevelManager } from './contexts/userLevelContext.js';
import EnrollmentReportSearch from './pages/EnrollmentReport.js';
import ProcessingSummary from './pages/ProcessingSummary.js';
import AccountExport from './pages/AccountExport.js';
import AdminUsersPage from './pages/UsersAdmin.js';
import LocationDetail from "./pages/LocationDetail";
import { NotificationProvider } from './hooks/NotificationContext.tsx';
import { UserManager } from './contexts/userContext.js';
import SavedQueriesPage from './pages/SavedQueries.js';
import DataImport from './pages/DataImport.tsx';
import CardTypesPage from './pages/CardTypes.js';
import CashFlowReport from './pages/Cashflow.js';
import LoadingScreen from './pages/LoadingScreen.tsx';

function App() {
    const queryClient = new QueryClient();
    if(process.env.REACT_APP_ENV !== 'staging') {
        // dont clear if first login
        if(localStorage.length > 2) {
            if(!localStorage.getItem('last_build_date') || localStorage.getItem('last_build_date') !== process.env.REACT_APP_BUILD_DATE) {
                localStorage.clear();
                localStorage.setItem('last_build_date', process.env.REACT_APP_BUILD_DATE);
                window.location.reload();
            }
        }else {
            localStorage.setItem('last_build_date', process.env.REACT_APP_BUILD_DATE);
        }
    }

    return (
        <QueryClientProvider client={queryClient}>
            <NotificationProvider>
                <UserManager>
                    <CardEnrollmentUserLevelManager>
                        <BrowserRouter>
                            <LoadingScreen>
                                <Routes>
                                    <Route path="/" exact element={<Dashboard />} />
                                    <Route path="/enroll" element={<Enroll />} />
                                    <Route
                                        path="/enroll_disabled"
                                        element={<EnrollDisabled />}
                                    />
                                    <Route path="/search" element={<Search />} />
                                    <Route
                                        path="/enrollments"
                                        element={<EnrollmentList />}
                                    />
                                    <Route path="/locations" element={<LocationList />} />
                                    <Route path="/users" element={<Users />} />
                                    <Route path="/users/admin" element={<AdminUsersPage />} />
                                    <Route
                                        path="/enrollment-detail/:id"
                                        element={<EnrollmentDetail />}
                                    />
                                    <Route
                                        path="/file-upload-utility"
                                        element={<FileUpload />}
                                    />
                                    <Route path="/help" element={<Help />} />
                                    <Route path="/notexist" element={<NotExist />} />
                                    <Route path="/query-tool" element={<QueryTool />} />
                                    <Route path="/query-tool/saved" element={<SavedQueriesPage />} />
                                    <Route path="/add-location" element={<AddLocation />} />
                                    <Route path="/location-detail/:id" element={<LocationDetail />} />
                                    <Route path="/organizations" element={<CBOs />} />
                                    <Route
                                        path="/reports/enrollment"
                                        element={<EnrollmentReportSearch />}
                                    />
                                    <Route
                                        path="/reports/processing"
                                        element={<ProcessingSummary />}
                                    />
                                    <Route
                                        path="/reports/accounts"
                                        element={<AccountExport />}
                                    />
                                    <Route
                                        path="/import"
                                        element={<DataImport />}
                                    />
                                    <Route
                                        path="/reports/card_types"
                                        element={<CardTypesPage />}
                                    />
                                    <Route
                                        path="/reports/cashflow"
                                        element={<CashFlowReport />}
                                    />
                                    <Route path="*"
                                        element={<NotFound />}
                                    />
                                </Routes>
                            </LoadingScreen>
                        </BrowserRouter>

                    </CardEnrollmentUserLevelManager>
                </UserManager>
            </NotificationProvider>
        </QueryClientProvider>
    );
}

export default App;
