import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Select from "../../UI/Select/Select";
import Space from "../../UI/Space/Space";
import Notification from "../../UI/Notification/Notification";
import LocationDetailsModal from "./LocationDetailsModal";

import useNotification from "../../../hooks/useNotification";
import { getLocations, updateLocation } from "../../../api/api";
import { FiInfo } from "react-icons/fi";

import classes from "./AboutLocation.module.scss";

const AboutLocation = ({ cbo }) => {
  const [location, setLocation] = useState([]);

  const [activeLocation, setActiveLocation] = useState(null);
  const [options, setOptions] = useState([]);
  const [activeOption, setActiveOption] = useState("0");
  const [showModal, setShowModal] = useState(false);

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    add: addNotification,
    remove: removeNotification,
  } = useNotification();

  const title = cbo ? cbo.name : "";

  useEffect(() => {
    renderLocation();
  }, []);

  useEffect(() => {
    if (location.length > 1) {
      const optionsArr = location.map((item) => {
        return {
          value: item.id,
          label: item.name,
          isDisabled: false,
        };
      });

      optionsArr.unshift({
        value: "0",
        label: "Select location for details...",
        isDisabled: true,
      });

      setOptions(optionsArr);
      const defaultLocation = localStorage.getItem('defaultLocation')
      if (defaultLocation) {
        console.log(`Restoring default selected location ${defaultLocation}`)
        const locationItem = location.find(
          (item) => item.id === defaultLocation
        );
        if (locationItem) {
          setActiveOption(defaultLocation)
          setActiveLocation(locationItem)        
        }
      } else {
        setActiveLocation(null);
      }

    } else {
      setActiveLocation(location[0]);
    }
  }, [location]);

  const changeSelectHandler = (event) => {
    setActiveOption(event.target.value);
    localStorage.setItem('defaultLocation', event.target.value);

    const currentLocation = location.find(
      (item) => item.id === event.target.value
    );
    setActiveLocation(currentLocation);
  };

  const openModalHandler = () => {
    setShowModal(true);
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };

  const renderLocation = () => {
    setActiveOption("0");

    getLocations()
      .then((response) => {
        setLocation(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const closeNotificationHandler = () => {
    removeNotification();
  };

  const updateLocationHandler = (id, prepareBody) => {
    updateLocation(id, prepareBody)
      .then(() => {
        closeModalHandler();
        renderLocation();

        addNotification({
          status: "success",
          text: "Your location details have been updated.",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 10000);
      })
      .catch((error) => {
        var text = error.toString() || "Error saving location details. Please check your inputs and try again."
        text = text.replace('Error: ','')
        addNotification({
          status: "error",
          text,
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 10000);
      });
  };

  return (
    <>
      <div>
        {!location.length && <p></p>}

        {location.length > 1 && (
          <>
            {!activeLocation && (
              <div className={classes.Info}>
                <span>
                  <FiInfo />
                </span>
                <span>
                  Your account is associated with multiple locations. Please
                  select one of the locations below to view details.
                </span>
              </div>
            )}

            <p className={classes.Title}>{title}</p>

            <Select
              options={options}
              value={activeOption}
              onChange={changeSelectHandler}
            />

            <Space value="20" />
          </>
        )}

        {location.length === 1 && (
          <>
            {activeLocation && (
              <p className={classes.Title}>{activeLocation.name}</p>
            )}
          </>
        )}

        {activeLocation && (
          <>

            <div className={classes.Content}>

              <span className={classes.Text}>
                {activeLocation.address.address}
              </span>

              {activeLocation.address.address_2 && (
              <span className={classes.Text}>
               <br/>{activeLocation.address.address_2}
              </span>
              )}

              <p className={classes.Text}
              >{`${activeLocation.address.city}, ${activeLocation.address.state} ${activeLocation.address.zip}`}</p>
              <p className={classes.Text}>{activeLocation.phone}</p>
            </div>

            <div className={classes.Content}>
              <p className={classes.Subtitle}>Enabled Cards:</p>

              {activeLocation.cards.map((cardItem) => (
                <span key={cardItem.id} className={classes.Text}>
                  {cardItem.name}<br/>
                </span>
              ))}
            </div>

            {activeLocation.contacts && (
              <div className={classes.Content}>
                <p className={classes.Subtitle}>Contacts:</p>
                {activeLocation.contacts.primary && (
                  <p className={classes.Subtitle}>
                    Primary:{" "}
                    <a
                      className="link"
                      href={`mailto:${activeLocation.contacts.primary.email}`}
                    >
                      {activeLocation.contacts.primary.name}
                    </a>
                  </p>
                )}
                {activeLocation.contacts.secondary && (
                  <p className={classes.Subtitle}>
                    Secondary:{" "}
                    <a
                      className="link"
                      href={`mailto:${activeLocation.contacts.secondary.email}`}
                    >
                      {activeLocation.contacts.secondary.name}
                    </a>
                  </p>
                )}
              </div>
            )}

            {activeLocation.user_can_edit && (
              <div className={classes.Content}>
                <p className={classes.Subtitle}>Is everything correct?</p>
                <p className={classes.Text}>
                  If not,{" "}
                  <span className="link" onClick={openModalHandler}>
                    edit your location information
                  </span>{" "}
                  and make any changes needed.
                </p>
              </div>
            )}
          </>
        )}
      </div>

      {showModal &&
        ReactDOM.createPortal(
          <LocationDetailsModal
            onClose={closeModalHandler}
            onSave={updateLocationHandler}
            data={activeLocation}
          />,
          document.querySelector("body")
        )}

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={closeNotificationHandler}
          />,
          document.querySelector("body")
        )}
    </>
  );
};

export default AboutLocation;
