import Button from "../UI/Button/Button";
import Space from "../UI/Space/Space";

import {phoneNumberFormat, zipCodeFormat} from "../../assets/helper";
import CreditCard from "../../assets/images/credit_card.png";
import classes from "./EnrollForm.module.scss";

const ReviewModal = ({
  onClose,
  onCreate,
  formParams,
  location,
  isMailingAdress,
}) => {
  const out = {
    card_number: formParams && (formParams.card_number || ""),
    first: formParams && (formParams.first || ""),
    last: formParams && (formParams.last || ""),
    dob: formParams && (formParams.dob || ""),
    itin: formParams && (formParams.identification_value || ""),
    email: formParams && (formParams.email || ""),
    alternate_email: formParams && (formParams.alternate_email || ""),
    p_address: formParams && (formParams.physical_address.address || ""),
    p_address_2: formParams && (formParams.physical_address.address_2 || ""),
    p_city: formParams && (formParams.physical_address.city || ""),
    p_state: formParams && (formParams.physical_address.state || ""),
    p_zip: formParams && (formParams.physical_address.zip || ""),
    m_address: formParams && (formParams.mailing_address.address || ""),
    m_address_2: formParams && (formParams.mailing_address.address_2 || ""),
    m_city: formParams && (formParams.mailing_address.city || ""),
    m_state: formParams && (formParams.mailing_address.state || ""),
    m_zip: formParams && (formParams.mailing_address.zip || ""),
    phones: formParams && (formParams.phones || []),
  };

  const cancelClickHandler = () => {
    onClose(false);
  };

  const createEnrollHandler = () => {
    onClose(false);
    onCreate();
  };

  return (
    <div className={classes.Modal}>
      <div className={classes.Modal__wrapper}>
        <div className={classes.Modal__body}>
          <div className={classes.Modal__content}>
            <h2 className={classes.Modal__title}>
              Please Review Before Submitting
            </h2>

            <div className={classes.Modal__row}>
              <div className={classes.Modal__left}>
                {location && (
                  <p className={classes.Modal__text___review}>
                    <b>Location:</b> {location}
                  </p>
                )}

                {(out.first || out.last) && (
                  <p className={classes.Modal__text___review}>
                    <b>Name:</b> {out.first} {out.last}
                  </p>
                )}

                {out.dob && (
                  <p className={classes.Modal__text___review}>
                    <b>DOB:</b> {out.dob}
                  </p>
                )}

                {out.itin && (
                  <p className={classes.Modal__text___review}>
                    <b>SSN/ITIN:</b> ••• •• ••••
                  </p>
                )}

                <Space value="25" />

                {out.email && (
                  <>
                    <p className={classes.Modal__text___review}>
                      <b>Email(s):</b>
                    </p>
                    <p className={classes.Modal__text___review}>
                      <b>Primary:</b> {out.email}
                    </p>
                    {out.alternate_email && (
                      <p className={classes.Modal__text___review}>
                        <b>Alternate:</b> {out.alternate_email}
                      </p>
                    )}
                  </>
                )}
              </div>

              <div className={classes.Modal__right}>
                <img
                  src={CreditCard}
                  alt="card"
                  className={classes.Modal__img}
                />

                {out.card_number && (
                  <p className={classes.Modal__text___review}>
                    Payroll Instant Issue {out.card_number}
                  </p>
                )}
              </div>
            </div>

            <Space value="25" />

            <div className={classes.Modal__row}>
              <div className={classes.Modal__left}>
                <p className={classes.Modal__text___review}>
                  {isMailingAdress && <b>Physical Address:</b>}
                  {!isMailingAdress && <b>Physical/Mailing Address:</b>}
                </p>
                {out.p_address && (
                  <p className={classes.Modal__text___review}>{out.p_address}</p>
                )}
                {out.p_address_2 && (
                  <p className={classes.Modal__text___review}>{out.p_address_2}</p>
                )}
                <p className={classes.Modal__text___review}>
                  {out.p_city}, {out.p_state}, {zipCodeFormat(out.p_zip)}
                </p>

                <Space value="25" />

                {out.phones.length
                  ? out.phones.map((item) => (
                      <p
                        className={classes.Modal__text___review}
                        key={item.phone_number}
                      >
                        <b style={{ textTransform: "capitalize" }}>
                          {item.phone_type}:
                        </b>{" "}
                        {phoneNumberFormat(item.phone_number)}
                      </p>
                    ))
                  : null}
              </div>

              {isMailingAdress && (
                <div className={classes.Modal__right}>
                  <p className={classes.Modal__text___review}>
                    <b>Mailing Address:</b>
                  </p>

                  {out.m_address && (
                    <p className={classes.Modal__text___review}>{out.m_address}</p>
                  )}
                  {out.m_address_2 && (
                    <p className={classes.Modal__text___review}>{out.m_address_2}</p>
                  )}
                  <p className={classes.Modal__text___review}>
                    {out.m_city}, {out.m_state}, {zipCodeFormat(out.m_zip)}
                  </p>
                </div>
              )}
            </div>

            <div className={classes.Modal__footer}>
              <Button color="secondary" onClick={cancelClickHandler}>
                Make Changes
              </Button>

              <Button color="primary" onClick={createEnrollHandler}>
                Finish
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.Modal__overlay}></div>
    </div>
  );
};

export default ReviewModal;
