import { getBaseUrl, getHeaders } from "./common.ts";

export interface DataImportTemplate {
  name: string;
  columnDefinitions: string[];
  format: 'CSV' | 'JSON' | 'XLXS'; // i dont know any formats we would use other than csv, but it is here for future proofing
  updated: Date;
  extra_data: ImportTemplateExtraDataObject
}

interface ImportTemplateExtraDataObject {
  [key: string]: ImportTemplateExtraData
}

interface BaseImportTemplateExtraData {
  type: string;
}

interface DropdownImportTemplateExtraData {
  type: 'dropdown';
  options: {label: string, value: string}
}

type ImportTemplateExtraData = BaseImportTemplateExtraData & DropdownImportTemplateExtraData;

export interface UploadOrTestResults {
  rowsAffected?: number;
  success: boolean;
  log: string;
}

export async function importData(data, template, extra_data, is_test): Promise<UploadOrTestResults> {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      data,
      template,
      extra_data,
      is_test
    }),
  };

  const url = new URL(`/api/v1/data/import`, getBaseUrl());
  const resp = await fetch(url, options);

  if (!resp.ok) {
    var error_text =
      `Sorry, we were unable to complete that request. Please send the log in any bug report! (${resp.status}: ${resp.statusText})`;
      var log;
    try {
      const json = await resp.json();
      error_text = json.message;
      
      log = json.log
    } catch { }
    // eslint-disable-next-line
    throw {message: error_text, success: false, log};
  }

  return await resp.json();
};

export async function getTemplates(): Promise<DataImportTemplate[]> {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };

  const url = new URL(`/api/v1/data/import`, getBaseUrl());
  const resp = await fetch(url, options);

  return await resp.json();
};

export async function getTemplate(id: string): Promise<string> {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };

  const url = new URL(`/api/v1/data/import/template/${id}`, getBaseUrl());
  const resp = await fetch(url, options);

  if (!resp.ok) {
    var error_text =
      "Sorry, we were unable to complete that request. Please check your inputs and try again.";
    try {
      error_text = await resp.text();
    } catch { }
    throw error_text;
  }

  return await resp.text();
};

interface CashFlowData {
  '#': number,
  A: string,
  B: any
}

interface CashFlowReportResponse {
  data: CashFlowData
}

export async function cashflow_report(start_date, end_date, group_selection, location, cbo, predefined_selection_id, csv, card_type=null): Promise<CashFlowReportResponse> {
  const options = {
    method: "GET",
    headers: await getHeaders(),
  };

  const params = new URLSearchParams();
  params.set('start_date', start_date);
  params.set('end_date', end_date);
  params.set('group_selection', group_selection);
  params.set('location', location);
  params.set('cbo', cbo);
  params.set('predefined_selection_id', predefined_selection_id);
  params.set('csv', csv);
  if (card_type) {
    params.set('card_type', card_type);
  }
  

  const url = new URL(`/api/v1/reports/cashflow?${params.toString()}`, getBaseUrl());
  const resp = await fetch(url, options);
  const clonedResp = resp.clone();
  try {
    // Clone the response before consuming it
    return await resp.json();
  } catch {
    // Use the cloned response to get the text
    throw new Error(await clonedResp.text());
  }
  
}

export interface PredefinedSelectionObject {
  id: number,
  name: string,
  shared: boolean,
}

export type FullPredefinedSelection = {
  locations: string[];
  cbos: number[];
  id: number;
  name: string | null;
  shared: boolean;
  userName: string | null;
};


export async function createCashflowPredefinedSelection(name: string, cbos: number[], locations: string[], share: boolean) {
  const options = {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify({
      name,
      cbos,
      locations,
      share
    })
  };

  const url = new URL('v1/reports/cashflow/selection', getBaseUrl());

  const res = await fetch(url, options);

  return (await res.json()) as PredefinedSelectionObject;
}

export async function updateCashflowPredefinedSelection(id: number, name: string, cbos: number[], locations: string[], share: boolean) {
  const options = {
    method: 'PATCH',
    headers: await getHeaders(),
    body: JSON.stringify({
      id,
      name,
      cbos,
      locations,
      share
    })
  };

  const url = new URL('v1/reports/cashflow/selection', getBaseUrl());

  const res = await fetch(url, options);

  return (await res.json()) as PredefinedSelectionObject;
}

export async function getCashflowPredefinedSelections() {
  const options = {
    method: 'get',
    headers: await getHeaders()
  };

  const url = new URL('v1/reports/cashflow/selection', getBaseUrl());

  const res = await fetch(url, options);

  return (await res.json()) as PredefinedSelectionObject[];
}

export async function getCashflowPredefinedSelection(selection_id: number) {
  const options = {
    method: 'get',
    headers: await getHeaders()
  };

  const url = new URL(`v1/reports/cashflow/selection/edit/${selection_id}`, getBaseUrl());


  const res = await fetch(url, options);

  return (await res.json()) as FullPredefinedSelection;
}

export async function deleteCashflowPredefinedSelection(selection_id: number) {
  const options = {
    method: 'delete',
    headers: await getHeaders()
  };

  const url = new URL(`v1/reports/cashflow/selection/${selection_id}`, getBaseUrl());


  const res = await fetch(url, options);

  return {success: res.ok};
}