import { csvMaker, downloadCSVFile, setDateFormat } from "../assets/helper.js";
import {getHeaders, getBaseUrl} from './common.ts';

export type FetchParametersParam = {[key: string]: (string | number)}
export interface PaginationParams {
  pageSize: number,
  pageIndex: number,
}
export type SortingParams = any[] & [{id: string, desc: boolean}]

export const addParamsToFetchUrl = (params: FetchParametersParam, fetchURL: URL) => {
  for (var key in params) {
    if (params.hasOwnProperty(key) && params[key] !== "" && params[key] !== undefined && params[key] !== "undefined" && 
      params[key] !== null  && params[key] !== "null") {
      fetchURL.searchParams.set(key, '' + params[key])
    }
  }
}


export const addSortingToFetchUrl = (sorting: SortingParams, fetchURL) => {
  if (sorting[0]) {
    if (sorting[0].id) {
      fetchURL.searchParams.set('sortBy', `${sorting[0].id}`)

      if (sorting[0].desc) {
        fetchURL.searchParams.set('sortDirection', 'desc')
      } else {
        fetchURL.searchParams.set('sortDirection', 'asc')
      }
    }
  }
}

export const addPaginationToFetchUrl = (pagination: PaginationParams, fetchURL) => {
  return addParamsToFetchUrl({perPage: pagination.pageSize, page: pagination.pageIndex + 1}, fetchURL)
}

export async function downloadCSVFromFetch(fetchRequest, params, sorting, fileName: string) {
  try {
    const resp = await fetchRequest(Object.assign(params, {isCSV: true}), sorting, {pageSize: -1, pageIndex: 0});
    if(typeof resp === 'string') {
      downloadCSVFile(resp, fileName);
      return;
    }
    const csvData = csvMaker(resp.items);
    downloadCSVFile(csvData, fileName);
  }catch(e) {
    console.error(e);
    throw e;
  }

  return true;
}

export const fetchEnrollments = async (params, sorting: SortingParams, pagination: PaginationParams) => {
  const fetchURL = new URL(
    '/api/v1/org/enrollments',
    getBaseUrl(),
  );

  addPaginationToFetchUrl(pagination, fetchURL)
  addParamsToFetchUrl(params, fetchURL)
  addSortingToFetchUrl(sorting, fetchURL)

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders()
  })

  let json = await response.json()

  json.items = !json.items.length
    ? []
    : json.items.map((item) => {
        var capitalizedStatus = item.status
        if (capitalizedStatus.length > 0) {
          capitalizedStatus = item.status.charAt(0).toUpperCase() + item.status.slice(1)
        }
        return {
          id: item.id,
          first: item.first,
          last: item.last,
          email: item.email,
          location: [item.location, item.location_id],
          address: [`${item.address_1} ${item.address_2 ? (', ' + item.address_2) : ''}` , `${item.city}, ${item.state} ${item.zip}`],
          status: capitalizedStatus,
          date: setDateFormat(item.submitted, {
            month: "numeric",
            year: "numeric",
            day: "numeric",
          }),
          action_id: item.id,
        };
      });

  return json
}





export const fetchLocations = async (params, sorting, pagination) => {
  const fetchURL = new URL(
    '/api/v1/org/locations',
    getBaseUrl(),
  );

  addPaginationToFetchUrl(pagination, fetchURL)
  addParamsToFetchUrl(params, fetchURL)
  addSortingToFetchUrl(sorting, fetchURL)

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders()
  })

  let json = await response.json()

  json.items = !json.items.length
    ? []
    : json.items.map((item) => {
        const [year, month, day] = item.lastEntry ? item.lastEntry.split(" ")[0].split("-") : ["", "", ""]
        return {
          id: item.id,
          location: [item.name, item.id],
          cbo: item.cbo.name,
          address: 
              item.address_2
              ? item.city && item.state && item.zip
              ? [`${item.address_1}, ${item.address_2}`, `${item.city}, ${item.state} ${item.zip}`]
              : [`${item.address_1}`, `${item.address_2}`]
              : item.city && item.state && item.zip
              ? [`${item.address_1}`, `${item.city}, ${item.state} ${item.zip}`]
              : [`${item.address_1}`, ""],
          inactive: item.inactive,
          enrollmentsYtd: item.enrollmentsYtd,
          lastEntry: item.lastEntry ? `${month}/${day}/${year}` : ""
        };
      });

  return json
}

