import classes from "./Select.module.scss";
import { IconButton } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

const Select = (props) => {
  const cls = [
    classes.select_container,
    props.clsType ? classes[props.clsType] : "",
  ];
  const options = props.options || [];

  if (props.hasError) {
    cls.push(classes.invalid);
  }

  return (
    <div className={cls.join(" ")}>
      {props.label && (
        <label>
          {props.label}{" "}
          {props.hasError ? (
            <span className={classes.select__error}>
              {props.errorMessageProvider ? props.errorMessageProvider() : props.errorMessage || "Enter valid data"}
            </span>
          ) : null}
        </label>
      )}

      <div className={classes.select + (props.value === "" ? " " + classes.placeholder : "") + " " + props.outerDivClassName}>
        <select
          ref={props.ref}
          className={props.className}
          onChange={props.onChange}
          disabled={props.disabled}
          value={props.value}
          id={props.id}
        >
          {!props.disabled && options.map((option) => (
            (option.hasOwnProperty("visible") ? option.visible : true) &&
            <option
              key={option.value}
              value={option.value}
              disabled={option.isDisabled}
            >
              {option.label} {props.value === option.value}
            </option>
          ))}
        </select>

        <div className={classes.select_icon}>
          <svg
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            className="dropdown-heading-dropdown-arrow gray"
          >
            <path d="M6 9L12 15 18 9"></path>
          </svg>
        </div>
      </div>

      {props.cancel && (
        <IconButton className={classes.Icon__cancel} aria-label="cancel" onClick={props.cancel}>
          <CancelIcon />
        </IconButton>
      )}
    </div>
  );
};

export default Select;
