import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSearchParams, useNavigate } from "react-router-dom";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading.tsx";
import LocationFilters from "../components/List/Location/Filters";
import LocationTable from "../components/List/Location/Table";
import Notification from "../components/UI/Notification/Notification";

import useNotification from "../hooks/useNotification";
import { getCBOs } from "../api/api";

let isInitial = true

const LocationSearch = () => {

    const navigate = useNavigate();

    const [rowSelection, setRowSelection] = useState({})

    let [searchParams] = useSearchParams()
    const [params, setParams] = useState({})

    searchParams.forEach((key, value) => {
        params[value] = key
        setParams({...params})
    })

    const [bodyParams, setBodyParams] = useState({ inactive: false })
    const [cbo, setCbo] = useState([])

    const {
        status: notificationStatus,
        text: notificationText,
        btn_text: notificationBtnText,
        isShow: notificationIsShow,
        //add: addNotification,
        remove: removeNotification,
    } = useNotification()

    const closeNotificationHandler = () => {
        removeNotification()
        onSearch(bodyParams)
    }

    useEffect(() => {
        getCBOs()
            .then((response) => {
                setCbo(response)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [])

    const onSearch = (body) => {
        setBodyParams(body)
    }

    useEffect(() => {
        if (isInitial && Object.keys(params).length) {
            isInitial = false
            onSearch(params)
        }
    }, [params])

    const addLocation = () => {
        navigate('/add-location');
    }

    return (
        <>
            <Layout>
                <Heading
                    title="Locations"
                    subtitle="Organizations"
                    description="It's where our partners make a difference."
                    btn_text='Add Location'
                    onClick={addLocation}                    
                />
                <div style={{minHeight: 70 + 'vh'}}>
                    <LocationFilters 
                        searchParams={params}
                        onSearch={onSearch}
                        cbo={cbo}
                        rowSelection={rowSelection}
                    />
                    <LocationTable
                        bodyParams={bodyParams}
                        rowSelection={rowSelection}
                        setRowSelection={setRowSelection}
                    />
                </div>
            </Layout>

            {notificationIsShow &&
                ReactDOM.createPortal(
                    <Notification
                        status={notificationStatus}
                        width="full"
                        text={notificationText}
                        btn_text={notificationBtnText}
                        onClose={closeNotificationHandler}
                    />,
                    document.querySelector("body")
                )}
        </>
    )
}

export default LocationSearch;