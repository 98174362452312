import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";

import Button from "../UI/Button/Button";
import Space from "../UI/Space/Space";

import Copy from "../../assets/svg/Copy";

import { scrollToTop } from "../../assets/helper";
import classes from "./EnrollForm.module.scss";

import { useReactToPrint } from "react-to-print";

const SuccessModal = ({ onClose, onReset, params, fullName }) => {
  const ref = useRef(null)

  const printThis = useReactToPrint({
    content: () => ref.current,
  });

  useEffect(() => {
    console.log(ref)
  }, [ref])

  return (
    <div>
      <div ref={ref}>
        <InnerSuccessModal 
          onClose={onClose}
          onReset={onReset}
          params={params}
          fullName={fullName}
          printThis={printThis}
        />
      </div>
      <div className={classes.Modal__overlay}></div>
    </div>

  );
};

const InnerSuccessModal = ({ onClose, onReset, params, fullName, ref, printThis }) => {
  const paramsArr = params.id.split("-");
  const navigate = useNavigate();

  const redirectDashboardHandler = () => {
    navigate("/");
  };

  const closeClicklHandler = () => {
    onReset();
    onClose(false);
    scrollToTop();
  };

  const printHandler = () => {
    printThis();
  };

  const copyHandler = (event) => {
    const copyText = event.target.attributes["data-value"].value;

    navigator.clipboard.writeText(copyText);
  };

  return (
    <div className={classes.Modal}>
      <div className={classes.Modal__wrapper}>
        <div className={classes.Modal__body}>
          <div className={`${classes.Modal__content} ${classes.small}`}>
            <h2 className={classes.Modal__title}>Congratulations!</h2>

            <p className={classes.Modal__text}>
              <b>Your enrollment was successful! </b>
            </p>

            <Space value="10" />

            <div className={classes.Modal__copy}>
              <p className={classes.Modal__text}>
                <b>Name:</b> {fullName}
              </p>
              <span
                className={classes.Modal__copy_icon}
                data-value={paramsArr[0]}
                onClick={copyHandler}
              >
              </span>
            </div>

            <Space value="10" />

            <div className={classes.Modal__copy}>
              <p className={classes.Modal__text}>
                <b>Confirmation:</b> {paramsArr[0]}
              </p>
              <span
                className={classes.Modal__copy_icon}
                data-value={paramsArr[0]}
                onClick={copyHandler}
              >
                <Copy />
              </span>
            </div>

            <Space value="10" />

            <div className={classes.Modal__copy}>
              <p className={classes.Modal__text}>
                <b>Routing:</b> {paramsArr[1]}
              </p>
              <span
                className={classes.Modal__copy_icon}
                data-value={paramsArr[1]}
                onClick={copyHandler}
              >
                <Copy />
              </span>
            </div>

            <Space value="10" />

            <div className={classes.Modal__copy}>
              <p className={classes.Modal__text}>
                <b>Account:</b> {paramsArr[2]}
              </p>
              <span
                className={classes.Modal__copy_icon}
                data-value={paramsArr[2]}
                onClick={copyHandler}
              >
                <Copy />
              </span>
            </div>

            <Space value="10" />

            <span onClick={printHandler} className={classes.SubModal}>Click here to print this confirmation.
            </span>

            <div className={classes.Modal__footer}>
              <Button color="secondary" onClick={redirectDashboardHandler}>
                Return to Dashboard
              </Button>

              <Button color="primary" onClick={closeClicklHandler}>
                Enroll Another
              </Button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SuccessModal;
