import React, { useReducer, useState } from 'react';
import useInput from '../hooks/useInput.js';
import {
  useFilters,
  FilterItemBuilder,
} from '../components/List/Common/DynamicFilters/FilterStateBuilder.ts';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { fetchUsers } from '../api/users.ts';
import GridPageTemplate from '../components/Reports/GridPageTemplate.js';
import { deleteQuery, fetchQueries, modifySavedQuery } from '../api/queries.ts';
import { IconButton, Tooltip } from '@mui/material';
import { DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../hooks/NotificationContext.tsx';
import { useTrigger } from '../hooks/useTrigger';
import SaveModal from '../components/Editor/SaveModal.tsx';

const selectInitialState = () => {
  return { options: [], value: '' };
};

const selectReducer = (state, action) => {
  if (action.type === 'SET_OPTIONS') {
    return {
      options: action.payload,
      value: state.value,
    };
  }

  if (action.type === 'CHANGE') {
    return {
      options: state.options,
      value: action.payload,
    };
  }

  if (action.type === 'RESET') {
    return {
      options: state.options,
      value: '',
    };
  }

  return selectInitialState();
};

const SavedQueriesPage = () => {
  const filters = useFilters(
    (filterValuesRef) => {
      return {
        description: new FilterItemBuilder()
          .setLabel('Description')
          .setParamName('description')
          .setPermanent()
          .setType('string')
          .useBuildCombo((v) => true, useInput),
        name: new FilterItemBuilder()
          .setLabel('Name')
          .setPermanent()
          .setParamName('name')
          .setType('string')
          .useBuildCombo((v) => true, useInput),
        created_by: new FilterItemBuilder()
          .setLabel('Created By')
          .setParamName('created_by')
          .setPermanent()
          .select()
          .setReference()
          .setGetterFunc(async () => {
            const users = await fetchUsers(
              { role: 'Administrator' },
              [{ id: 'name', desc: false }],
              { pageSize: -1, pageIndex: 0 }
            );

            console.log(
              users,
              users.items.map((u) => ({ id: u.name, name: u.id }))
            );

            return users.items.map((u) => ({ id: u.id, name: u.name }));
          })
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
      };
    },
    ['name', 'description', 'created_by']
  );
  const navigate = useNavigate();
  const { addNotification, removeNotification } = useNotification();
  const { trigger: refreshTable, triggerValue: onRefreshTable } =
    useTrigger.parent();
  const [editingQuery, setEditingQuery] = useState(null);

  function renderRowActions(row) {
    const sq = row.original;

    return (
      <>
        <Tooltip arrow title={<React.Fragment>Run Query</React.Fragment>}>
          <IconButton onClick={() => navigate(`/query-tool?query_id=${sq.id}`)}>
            <PlayCircleIcon />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={<React.Fragment>Edit Query</React.Fragment>}>
          <IconButton
            onClick={() => {
              setEditingQuery(sq);
            }}
          >
            <EditOutlined />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={<React.Fragment>Delete Query</React.Fragment>}>
          <IconButton
            onClick={() => {
              if(!window.confirm('Are you sure')) {
                return;
              }
              deleteQuery(sq.id).then(() => {
                const id = addNotification({
                  text: `Query "${sq.name}" has been deleted`,
                  btnText: 'OK',
                  color: 'ok',
                });
                refreshTable();
                setTimeout(() => {
                  removeNotification(id);
                }, 10000);
              });
            }}
          >
            <DeleteOutline />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  const saveModalSubmit = async (name, description, query) => {
    try {
      await modifySavedQuery(editingQuery.id, query, name, description).then(
        () => {
          refreshTable();
          setEditingQuery(null);
        }
      );
    } catch {
      return false;
    }
    return true;
  };

  return (
    <>
      {editingQuery && (
        <SaveModal
          onSubmit={saveModalSubmit}
          closeModal={() => setEditingQuery(null)}
          preset_info={editingQuery}
        />
      )}
      <GridPageTemplate
        name="Saved Queries"
        button_text="Search"
        description="Quickly get the data you need each time."
        subtitle="DATA"
        filters={filters}
        fetchRequest={fetchQueries}
        rowActions={renderRowActions}
        fileName={'savedQueries'}
        refreshTrigger={onRefreshTable}
        columns={[
          {
            accessorKey: 'name',
            header: 'Name',
          },
          {
            accessorKey: 'description',
            header: 'Description',
          },
          {
            accessorKey: 'username',
            header: 'Created By',
          },
        ]}
      />
    </>
  );
};

export default SavedQueriesPage;
