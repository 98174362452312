import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { ReactNode, useEffect } from "react";
import { useState } from "react";

export type idAddingFunction = (ids: any[]) => void;

interface LoadingAutoCompleteProps<T extends object> {
  optionsGetter: () => Promise<T[]>,
  optionLabelGetter: (option: T) => string,
  optionsEqual: (a: T, b: T) => boolean,
  setSelected: (selected: T[] | ((previous:T[]) => T[])) => void,
  selected: T[],
  placeHolder: string,
  label: string,
  cls?: string,
  addSelectedIdFunctionRef?: React.MutableRefObject<idAddingFunction | undefined>
}


const LoadingAutoComplete: <T extends object, >(props: LoadingAutoCompleteProps<T>) => ReactNode = <T extends object,>({optionsGetter, optionLabelGetter, optionsEqual, setSelected, selected, placeHolder, label, cls, addSelectedIdFunctionRef}: LoadingAutoCompleteProps<T>) => {
  const [loading, setIsLoading] = useState<boolean>();
  const [options, setOptions] = useState<T[]>([]);
  const [isOpen, setOpen] = useState(false);
  const [enqueuedIds, setEnqueuedIds] = useState<any[]>([]);

  
  function addOrEnqueueSelectedIds(ids: any[]) {
    if(loading) {
      setEnqueuedIds(enqueuedIds => {
        return [...enqueuedIds, ...ids];
      })
      return;
    }else {
      addSelectedIds(ids);
    }
  }

  function addSelectedIds(ids: any[]) {
    if(loading) {
      throw new Error("Add Selected Ids was called at a wrong time.")
    }

    

    let remainingIds = ids;
    const toBeSelected: T[] = [];

    
    for(const option of options) {
      if(remainingIds.length === 0) {
        break;
      }
      if(!('id' in option)) {
        continue;
      }
      if(ids.includes(option.id)) {
        if(selected.find(s => 'id' in s && s.id === option.id)) {
          continue
        }
        toBeSelected.push(option);
        remainingIds = remainingIds.splice(remainingIds.indexOf(option.id), 1);
      }
    }
    setSelected(ops => [...ops, ...toBeSelected])
  }

  if(addSelectedIdFunctionRef) {
    addSelectedIdFunctionRef.current = addOrEnqueueSelectedIds;
  }
  

  useEffect(() => {
    setIsLoading(true);
    optionsGetter().then(o => {
      setOptions(o);
      setIsLoading(false);
    });
  }, [optionsGetter])

  useEffect(() => {
    if(!loading && enqueuedIds.length > 0) {
      addSelectedIds(enqueuedIds);
      setEnqueuedIds([]);
    }
    //eslint-disable-next-line
  }, [loading])

  useEffect(() => {
    console.log('selected', selected)
  }, [selected])

  return <>
    <Autocomplete
      multiple
      className={cls}
      id="tags-standard"
      options={options}
      getOptionLabel={optionLabelGetter}
      isOptionEqualToValue={optionsEqual}
      open={isOpen}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          fullWidth
          multiline
          placeholder={placeHolder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                  />
                ) : null}
              </React.Fragment>
            ),
          }}
        />
      )}
      value={selected}
      onChange={(event, value) => setSelected(value)}
    />

  </>
}

export default LoadingAutoComplete;