import { useState } from 'react';
import useInput from '../../hooks/useInput';
import Button from '../UI/Button/Button';
import Checkbox from '../UI/Checkbox/Checkbox';
import Input from '../UI/Input/Input';
import classes from './CBOs.module.scss';
import { ErrorMessageProviderBuilder } from '../UI/Input/ErrorMessageProviderBuilder.ts';
import filterClasses from '../List/List.module.scss'

function CBOSearch({ onSearch }) {
    /**
     * CBO Name
     */
    const {
        value: CBOName,
        inputChangeHandler: CBONameChangeHandler,
        reset: CBONameReset,
    } = useInput(() => true);

    /**
     * Minimum Quantity of Locations
     */
    const numericOptional = new ErrorMessageProviderBuilder(
        'this'
    ).isNumeric();
    const {
        value: minQty,
        inputChangeHandler: minQtyChangeHandler,
        inputBlurHandler: minQtyBlurHandler,
        reset: minQtyReset,
        hasError: minQtyHasError,
    } = useInput((value) => numericOptional.passes(value) || value.trim() === '');

    /**
     * Minimum of YTD Enrollments
     */
    const {
        value: minYTDEnrollments,
        inputChangeHandler: minYTDEnrollmentsChangeHandler,
        reset: minYTDEnrollmentsReset,
        inputBlurHandler: minYTDEnrollmentsBlurHandler,
        hasError: minYTDEnrollmentsHasError,
    } = useInput((value) => numericOptional.passes(value) || value.trim() === '');

    /**
     * Last Enrollment Start Date
     */
    const {
        value: enrollmentStartDate,
        inputChangeHandler: enrollmentStartDateChangeHandler,
        reset: enrollmentStartDateReset,
    } = useInput(() => true);

    /**
     * Last Enrollment End Date
     */
    const {
        value: enrollmentEndDate,
        inputChangeHandler: enrollmentEndDateChangeHandler,
        reset: enrollmentEndDateReset,
    } = useInput(() => true);

    /**
     * Include Deactivated
     */
    const [includeDeactivated, setIncludeDeactivated] = useState(false);

    function resetFilters() {
        CBONameReset();
        minQtyReset();
        minYTDEnrollmentsReset();
        enrollmentStartDateReset();
        enrollmentEndDateReset();
        setIncludeDeactivated(false);
    }

    // useEffect(() => {
    //     dataRef.current = {
    //         CBOName,
    //         minQty,
    //         minYTDEnrollments,
    //         enrollmentStartDate,
    //         enrollmentEndDate,
    //         includeDeactivated,
    //     };
    // }, [
    //     CBOName,
    //     minQty,
    //     minYTDEnrollments,
    //     enrollmentStartDate,
    //     enrollmentEndDate,
    //     includeDeactivated,
    //     dataRef
    // ]);

    function onSearchClicked() {
        onSearch({
            name: CBOName,
            minQty,
            minYTDEnrollments,
            enrollmentStartDate,
            enrollmentEndDate,
            includeDeactivated,
        });
    }

    return (
        <div className={classes.Search__container}>
            <div className={classes.Search__container__inner}>
                <div className={filterClasses.Form__text}>
                    Enter <b>any</b> search parameter below and click search.   
                    <span onClick={resetFilters} className={filterClasses.Form__clear}>
                        RESET FILTERS
                    </span>
                </div>
                <div className="row">
                    <div className="col-sm-2">
                        <Input
                            label="CBO Name:"
                            placeholder="CBO Location Name"
                            value={CBOName}
                            onChange={CBONameChangeHandler}
                        />
                    </div>
                    <div className="col-sm-2">
                        <Input
                            label="Min Qty Locations:"
                            placeholder="# Locations"
                            hasError={minQtyHasError}
                            errorMessageProvider={numericOptional
                                .formatWith((s) => (
                                    <span
                                        className={classes.Search__inputError}
                                    >
                                    {s}
                                    </span>
                                ))
                                .build()}
                            onBlur={minQtyBlurHandler}
                            value={minQty}
                            onChange={minQtyChangeHandler}
                        />
                    </div>
                    <div className="col-sm-2">
                        <Input
                            label="Min YTD Enrollments:"
                            placeholder="# Enrollments"
                            hasError={minYTDEnrollmentsHasError}
                            errorMessageProvider={numericOptional
                                .formatWith((s) => (
                                    <span
                                        className={classes.Search__inputError}
                                    >
                                    {s}
                                    </span>
                                ))
                                .build()}
                            onBlur={minYTDEnrollmentsBlurHandler}
                            value={minYTDEnrollments}
                            onChange={minYTDEnrollmentsChangeHandler}
                        />
                    </div>
                    <div className="col-sm-2">
                        <Input
                            type="date"
                            label="Last Enrollment Start Date:"
                            placeholder={{
                                year: 'YYYY',
                                month: 'MM',
                                day: 'DD',
                            }}
                            value={enrollmentStartDate}
                            onChange={enrollmentStartDateChangeHandler}
                        />
                    </div>
                    <div className="col-sm-2">
                        <Input
                            type="date"
                            label="Last Enrollment End Date:"
                            placeholder={{
                                year: 'YYYY',
                                month: 'MM',
                                day: 'DD',
                            }}
                            value={enrollmentEndDate}
                            onChange={enrollmentEndDateChangeHandler}
                        />
                    </div>
                    <div
                        className={`col-sm-2 ${classes.Search__buttonContainer}`}
                    >
                        <Button className={classes.Search__button} clsType="small" onClick={onSearchClicked}>
                            Search
                        </Button>
                    </div>
                </div>
                <div>
                    <Checkbox
                        label={'Include Deactivated'}
                        value={includeDeactivated}
                        onChange={() =>
                            setIncludeDeactivated(!includeDeactivated)
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default CBOSearch;
