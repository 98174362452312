import { PaginateApiResponse, getBaseUrl, getHeaders } from './common.ts';
import {
  addPaginationToFetchUrl,
  addParamsToFetchUrl,
  addSortingToFetchUrl,
} from './fetch.ts';

export async function fetchCardTypesReport(
    params,
    sorting,
    pagination
  ): Promise<PaginateApiResponse<any>> {
    const fetchURL = new URL('/api/v1/cards/types/fetch', getBaseUrl());
  
    addPaginationToFetchUrl(pagination, fetchURL);
    addParamsToFetchUrl(params, fetchURL);
    addSortingToFetchUrl(sorting, fetchURL);
  
    const response = await fetch(fetchURL.href, {
      headers: await getHeaders(),
    });
  
    let json = await response.json();
    console.log(json);
    return json;
}