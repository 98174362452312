import { useEffect, useMemo, useReducer, useState } from 'react';
import useInput from '../hooks/useInput.js';
import {
  useFilters,
  FilterItemBuilder,
} from '../components/List/Common/DynamicFilters/FilterStateBuilder.ts';
import { getCBOs } from '../api/api.js';
import ReportPageTemplate from '../components/Reports/ReportPageTemplate.js';
import { getAllLocations } from '../api/locations.ts';
import PredefinedSelection from '../components/UI/PredefinedSelection/PredefinedSelection.tsx';
import { cashflow_report } from '../api/data.ts';
import moment from 'moment';
import { useNotification } from '../hooks/NotificationContext.tsx';
import _ from 'lodash';

const selectInitialState = () => {
  return { options: [], value: '' };
};

const selectReducer = (state, action) => {
  if (action.type === 'SET_OPTIONS') {
    return {
      options: action.payload,
      value: state.value,
    };
  }

  if (action.type === 'CHANGE') {
    return {
      options: state.options,
      value: action.payload,
    };
  }

  if (action.type === 'RESET') {
    return {
      options: state.options,
      value: '',
    };
  }

  return selectInitialState();
};

const CashFlowReport = () => {

  const filters = useFilters(
    (filterValuesRef) => {
      return {
        cbo: new FilterItemBuilder()
          .setLabel('CBO')
          .setParamName('cbo')
          .setPermanent()
          .select()
          .setReference()
          .setGetterFunc(getCBOs)
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        location: new FilterItemBuilder()
          .setLabel('Locations')
          .setParamName('location')
          .setPermanent()
          .select()
          .setReference()
          .setGetterFunc(getAllLocations)
          .setValueAndLabelParams('id', 'name')
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        group_by: new FilterItemBuilder()
          .setLabel('Group By *')
          .setParamName('group_by')
          .setPermanent()
          .select()
          .setOptions([
            { label: 'Select group by', value: 'unset' },
            { label: 'Month', value: 'Month' },
            { label: 'Quarter', value: 'Quarter' }
          ])
          .useBuildCombo(selectReducer, selectInitialState(), useReducer),
        begin_date: new FilterItemBuilder()
          .setLabel('Begin Date *')
          .setPermanent()
          .setParamName('begin_date')
          .setType('date')
          .setPlaceholder('MM/DD/YYYY')
          .setErrorMessage('Begin and end are swapped.')
          .useBuildCombo(
            () => true,
            useInput
          ),
        end_date: new FilterItemBuilder()
          .setLabel('End Date *')
          .setPermanent()
          .setParamName('end_date')
          .setType('date')
          .setPlaceholder('MM/DD/YYYY')
          .setErrorMessage('Begin and end are swapped.')
          .useBuildCombo(
            () => true,
            useInput
          ),
        accidental_selection: new FilterItemBuilder()
          .setLabel("Prevent accidential selection of partial months")
          .setParamName("accidental_selection")
          .setPermanent()
          .checkbox()
          .startEnabled()
          .useBuildCombo(useState),
        predefined_selection: new FilterItemBuilder()
          .setLabel('Select or create a predefined selection...')
          .setParamName('predefined_selection')
          .setPermanent()
          .custom()
          .setDefaultState({ selected: 0 })
          .setComponent(PredefinedSelection)
          .useBuildCombo(useState),
        // card_type: new FilterItemBuilder()
        //   .setLabel('Card Type')
        //   .setParamName('card_type')
        //   .select()
        //   .setOptions([{ label: 'All', value: 'all' }, { label: 'Vita', value: 'vita' }, { label: 'Payroll', value: 'payroll' }])
        //   .useBuildCombo(selectReducer, selectInitialState(), useReducer),
      };
    },
    ['cbo', 'location', 'group_by', 'begin_date', 'end_date', 'accidental_selection', 'predefined_selection']
  );

  
  /**
   * @type {[{'#': string, Label: string, result: string}[], any]}
   */
  const [data, setData] = useState([])

  function onSubmit() {
    if (!filters.filterValues.begin_date.value) {
      alert('start date is a required field')
    }
    if (!filters.filterValues.end_date.value) {
      alert('Select an end date')
    }

    if (filters.filterValues.group_by.state.value === 'unset' || !filters.filterValues.group_by.state.value) {
      alert('Select a "Group By"')
    }

    if (filters.filterValues.accidental_selection[0]) {
      if (moment(filters.filterValues.begin_date.value).date() !== 1) {
        alert('The start date must begin on the first of a month')
        return;
      }
      const end_date = moment(filters.filterValues.end_date.value);
      if (filters.filterValues.end_date.value && end_date.date() !== end_date.daysInMonth()) {
        alert('The end date must be the last date in a month')
        return;
      }
    }
    console.log(filters.filterValues.group_by.state.value)
    report().then(d => setData(d.data))
  }

  const {addNotification} = useNotification();

  function report(csv = false) {
    return cashflow_report(
      (filters.filterValues.begin_date.value && moment(filters.filterValues.begin_date.value).toDate().valueOf()) || null,
      (filters.filterValues.end_date.value && moment(filters.filterValues.end_date.value).toDate().valueOf()) || null,
      filters.filterValues.group_by.state.value,
      filters.filterValues.location.state.value,
      filters.filterValues.cbo.state.value,
      parseInt(filters.filterValues.predefined_selection[0].selected) || -1,
      csv,
      // filters.filterValues.card_type.state.value
    ).then((d) => {
      return d;
    }).catch((e) => {
      addNotification({
        color: 'error',
        text: e.toString(),
        btnText: 'OK',
      })
    });
  }

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 48, //customize the default page size
  });

  function sortColumns(a, b) {
    // Extract headers
    const headerA = a.header;
    const headerB = b.header;

    // Function to check if the header is a number
    const isNumber = (header) => !isNaN(header);

    // Rule 1: "#" comes first
    if (headerA === "#" && headerB !== "#") return -1;
    if (headerA !== "#" && headerB === "#") return 1;

    // Rule 2: Numbers come last but in numerical order
    if (isNumber(headerA) && isNumber(headerB)) return Number(headerA) - Number(headerB);
    if (isNumber(headerA) && !isNumber(headerB)) return 1;
    if (!isNumber(headerA) && isNumber(headerB)) return -1;

    // Rule 3: Everything else remains in between
    return headerA.localeCompare(headerB);
  }

  const customTable = useMemo(() => ({
    data,
    pagination,
    sorting: sortColumns,
    setPagination: (d) => {
      setPagination((old) => {
        const change = d(old);
        if(_.isEqual(old, change)) return old;
        return d(old);
      });
    },
  }), [data, pagination])

  useEffect(() => {
    console.log(data)
  }, [data])

  useEffect(() => {
    console.log(pagination)
  }, [pagination])


  return (
    <>
      <ReportPageTemplate
        name="Cashflow"
        description="Generate cashflow reports for clients."
        filters={filters}
        fetchRequest={async () => ({ items: await report(true) })}
        fileName={"cashflow_report"}
        customTable={() => (<></>)}
        onSubmit={onSubmit}
        useCustomTable={customTable}
      />

    </>
  );
};

export default CashFlowReport;