import { CancelOutlined, DeleteOutline, EditOutlined, LockResetOutlined, RemoveRedEye } from "@mui/icons-material"
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import React from "react"
import { RiUserShared2Line } from "react-icons/ri"
import { TbBrandAuth0, TbMailForward } from "react-icons/tb"
import MenuIcon from "@mui/icons-material/Menu"
import { requestViewAsSession } from "../../api/users.ts"

interface UserContextMenuProps {
    openModal: (type: string, data: any) => void;
    user: any;
    executeAction: (data: any, action: string) => void;
}

const UserContextMenu: React.FC<UserContextMenuProps> = ({ openModal, user, executeAction }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const status = user.info_status.toLowerCase();

    const menuItems: React.JSX.Element[] = [];

    if (['legacy', 'deactivated', 'cancelled'].includes(status)) {
        menuItems.push((<MenuItem onClick={() => openModal('view', user.info_data)}>
            <ListItemIcon>
                <EditOutlined />
            </ListItemIcon>
            <ListItemText>View Details</ListItemText>
        </MenuItem>))
    }
    if (['active', 'migrated'].includes(status)) {
        menuItems.push(<MenuItem onClick={() => openModal('edit', user.info_data)}>
            <ListItemIcon>
                <EditOutlined />
            </ListItemIcon>
            <ListItemText>Edit User</ListItemText>
        </MenuItem>,
            <MenuItem onClick={() => executeAction(user.info_data, 'resetPassword')}>
                <ListItemIcon>
                    <LockResetOutlined />
                </ListItemIcon>
                <ListItemText>Reset Password</ListItemText>
            </MenuItem>,
            <MenuItem onClick={() => executeAction(user.info_data, 'reset_MFA')}>
                <ListItemIcon>
                    <TbBrandAuth0 fontSize="large" />
                </ListItemIcon>
                <ListItemText>Reset MFA Phone Number</ListItemText>
            </MenuItem>,
            <MenuItem onClick={() => requestViewAsSession(user.info_data.id).then(() => window.location.assign("/"))}>
                <ListItemIcon>
                    <RemoveRedEye />
                </ListItemIcon>
                <ListItemText>View Site as User</ListItemText>
            </MenuItem>)
    }
    if (status === 'invited') {
        menuItems.push(<MenuItem onClick={() => executeAction(user.info_data, 'resendInvitation')}>
            <ListItemIcon>
                <TbMailForward />
            </ListItemIcon>
            <ListItemText>Resend Invite</ListItemText>
        </MenuItem>,
            <MenuItem onClick={() => executeAction(user.info_data, 'cancelInvitation')}>
                <ListItemIcon>
                    <CancelOutlined />
                </ListItemIcon>
                <ListItemText>Cancel Invite</ListItemText>
            </MenuItem>)
    }
    if (status === 'legacy') {
        menuItems.push(<MenuItem onClick={() => executeAction(user.info_data, 'migrateUser')}>
            <ListItemIcon>
                <RiUserShared2Line />
            </ListItemIcon>
            <ListItemText>Migrate</ListItemText>
        </MenuItem>)
    }
    if(['active', 'legacy', 'migrated'].includes(status)) {
        menuItems.push(<MenuItem onClick={() => executeAction(user.info_data, 'deactivate')}>
            <ListItemIcon>
                <DeleteOutline />
            </ListItemIcon>
            <ListItemText>Deactivate User</ListItemText>
        </MenuItem>)
    }


    return (<>
        <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
            <MenuIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={anchorEl !== null} onClose={() => setAnchorEl(null)}>
            {menuItems}
        </Menu>
    </>)
}

export default UserContextMenu;