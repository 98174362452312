import React from "react";

const Failure = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_703_216)">
        <path
          d="M4.54281 0H21.8042C22.3547 0.000479925 22.8996 0.109491 23.4079 0.320795C23.9162 0.5321 24.3779 0.841551 24.7664 1.23145L24.8341 1.3055C25.5794 2.08337 25.9962 3.11854 25.9979 4.1958V21.8042C25.9934 22.9156 25.5499 23.9803 24.764 24.7662C23.9781 25.5521 22.9135 25.9955 21.8021 26H4.1958C3.08438 25.9955 2.01975 25.5521 1.23385 24.7662C0.447944 23.9803 0.00445689 22.9156 0 21.8042L0 4.54281C0.00501259 3.33952 0.485238 2.18695 1.3361 1.3361C2.18695 0.485238 3.33952 0.00501259 4.54281 0V0ZM11.7093 7.15169L13 10.279H13.1883L14.479 7.15169H18.5394L15.939 12.8032L18.5394 18.8483H14.388L12.981 15.4798H12.8138L11.4258 18.8483H7.46273L10.0018 12.9238L7.46273 7.16016L11.7093 7.15169ZM21.8042 2.13916H4.54281C3.90549 2.13972 3.29444 2.39314 2.84379 2.84379C2.39314 3.29444 2.13972 3.90549 2.13916 4.54281V21.8042C2.14194 22.3488 2.35951 22.8703 2.74461 23.2554C3.1297 23.6405 3.6512 23.8581 4.1958 23.8608H21.8042C22.3495 23.8603 22.8723 23.6434 23.2578 23.2578C23.6434 22.8723 23.8603 22.3495 23.8608 21.8042V4.1958C23.8607 3.67417 23.6626 3.17203 23.3065 2.79085L23.2557 2.7443C23.0655 2.55288 22.8394 2.40088 22.5903 2.29704C22.3412 2.19319 22.0741 2.13954 21.8042 2.13916Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_703_216">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Failure;
