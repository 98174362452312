import NotesItem from "./NotesItem";
import NoteSubmit from "../../assets/images/icon-note-submit.svg";
import Scrollbar from "../UI/Scrollbar/Scrollbar.tsx";
import { createNote, getNotes } from "../../api/api";
import classes from "./EnrollmentDetail.module.scss";
import useInput from "../../hooks/useInput";
import { useState } from "react";

const Notes = ({ enrollment }) => {
  const {
    value: noteText,
    isValid: noteTextIsValid, // prevents submission of empty note
    inputChangeHandler: noteTextChangeHandler,
    reset: noteTextReset,
  } = useInput((value) => value.trim() !== "");

  const submitOnEnter = (event) => {
    if (event.keyCode === 13) submitHandler(event)
  }

  const [notes, setNotes] = useState(null);
  const [notesLoaded, setNotesLoaded] = useState(false);

  if (!notes && enrollment && enrollment.id) getNotes({id: enrollment.id, type: "enrollment"}).then((response) => {
    // console.log(response)
    setNotes(response)
    setNotesLoaded(true)
  })

  const submitHandler = (event) => {
    event.preventDefault();

    const newNote = {
      message: noteText,
      entity_id: enrollment.id,
      entity: "enrollment"
      // user_id: activeUser.id
      // timestamp: Date.now()
    }

    // console.log(newNote)

    if (noteTextIsValid) createNote(newNote).then((response) => {
      // console.log(response)
      if (response.req_successful) {
        noteTextReset();
        setNotes([...notes, response])
      }
    })
  }

  const notesForm = (
    <form className={classes.Notes__form} onSubmit={submitHandler}>
      <textarea wrap="soft" rows="2"
        placeholder="Enter any comments here and press enter or click the button to the right..."
        value={noteText}
        onChange={noteTextChangeHandler}
        onKeyDown={submitOnEnter}
      ></textarea>
      <img onClick={submitHandler} src={NoteSubmit} alt="Submit Note" className={classes.Notes__submit}/>
    </form>
  );

  if (!notes || !notes.length) {
    return (
      <>
        <p className={classes.Notes__message}>{notesLoaded ? "No notes have been added yet" : "Loading notes..."}</p>
        {notesForm}
      </>
    );
  }

  return (
    <div className={classes.Notes}>
      <Scrollbar>
        {notes && notes.map((item, index) => (
          <NotesItem key={index} item={item} />
        ))}
      </Scrollbar>
      {notesForm}
    </div>
  );
};

export default Notes;