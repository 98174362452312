import { IconButton } from "@mui/material";
import classes from "./Checkbox.module.scss";
import CancelIcon from '@mui/icons-material/Cancel';

/**
 * 
 * @param {{label: string, value: boolean, onChange: React.ChangeEventHandler<HTMLInputElement>, cancel?: boolean}} param0 
 * @returns 
 */
const Checkbox = ({label, value, onChange, cancel}) => {
  return (
    <div className={classes.Checkbox}>
        <label>
            <input type="checkbox" checked={value} onChange={onChange} />
            { label ? <span>{label}</span> : null}
        </label>
        {cancel && (
          <IconButton className={classes.Icon__cancel} aria-label="cancel" onClick={cancel}>
            <CancelIcon />
          </IconButton>
        )}
    </div>
  )
}

export default Checkbox