import { useReducer, useEffect, useState } from "react";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";

import useInput from "../../../hooks/useInput";
import { getEnrollmentsExport, getEnrollmentStatuses, cancelEnrollments, getCardTypes } from "../../../api/api";
import { downloadCSVFile, csvMaker } from "../../../assets/helper";

import Download from "../../../assets/svg/Download";
import classes from "../List.module.scss";
import DynamicFilterDropdown from "../Common/DynamicFilters/FilterDropdown";
import DynamicFilterInputs from "../Common/DynamicFilters/FilterInputs";
import Dropdown from 'rsuite/Dropdown';
import "rsuite/dist/rsuite.min.css";

const fillStates = () => {
  const statesJson = require("../../../assets/json/us-states.json");

  const options = [];

  for (let key in statesJson) {
    options.push({
      value: key,
      label: statesJson[key],
      isDisabled: false,
    });
  }

  options.unshift({
    value: "",
    label: "Select a state...",
    isDisabled: false,
  });

  return options;
};

const statesOptions = fillStates()

const getStatusOptions = async () => {
  const statuses = await getEnrollmentStatuses()
  const statusOptions = []
  for (const status of statuses) {
    statusOptions.push({
      status: status
    })
  }
  return statusOptions;
}

const getCardTypeOptions = async () => {
  const {
    instant_cards: instant,
    standard_cards: standard
  } = await getCardTypes()

  const cardTypeOptions = []
  for (const item of instant) {
    cardTypeOptions.push({
      type: item.name
    })
  }
  for (const item of standard) {
    cardTypeOptions.push({
      type: item.name
    })
  }

  return cardTypeOptions
}

const selectInitialState = () => {
  return { options: [], value: ""}
}

const selectReducer = (state, action) => {
  if (action.type === "SET_OPTIONS") {
    return {
      options: action.payload,
      value: state.value,
    }
  }

  if (action.type === "CHANGE") {
    return {
      options: state.options,
      value: action.payload,
    }
  }

  if (action.type === "RESET") {
    return {
      options: state.options,
      value: "",
    }
  }

  return  selectInitialState();
}

const SearchForm = ({ searchParams, onSearch, location, cbo, rowSelection }) => {
  const firstParam = searchParams ? searchParams.first : null;
  const lastParam = searchParams ? searchParams.last : null;
  const resets = [];

  const [failuresChecked, setFailuresChecked] = useState(false)

  const handleFailures = () => {
    setFailuresChecked(!failuresChecked)
  }

  const handleBulkActionCancel = async () => {
    const cancelResult = await cancelEnrollments({enrollment_ids: Object.keys(rowSelection)})
    console.log(cancelResult)
  }

  /**
   * Dynamic Filters
   */
  const filterValues = {}

  const [filterState, setFilter] = useState(
    {
      "activeFields": [],
      "submittedBefore": {
        label: "Submitted Before",
        type: 'date',
        paramName: "end_date",
        placeholder: 'MM/DD/YYYY'
      },
      "submittedAfter": {
        label: "Submitted After",
        type: 'date',
        paramName: "start_date",
        placeholder: 'MM/DD/YYYY'
      },
      "location_id": {
        label: "Location ID",
        type: 'string',
        paramName: "location_id",
        placeholder: "CSFW01"
      },
      "status": {
        label: "Status",
        type: "select",
        reference: true,
        paramName: "status",
        getterFunc: getStatusOptions,
        valueParam: "status",
        labelParam: "status",
      },
      "email": {
        label: "Email",
        type: "string",
        paramName: "email",
        placeholder: "johndoe@gmail.com"
      },
      "alternateEmail": {
        label: "Alternate Email",
        type: "string",
        paramName: "alternate_email",
        placeholder: "johndoe@gmail.com"
      },
      "address1": {
        label: "Mailing Address 1",
        type: 'string',
        paramName: 'address_1',
        placeholder: "123 Main St",
      },
      "address2": {
        label: "Mailing Address 2",
        type: 'string',
        paramName: 'address_2',
        placeholder: "Apt 321",
      },
      "city": {
        label: "Mailing City",
        type: "string",
        paramName: "city",
        placeholder: "Plano"
      },
      "state": {
        label: "Mailing State",
        type: "select",
        reference: false,
        paramName: "state",
        options: statesOptions
      },
      "zip": {
        label: "Mailing Zip Code",
        type: "number",
        paramName: "zip",
        placeholder: "75075"
      },
      "physicalAddress1": {
        label: "Physical Address 1",
        type: 'string',
        paramName: 'physical_address_line1',
        placeholder: "123 Main St",
      },
      "physicalAddress2": {
        label: "Physical Address 2",
        type: 'string',
        paramName: 'physical_address_line2',
        placeholder: "Apt 321",
      },
      "physicalCity": {
        label: "Physical City",
        type: "string",
        paramName: "physical_city",
        placeholder: "Plano"
      },
      "physicalState": {
        label: "Physical State",
        type: "select",
        reference: false,
        paramName: "physical_state",
        options: statesOptions
      },
      "physicalZip": {
        label: "Physical Zip Code",
        type: "number",
        paramName: "physical_zip",
        placeholder: "75075"
      },
      "dob": {
        label: 'Date of Birth',
        type: 'date',
        paramName: 'dob',
        placeholder: 'MM/DD/YYYY'
      },
      "workPhone": {
        label: "Work Phone",
        type: "phone",
        paramName: "work_phone",
        placeholder: "(555) 555-5555"
      },
      "homePhone": {
        label: "Home Phone",
        type: "phone",
        paramName: "home_phone",
        placeholder: "(555) 555-5555"
      },
      "cellPhone": {
        label: "Cell Phone",
        type: "phone",
        paramName: "cell_phone",
        placeholder: "(555) 555-5555"
      },
      "routingNumber": {
        label: "Routing Number",
        type: "number",
        paramName: "routing_number",
        placeholder: "123456789"
      },
      "accountNumber": {
        label: "Account Number",
        type: "number",
        paramName: "account_number",
        placeholder: "1111111111111111"
      },
      "user": {
        label: "User",
        type: "string",
        paramName: "user",
        placeholder: "John Doe"
      },
      "cardType": {
        label: "Card Type",
        type: 'select',
        reference: true,
        paramName: "card_type",
        getterFunc: getCardTypeOptions,
        valueParam: "type",
        labelParam: "type",
      },
      "identificationType": {
        label: "Identification Type",
        type: "select",
        reference: false,
        paramName: "identification_type",
        options: [
          {
            value: "",
            label: "Select a type...",
            isDisabled: false
          },
          {
            value: "SSN",
            label: "SSN",
            isDisabled: false
          },
          {
            value: "ITIN",
            label: "ITIN",
            isDisabled: false
          },
          {
            value: "UNKNOWN",
            label: "Unknown",
            isDisabled: false
          }
        ]
      },
    }
  )

  //update filterValues for non-select fields
  filterValues["dob"] = useInput(() => true)
  filterValues["location_id"] = useInput(() => true)
  filterValues["zip"] = useInput((value) => value.length === 5 || value.length === 0 ? true : false)
  filterValues["email"] = useInput((value) => /(.+)@(.+){1,}\.(.+){1,}/.test(value) ? true : value.length === 0 ? true : false)
  filterValues["alternateEmail"] = useInput((value) => /(.+)@(.+){1,}\.(.+){1,}/.test(value) ? true : value.length === 0 ? true : false)
  filterValues["city"] = useInput(() => true)
  filterValues["submittedBefore"] = useInput(() => true)
  filterValues["submittedAfter"] = useInput(() => true)
  filterValues["address1"] = useInput(() => true)
  filterValues["address2"] = useInput(() => true)
  filterValues["physicalAddress1"] = useInput(() => true)
  filterValues["physicalAddress2"] = useInput(() => true)
  filterValues["physicalCity"] = useInput(() => true)
  filterValues["physicalZip"] = useInput((value) => value.length === 5 || value.length === 0 ? true : false)
  filterValues['workPhone'] = useInput((value) => value.length < 14 && value.length !== 0 ? false : true)
  filterValues['homePhone'] = useInput((value) => value.length < 14 && value.length !== 0 ? false : true)
  filterValues['cellPhone'] = useInput((value) => value.length < 14 && value.length !== 0 ? false : true)
  filterValues['routingNumber'] = useInput(() => true)
  filterValues['accountNumber'] = useInput(() => true)
  filterValues["user"] = useInput(() => true)

  //update filterValues for select fields
  var statusReducer = useReducer(selectReducer, selectInitialState())
  filterValues["status"] = {}
  filterValues["status"]["state"] = statusReducer[0]
  filterValues["status"]["dispatch"] = statusReducer[1]
  var stateReducer = useReducer(selectReducer, selectInitialState())
  filterValues["state"] = {}
  filterValues["state"]["state"] = stateReducer[0]
  filterValues["state"]["dispatch"] = stateReducer[1]
  var physicalStateReducer = useReducer(selectReducer, selectInitialState())
  filterValues["physicalState"] = {}
  filterValues["physicalState"]["state"] = physicalStateReducer[0]
  filterValues["physicalState"]["dispatch"] = physicalStateReducer[1]
  var cardTypeReducer = useReducer(selectReducer, selectInitialState())
  filterValues["cardType"] = {}
  filterValues["cardType"]["state"] = cardTypeReducer[0]
  filterValues["cardType"]["dispatch"] = cardTypeReducer[1]
  var identificationTypeReducer = useReducer(selectReducer, selectInitialState())
  filterValues["identificationType"] = {}
  filterValues["identificationType"]["state"] = identificationTypeReducer[0]
  filterValues["identificationType"]["dispatch"] = identificationTypeReducer[1]

  const setFilterState = (filter) => {
    setFilter({...filter})
  }

  const filterValueKeys = Object.keys(filterValues)
  for (const key of filterValueKeys) {
    if (filterValues[key].reset) {
      resets.push(filterValues[key].reset)
    } else {
      resets.push(() => {filterValues[key].dispatch({type: "RESET"})})
    }
  }
  /**
   * End Dynamic Filters
   */

  

  /**
   * Name
   */
  const {
    value: firstName,
    inputChangeHandler: firstNameChangeHandler,
    inputBlurHandler: firstNameBlurHandler,
    reset: resetFirstName,
  } = useInput(() => true, firstParam);
  resets.push(resetFirstName)

  const {
    value: lastName,
    inputChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler,
    reset: resetLastName,
  } = useInput(() => true, lastParam);
  resets.push(resetLastName)

  /**
   * ID
   */
  const {
    value: idValue,
    inputChangeHandler: idChangeHandler,
    inputBlurHandler: idBlurHandler,
    reset: resetId,
  } = useInput(() => true);
  resets.push(resetId)

  /**
   * CBO
   */
  const [cboState, dispatchCbo] = useReducer(
    selectReducer,
    selectInitialState()
  )
  resets.push(() => {dispatchCbo({ type: "RESET" })})

  /**
   * Location
   */
  const [locationState, dispatchLocation] = useReducer(
    selectReducer,
    selectInitialState()
  );
  resets.push(() => {dispatchLocation({ type: "RESET" })})

  useEffect(() => {
    if (location.length > 1) {
      const optionsArr = location.map((item) => {
        return {
          value: item.id,
          label: item.name,
          isDisabled: false,
        };
      });

      optionsArr.unshift({
        label: "Select a location...",
        isDisabled: false,
        value: "",
      });

      dispatchLocation({ type: "SET_OPTIONS", payload: optionsArr });
    }
  }, [location]);

  useEffect(() => {
    if (cbo.length > 1) {
      const cboOptionsArr = cbo.map((item) => {
        return {
          value: item.id,
          label: item.name,
          isDisabled: false,
        }
      })

      cboOptionsArr.unshift({
        label: "Select a CBO...",
        isDisable: false,
        value: "",
      })

      dispatchCbo({ type: "SET_OPTIONS", payload: cboOptionsArr })
    }
  }, [cbo])

  const changeLocationHandler = (event) => {
    dispatchLocation({ type: "CHANGE", payload: event.target.value });
  };

  const changeCboHandler = (event) => {
    dispatchCbo({ type: "CHANGE", payload: event.target.value })
  }

  const submitHandler = (event) => {
    event.preventDefault();
    event.target.reset();

    const prepareBody = {
      first: `${firstName}` || null,
      last: `${lastName}` || null,
      id: `${idValue}` || null,
    };

    if (locationState.value) {
      prepareBody.locationId = locationState.value;
    }

    if (cboState.value) {
      prepareBody.cbo_id = cboState.value;
    }
    
    prepareBody.excludeFailures = !failuresChecked

    var isValid = true;
    for (const key of filterState.activeFields) {
      if (filterState[key].type !== "select") {
        if (!filterValues[key].isValid) {
          isValid = false
          filterValues[key].inputBlurHandler()
          continue;
        }
        if (filterValues[key].value !== "") {
          if (filterState[key].type === 'phone') {
            prepareBody[filterState[key].paramName] = filterValues[key].value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
          } else {
            prepareBody[filterState[key].paramName] = filterValues[key].value
          }
        }
      } else {
        if (filterValues[key].state.value !== "") {
          prepareBody[filterState[key].paramName] = filterValues[key].state.value
        }
      }
    }

    if (isValid) {
      onSearch(prepareBody);
    }
  };

  const downloadHandler = () => {
    const prepareBody = {
      first: `${firstName}` || null,
      last: `${lastName}` || null,
      id: `${idValue}` || null,
    };

    if (locationState.value) {
      prepareBody.locationId = locationState.value;
    }

    if (cboState.value) {
      prepareBody.cbo_id = cboState.value;
    }

    prepareBody.excludeFailures = !failuresChecked

    var isValid = true
    for (const key of filterState.activeFields) {
      if (filterState[key].type !== "select") {
        if (!filterValues[key].isValid) {
          isValid = false
          filterValues[key].inputBlurHandler()
          continue;
        }
        if (filterValues[key].value !== "") {
          if (filterState[key].type === 'phone') {
            prepareBody[filterState[key].paramName] = filterValues[key].value.replace('(', '').replace(')', '').replace(' ', '').replace('-', '')
          } else {
            prepareBody[filterState[key].paramName] = filterValues[key].value
          }
        }
      } else {
        if (filterValues[key].state.value !== "") {
          prepareBody[filterState[key].paramName] = filterValues[key].state.value
        }
      }
    }

    if (isValid) {
      getEnrollmentsExport(prepareBody)
        .then((response) => {
          const csvData = csvMaker(response);
          downloadCSVFile(csvData, "enrollments");
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // Nothing else worked, and i was running out of time, so i just made a function to clear all the inputs
  const runResets = (e) => {
    resets.forEach(function (f) { f() })
    setFailuresChecked(false)
    onSearch({excludeFailures: true})
  }

  return (
    <div>
      <div className={classes.Form__container}>
        <div className="container">
          <div className={classes.Form__text}>
            Enter <b>any</b> search parameter below and click search.   
            <span onClick={runResets} className={classes.Form__clear}>
                RESET FILTERS
            </span>
            <DynamicFilterDropdown
              filterState={filterState}
              setFilterState={setFilterState}
            />
          </div>

          <form onSubmit={submitHandler} className={classes.Form}>

            
              <div className={classes.Form__col}>

                <div className={classes.Form__input__long}>
                  <Input
                    label="First Name:"
                    clsType="small"
                    value={firstName}
                    onChange={firstNameChangeHandler}
                    onBlur={firstNameBlurHandler}
                    placeholder="Jane"
                  />
                </div>

              </div>
              <div className={classes.Form__col}>

                <div className={classes.Form__input__long}>
                  <Input
                    label="Last Name:"
                    clsType="small"
                    value={lastName}
                    onChange={lastNameChangeHandler}
                    onBlur={lastNameBlurHandler}
                    placeholder="Kenedy"
                  />
                </div>

              </div>
              <div className={classes.Form__col}>

                <div className={classes.Form__input__long}>
                  <Input
                    type="number"
                    clsType="small"
                    label="Confirmation:"
                    value={idValue}
                    onChange={idChangeHandler}
                    onBlur={idBlurHandler}
                    placeholder="10000000"
                  />
                </div>

              </div>
              <div className={classes.Form__col}>

                <div className={classes.Select}>
                  <Select
                    clsType="small"
                    label="CBO:"
                    value={cboState.value}
                    options={cboState.options}
                    onChange={changeCboHandler}
                  />
                </div>

              </div>
              <div className={classes.Form__col}>

                <div className={classes.Select}>
                  <Select
                    clsType="small"
                    label="Location:"
                    value={locationState.value}
                    options={locationState.options}
                    onChange={changeLocationHandler}
                  />
                </div>

              </div>

              <div className={classes.Form__col}>

                <Button color="primary" type="submit" clsType="small">
                  Search
                </Button>

              </div>
          </form>

          <DynamicFilterInputs
            filterState={filterState}
            setFilterState={setFilterState}
            resets={resets}
            filterValues={filterValues}
            submitHandler={submitHandler}
          />

          <div className={classes.Form__checkbox_container}>
            <input
              className={classes.Form__checkbox}
              type="checkbox"
              checked={failuresChecked}
              onChange={handleFailures}
              id="failuresCheckbox"
            />
            <label className={classes.Form__checkbox_label} for="failuresCheckbox">
              Show failures and cancelled enrollments
            </label>
          </div>

        </div>
      </div>

      <div className="container">
        <div className={classes.Row}>
          { Object.keys(rowSelection).length === 0 ? null :
            <>
              <Dropdown
                size="lg"
                title="Bulk Actions"
                className={classes.Bulk_Actions}
                toggleClassName={classes.Bulk_Actions__Toggle}
                menuStyle={{
                  width: '100%'
                }}
              >
                <Dropdown.Item onClick={handleBulkActionCancel}>Cancel</Dropdown.Item>
              </Dropdown>
            </>
          }
          <div className={classes.DownloadCol}>
          {true && (
            <div className={classes.Btn} onClick={downloadHandler}>
              Download <Download />
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchForm;
