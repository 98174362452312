import { useState, useEffect, useContext } from "react";
import ReactDOM from "react-dom";

import Layout from "../components/Layout/Layout";
import Heading from "../components/Heading/Heading.tsx";
import Space from "../components/UI/Space/Space";
import Notification from "../components/UI/Notification/Notification";
import UsersTable from "../components/Users/UsersTable";
import InviteUserModal from "../components/Users/InviteUserModal";

import { getCBOs, getMe, getUsers, userInvitation } from "../api/api";
import useNotification from "../hooks/useNotification";
import WaitModal from "../components/Enroll/WaitModal";
import AdminInviteUserModal from "../components/Users/AdminInviteUserModal";
import Select from "../components/UI/Select/Select";
import CardEnrollmentUserLevelContext from "../contexts/userLevelContext.js";

const Users = () => {
  const [users, setUsers] = useState(null);
  const [modalIsShow, setModalIsShow] = useState(false);
  const [inviteWaitIsShow, setinviteWaitIsShow] = useState(false);
  const [CBOs, setCBOs] = useState([])
  const [CBO, setCBO] = useState(window.localStorage.getItem('selectedCbo'))
  const role = useContext(CardEnrollmentUserLevelContext)

  const {
    status: notificationStatus,
    text: notificationText,
    btn_text: notificationBtnText,
    isShow: notificationIsShow,
    add: addNotification,
    remove: removeNotification,
  } = useNotification();

  const renderUsers = (cbo_id) => {
//    console.log("trying to render");
    const options = {}
    if (cbo_id) options.cbo_id = cbo_id
    // console.log(`getUsers with options`, options)
    getUsers(options)
      .then((response) => {
        setUsers(null);
        setUsers(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onCboChange = (event) => {
    const value = event.target.value
    setCBO(value)
    window.localStorage.setItem('selectedCbo', value)
    renderUsers(value)
  }

  useEffect(() => {
    let inviteScreen = 0;
    getMe().then((me) => {
      console.log(me)

      // keeping this one on old system instead of the CardEnrollmentUserContext
      // This code is too scary to edit. I dont want to break anything.
      
      // I agree that most of this is too scary to edit. I have taken bits and 
      // pieces and refactored it individually. TODO: Refactor this code to fully use the new context system
      // (8/1/24)
      if(me.card_enrollment_user_level === 'Administrator') {
        inviteScreen = 1;
      }
      window.localStorage.setItem("modal-type", inviteScreen)
      let cbo_id = window.localStorage.getItem('selectedCbo')
      populateCBO(cbo_id)
      renderUsers(cbo_id);
    })
  }, []);

  const openInviteUserModal = () => {
    setModalIsShow(true);
  };

  const closeModalHandler = () => {
    setModalIsShow(false);
  };

  const sendInvitationHandler = (body) => {
    removeNotification();
    setinviteWaitIsShow(true);
    userInvitation(body)
      .then((r) => {
        console.log('response', r)
        if(r.status === 'success') {
          addNotification({
            status: "success",
            text: "The request has been completed",
            btn_text: "OK",
          });

          setTimeout(() => {
            removeNotification();
          }, 3000);
          closeModalHandler();
          renderUsers();
        }else {
          addNotification({
            status: "error",
            text: r.response.message,
            btn_text: "OK",
          });
        }
        setinviteWaitIsShow(false);


      })
      .catch((error) => {
        setinviteWaitIsShow(false);

        addNotification({
          status: "error",
          text: "Error sending. Please check your inputs and try again.",
          btn_text: "OK",
        });

        setTimeout(() => {
          removeNotification();
        }, 3000);
      });
  };

  async function populateCBO() {
    const cbos = await getCBOs();
    const mapped = cbos.map(cbo => {
        return {
          label: cbo.name,
          value: cbo.id
        }
    })
    // if(CboId !== 'none') {
    //   mapped.splice(0, 0, {
    //     label: mapped[CboId].label,
    //     value: CboId
    //   })
    // }else {
    mapped.splice(0, 0, {
      label: 'Select a CBO',
      value: ''
    })
    // }

    setCBOs(mapped);
  }

  return (
    <>
      <Layout>
        <Heading
          title="Users"
          description="Everything you need to manage your team’s access."
          btn_text="Invite User"
          onClick={openInviteUserModal}
        />

        <Space value="50" />

        <div
        style={{
          display: 'flex',
          justifyContent: 'center'
        }}
        >
          <div style={{width: 20 + "%"}}>
            {window.localStorage.getItem("modal-type") === '1' &&
              <Select
                label='CBOs: *'
                onChange={onCboChange}
                options={CBOs}
                value={CBO}
              />
            }
          </div>
        </div>

        {users && <UsersTable users={users} render={renderUsers} cbo={CBO} />}

        <Space value="50" />
      </Layout>

      {modalIsShow &&
        ReactDOM.createPortal(
          role !== 'Administrator' ?
          <InviteUserModal
            onClose={closeModalHandler}
            onSave={sendInvitationHandler}
          /> :
          <AdminInviteUserModal
            onClose={closeModalHandler}
            onSave={sendInvitationHandler}
          />,
          document.querySelector("body")
        )}

      {notificationIsShow &&
        ReactDOM.createPortal(
          <Notification
            status={notificationStatus}
            width="full"
            text={notificationText}
            btn_text={notificationBtnText}
            onClose={() => removeNotification()}
          />,
          document.querySelector("body")
        )}
        {inviteWaitIsShow &&
        ReactDOM.createPortal(
          <WaitModal
            message="Creating new user and sending invite."
          />,
          document.querySelector("body")
        )}
    </>
  );
};

export default Users;
