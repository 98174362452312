import { getBaseUrl, getHeaders } from "./common.ts";

export interface Cbo {
  id: number;
  name: string;
  description?: string | null;
  inactive: boolean;
  helpful_links: Array<{ text: string; url: string }>;
  message_board?: string | null;
  enable_limit?: boolean | null;
  enrollment_limit?: number | null;
}

/**
 * Get CBOs
 * @returns Returns all CBOs
 */
export const getCBOs = async (status: string = 'all') => {
  const options = {
    headers: await getHeaders(),
  };

  const url = new URL(`/api/v1/cbos/${status}`, getBaseUrl());
  const resp = await fetch(url, options);

  if (!resp.ok) {
    throw new Error("Something went wrong!");
  }

  return (await resp.json()) as Cbo[];
};