import axios from 'axios';
import { PaginateApiResponse, getBaseUrl, getHeaders } from './common.ts';
import {
  FetchParametersParam,
  PaginationParams,
  SortingParams,
  addPaginationToFetchUrl,
  addParamsToFetchUrl,
  addSortingToFetchUrl,
} from './fetch.ts';

export interface SavedQueriesObject {
  id: number | null;
  name: string;
  sql_query: string;
  username: string;
  db: string;
  description: string;
}

export async function fetchQueries(
  params: FetchParametersParam,
  sorting: SortingParams,
  pagination: PaginationParams
): Promise<PaginateApiResponse<SavedQueriesObject>> {
  const fetchURL = new URL('/api/v1/data/queries/', getBaseUrl());

  addPaginationToFetchUrl(pagination, fetchURL);
  addParamsToFetchUrl(params, fetchURL);
  addSortingToFetchUrl(sorting, fetchURL);

  const response = await fetch(fetchURL.href, {
    headers: await getHeaders(),
  });

  let json = await response.json();

  json.items = json.items.map((item) => {
    // return the same thing but use moment to format the date in a reasonable way
    return item;
  });
  return json;
}


export async function deleteQuery(id: number) {
  const fetchURL = new URL(`/api/v1/data/query/saved/${id}`, getBaseUrl());

  await axios.delete(fetchURL.href, {
    headers: await getHeaders(),
  });
}

export async function createSavedQuery(sql_query, name, description, database) {
  const options = {
    method: "POST",
    headers: await getHeaders(),
    body: JSON.stringify({
      sql_query,
      name,
      description,
      database
    }),
  };

  const url = new URL(`/api/v1/data/query/saved/`, getBaseUrl());
  const resp = await fetch(url, options);

  if (!resp.ok) {
    var error_text =
      "Sorry, we were unable to complete that request.  Please check your inputs and try again.";
    try {
      var json = await resp.json();
      error_text = json.message;
    } catch { }
    throw new Error(error_text);
  }

  return await resp.json();
};

export async function modifySavedQuery(id, sql_query, name, description) {
  const options = {
    method: "PUT",
    headers: await getHeaders(),
    body: JSON.stringify({
      sql_query,
      name,
      description
    }),
  };

  const url = new URL(`/api/v1/data/query/saved/${id}`, getBaseUrl());
  await fetch(url, options);
};

export async function getSavedQuery(id): Promise<SavedQueriesObject> {
  const options = {
    method: "GET",
    headers: await getHeaders()
  };

  const url = new URL(`/api/v1/data/query/saved/${id}`, getBaseUrl());
  return (await fetch(url, options)).json();
};