import React from "react";

const IconLogout = () => {
    return (
        <svg width="51" height="38" viewBox="0 0 51 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.91474 21.2811H34.7724C36.0527 21.2811 37.0906 20.2431 37.0906 18.9629C37.0906 17.6826 36.0527 16.6447 34.7724 16.6447H7.91474L10.912 13.6476C11.8173 12.7424 11.8173 11.2746 10.912 10.3692C10.007 9.4639 8.5391 9.4639 7.63362 10.3692L0.679536 17.3233C0.625755 17.3769 0.575064 17.433 0.526846 17.4918C0.5151 17.5061 0.505055 17.5216 0.493618 17.5363C0.458227 17.5812 0.423455 17.6264 0.391618 17.674C0.382345 17.6879 0.374773 17.7026 0.365655 17.7166C0.333818 17.7664 0.3026 17.8166 0.274473 17.869C0.269064 17.8794 0.264582 17.8903 0.259173 17.9007C0.229964 17.9574 0.201836 18.0149 0.177264 18.0742C0.174173 18.0815 0.172009 18.0892 0.169073 18.0967C0.143882 18.1591 0.120545 18.2223 0.100764 18.2874C0.0981364 18.2956 0.0967455 18.3041 0.0944273 18.3124C0.0757273 18.3762 0.0585727 18.4407 0.0454364 18.5067C0.0415727 18.5263 0.0398727 18.5465 0.0363182 18.5663C0.0267364 18.621 0.0171545 18.6757 0.0117455 18.7315C0.00386364 18.808 0 18.8853 0 18.9629C0 19.0405 0.00386364 19.1177 0.0117455 19.1944C0.0171545 19.251 0.0270455 19.3061 0.0364727 19.3613C0.0398727 19.3805 0.0415727 19.4001 0.0454364 19.4191C0.0587273 19.4857 0.0757273 19.5508 0.0945818 19.6152C0.0969 19.6228 0.0982909 19.6308 0.100609 19.6384C0.120545 19.7038 0.143882 19.7676 0.169382 19.8307C0.172164 19.8375 0.174173 19.8447 0.177109 19.8515C0.201836 19.9113 0.230118 19.9691 0.259636 20.0262C0.264736 20.0362 0.269064 20.0467 0.274318 20.0568C0.302755 20.1095 0.334127 20.1601 0.366427 20.2104C0.375082 20.224 0.3825 20.2383 0.391464 20.2516C0.423455 20.2994 0.458536 20.3448 0.493773 20.39C0.505055 20.4045 0.5151 20.4198 0.526691 20.434C0.575064 20.4926 0.625755 20.5488 0.679382 20.6025L7.63346 27.5565C8.08613 28.0094 8.67943 28.2356 9.27273 28.2356C9.86603 28.2356 10.4593 28.0094 10.9118 27.5565C11.8172 26.6512 11.8172 25.1835 10.9118 24.2782L7.91474 21.2811Z" fill="#00AD43"/>
            <path d="M32.037 0C25.7093 0 19.822 3.13913 16.2883 8.39692C15.5743 9.45942 15.8568 10.8998 16.9193 11.6139C17.982 12.3279 19.4222 12.0457 20.1365 10.9829C22.8072 7.00879 27.2559 4.63621 32.037 4.63621C39.9366 4.63636 46.3635 11.0631 46.3635 18.9629C46.3635 26.8626 39.9366 33.2894 32.037 33.2894C27.27 33.2894 22.8285 30.9275 20.1558 26.9714C19.4389 25.9105 17.9982 25.6317 16.9371 26.3483C15.8763 27.0651 15.5972 28.5061 16.314 29.5669C19.8501 34.801 25.7278 37.9258 32.037 37.9258C42.493 37.9258 50.9998 29.4191 50.9998 18.9629C50.9998 8.50665 42.493 0 32.037 0Z" fill="#78D341"/>
        </svg>
    );
};

export default IconLogout;
