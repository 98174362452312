import classes from "./Footer.module.scss";
import moment from "moment";

const Header = () => {
  return (
    <div className={classes.Footer}>
      <div className={classes.FooterRows}>
        <div className={classes.FooterCopyright}>
          &copy; {(new Date().getFullYear())} Community Financial Resources
        </div>
        <div>
          Version: Admin R4+ (Build {moment(process.env.REACT_APP_BUILD_DATE).format("DDMMYY")})
        </div>
        <div className={classes.VisitBlog}>
          <a href="https://www.communityfinancialresources.org/blog" target="blank">Visit CFR's blog</a>
        </div>
      </div>
    </div>
  );
};

export default Header;
