import axios from "axios";
import { getBaseUrl, getHeaders } from "./common.ts";

export async function getAllLocations(): Promise<any> {
  const fetchURL = new URL('/api/v1/org/locations?getAll=true', getBaseUrl());


  const response = await axios.get(fetchURL.href, {
    headers: await getHeaders(),
  });

  return response.data.items;
}