import Modal from '../../UI/Modal/Modal';
import Select from '../../UI/Select/Select';
import { useEffect, useState } from 'react';
import Input from '../../UI/Input/Input';
import useTimeAgo from '../../../hooks/useTimeAgo';
import Button from '../../UI/Button/Button';
import { getStatusCodes } from '../../../api/api';
import useInput from "../../../hooks/useInput"
import classes from "./Files.module.scss"
import Space from '../../UI/Space/Space';

function UpdateModal({ onSubmit, onClose, file }) {
    const [status, setStatus] = useState();
    const [statusOptions, setStatusOptions] = useState([
        { label: 'Loading... ', value: -1, disabled: true },
    ]);

    const {
        inputChangeHandler: commentChangeHandler,
        value: comment
    } = useInput(v => v.trim() !== '')

    useEffect(() => {
        getStatusCodes().then((sc) => {
            setStatusOptions(
                sc.map((v) => {
                    return {
                        label: v.name,
                        value: v.id,
                    };
                })
            );

            setStatus('' + file.status_code);
        });
    }, [file.status_code]);

    useEffect(() => {
        console.log(status)
    }, [status])

    return (
        <Modal onClose={onClose} className={classes.Modal__UpdateModal} hideTitle>
            <div>
                <h2 className={classes.Modal__title}>Update Status</h2>
                <p>You are about to update the status of the following file:</p>
                <span className={classes.Modal__bold}>
                    Client: {file.uploaded_by} <br />
                    Uploaded: {useTimeAgo(file.uploaded)}
                    <br />
                    {file.name}
                </span>
            </div>
            <Space value={25}/>
            <div>
                <p>
                    Please update the status below and provide a comment related
                    to the status.
                </p>
                <div className={classes.Modal__inputs}>
                    <Select
                        options={statusOptions}
                        value={status}
                        onChange={(e) => {
                            setStatus(e.target.value);
                        }}
                        label="Status:"
                    />
                    <Input
                        label="Comment:*"
                        value={comment}
                        onChange={commentChangeHandler}
                        useArea
                        area_height={100}
                    />
                </div>
            </div>
            <div className={classes.Modal__footer}>
                <Button color="secondary" onClick={onClose} className={classes.Modal__button}>Cancel</Button>
                <Button color="primary" onClick={() => onSubmit(status, comment)} className={classes.Modal__button}>
                    Update
                </Button>
            </div>
        </Modal>
    );
}

export default UpdateModal;
