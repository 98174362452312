import React, { useEffect } from "react";
import Button from "../UI/Button/Button";
import classes from "./Heading.module.scss";
import { motion } from 'framer-motion';

interface BaseHeadingProps {
  title: string;
  subtitle: string;
  description: string;
}

type HeadingWithBtnProps = BaseHeadingProps & {
  btn_text: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

type HeadingProps = BaseHeadingProps | HeadingWithBtnProps;

const Heading: React.FC<HeadingProps> = (props) => {
  useEffect(() => {
    document.head.getElementsByTagName('title')[0].innerText = props.title + ' - Community Financial Resources';
  }, [props.title])

  return (
    <div className={classes.Heading}>
      <div className={`container ${classes.Heading__wrap}`}>
        <div className={classes.Heading__content}>
          <motion.h1
            initial={{opacity: 0, x: -60 }}
            animate={{opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
            className={classes.Heading__title}>
            {props.title}
          </motion.h1>
          {props.subtitle && (
            <motion.p
              initial={{opacity: 0, x: -60 }}
              animate={{opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
              className={classes.Heading__subtitle}>
              {props.subtitle}
            </motion.p>
          )}
        </div>
        <motion.p
          initial={{opacity: 0, x: -60 }}
          animate={{opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
          className={classes.Heading__description}>
          {props.description}
        </motion.p>
        {'btn_text' in props && props.btn_text && (
          <div className={classes.Heading__btn}>
            <Button onClick={props.onClick}>{props.btn_text}</Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Heading;
