import React from 'react';


const SortArrow = (props: { direction: 'up' | 'down', style: any}) => {
    const useUpArrow = props.direction === 'up';

    return (
        <div style={props.style}>
            {useUpArrow ? (
                <svg
                    width="10"
                    height="5"
                    viewBox="0 0 60 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5 30L29.2929 5.70711C29.6834 5.31658 30.3166 5.31658 30.7071 5.70711L55 30"
                        stroke="#505050"
                        stroke-width="10"
                        stroke-linecap="round"
                    />
                </svg>
            ) : (
                <svg
                    width="10"
                    height="5"
                    viewBox="0 0 60 35"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M55 5L30.7071 29.2929C30.3166 29.6834 29.6834 29.6834 29.2929 29.2929L5 5.00001"
                        stroke="#505050"
                        stroke-width="10"
                        stroke-linecap="round"
                    />
                </svg>
            )}
        </div>
    );
};

export default SortArrow;