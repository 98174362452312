import React from "react";

const New = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.7363 4.24493C17.6289 -0.862417 9.35277 -0.862417 4.24542 4.24493C-0.861925 9.35228 -0.861926 17.6284 4.24542 22.7358C9.35277 27.8431 17.6289 27.8431 22.7363 22.7358C27.8436 17.6284 27.8436 9.35228 22.7363 4.24493ZM15.5972 20.4505C15.5972 20.8009 15.3138 21.0843 14.9634 21.0843L12.0146 21.0805C11.6641 21.0805 11.3808 20.7972 11.3808 20.4468L11.4032 15.578L6.53068 15.5967C6.18025 15.5967 5.89692 15.3133 5.89692 14.9629L5.90065 12.0141C5.90065 11.6636 6.18398 11.3803 6.53441 11.3803L11.4032 11.4027L11.3845 6.53019C11.3845 6.17976 11.6679 5.89643 12.0183 5.89643L14.9709 5.89643C15.3213 5.89643 15.6046 6.17976 15.6046 6.53019L15.5785 11.4027L20.451 11.384C20.8014 11.384 21.0848 11.6674 21.0848 12.0178L21.0848 14.9704C21.0848 15.3208 20.8014 15.6041 20.451 15.6041L15.5785 15.578L15.5972 20.4505Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default New;
