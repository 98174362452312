import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { FilterItem } from "../../List/Common/DynamicFilters/FilterState";
import classes from "./PredefinedSelection.module.scss"
import CreateSelectionModal from "./CreateSelectionModal.tsx";
import { PredefinedSelectionObject, getCashflowPredefinedSelections } from "../../../api/data.ts";

const PredefinedSelection: React.FC<{ filter_item: FilterItem, filter_value: any }> = ({ filter_item, filter_value }) => {
  const [state, setState] = filter_value;
  const [options, setOptions] = useState<{label: string, value: string}[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editingId, setEditingId] = useState<number | null>(null);

  useEffect(() => {
    console.log(state)
    if(state.selected === "-2") {
      setShowModal(true);
      setEditingId(null);
      setState(e => ({...e, selected: -1}));
    }
  }, [state, setState])

  function refreshOptions() {
    return getCashflowPredefinedSelections().then(o => {
      setOptions(o.map(s => ({label: s.name, value: s.id.toString()})))
    })
  }

  useEffect(() => {
    refreshOptions()
  }, [])

  function onSubmit(selection: PredefinedSelectionObject | null) {
    if(!selection) {
      refreshOptions().then(()=> {
        setState(e => ({...e, selected: -1}));
        setShowModal(false);
      })
      return;
    }
    refreshOptions().then(() => {
      setState(e => ({...e, selected: selection.id}));
      setShowModal(false);
    });
  }

  function edit(id: number) {
    console.log(id, 'setting')
    setEditingId(id);
    setShowModal(true);
  }

  return (
    <>
    <div className={classes.container}>
      <select value={state.selected} onChange={(e) => setState(s => ({...s, selected: e.target.value}))} className={classes.sel}>
        {[{label: filter_item.label, value: -1}, ...options, {label: "Create a new predefined selection", value: -2}].map((v, i) => (<option key={i} value={v.value}>{v.label}</option>))}
      </select>
      {!!(state.selected && state.selected !== '-1') && (
        <button type="button" onClick={() => edit(parseInt(state.selected))}>Edit</button>
      )}
    </div>
    {showModal && ReactDOM.createPortal((<>
      <CreateSelectionModal onClose={() => setShowModal(false)} onSubmit={onSubmit} editing_id={editingId}/>
    
      </>), document.querySelector("body") as Element, null)}
    
    </>
  )
}

export default PredefinedSelection;