import classes from "./Charts.module.scss";
import DailyEnrollments from "./DailyEnrollments";
import MonthlyCashflow from "./MonthlyCashflow";

const Charts = () => {
    return (
        <div className={classes.Charts}>
            <div className="container">
                <div className="row">
                    <DailyEnrollments />
                    <MonthlyCashflow />
                </div>
            </div>
        </div>
    )
}

export default Charts;