import classes from './CBOs.module.scss';
import Select from '../UI/Select/Select'
import Button from '../UI/Button/Button';
import moment from 'moment'
import { csvMaker, downloadCSVFile } from '../../assets/helper';
import { useState } from 'react';
import Space from '../UI/Space/Space';

function DownloadIcon() {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
        <path d="M17.409 12.4443V16.588H3.43401V12.4443H0.748047V17.806C0.748047 18.4783 1.34849 19.0235 2.09303 19.0235H18.75C19.4938 19.0235 20.0949 18.4789 20.0949 17.806V12.4443H17.409Z" fill="#579D36"/>
        <path d="M10.1755 12.0333L6.32999 7.81878C6.32999 7.81878 5.7449 7.31776 6.37936 7.31776C7.01383 7.31776 8.54629 7.31776 8.54629 7.31776C8.54629 7.31776 8.54629 6.98072 8.54629 6.46095C8.54629 4.97907 8.54629 2.28216 8.54629 1.18271C8.54629 1.18271 8.46022 0.883789 8.95659 0.883789C9.45695 0.883789 11.6485 0.883789 12.0068 0.883789C12.3644 0.883789 12.3564 1.13551 12.3564 1.13551C12.3564 2.20169 12.3564 4.99178 12.3564 6.42525C12.3564 6.88996 12.3564 7.1913 12.3564 7.1913C12.3564 7.1913 13.5853 7.1913 14.3565 7.1913C15.1264 7.1913 14.5467 7.71592 14.5467 7.71592C14.5467 7.71592 11.2749 11.6551 10.8186 12.0684C10.4903 12.3679 10.1755 12.0333 10.1755 12.0333Z" fill="#579D36"/>
    </svg>)
}

function CBOActions({data, runBulkAction}) {
    const [bulkAction, setBulkAction] = useState('title')

    const options = [
        {value: 'title', label: 'Bulk Actions'},
        {value: 'deactivate', label: 'Deactivate'}
    ]

    function download() {
        let dataToDownload = data;
        // console.log(selectedData)
        // if(selectedData && Object.keys(selectedData).length > 0) {
        //     dataToDownload = getDataFromSelected();
        // }
        downloadCSVFile(csvMaker(dataToDownload.map(d => {
            return {
                'CBO Id': d.id,
                'CBO Name': d.name,
                'CBO Description': (d.description || '').replace(',', ''),
                'Active': !d.inactive,
                'Last Enrollment Date': moment(d.latest_enrollment_timestamp).isValid() ? moment(d.latest_enrollment_timestamp).format('M / DD / YYYY') : 'N/a',
                'YTD Enrollments': d.ytd_enrollment_count,
                'Active Locations': d.location_count
            }
        })), 'data')
    }

    if(!data || !data.length) {
        return (<Space value={25}/>)
    }

    return (
        <div className={classes.Actions__container}>
            <div className={classes.Actions__inner}>
                <div className={classes.Actions__dropdown}>
                    <Select
                        options={options}
                        value={bulkAction}
                        onChange={(e) => {setBulkAction(e.target.value)}}
                    />
                    {
                        bulkAction === 'title' ? '' :
                        <Button color={'lightgreen'} onClick={() => runBulkAction.current(bulkAction)}>Execute Action</Button>
                    }

                </div>

                <Button color={'lightgreen'} onClick={download}><div style={{display:'flex', flexDirection: 'row'}}>Download<div style={{width:10}}></div><DownloadIcon/></div></Button>
            </div>
        </div>
    )
}

export default CBOActions;