import { NavLink } from "react-router-dom";
import {
  MdCreditCard,
  MdPersonOutline,
  MdErrorOutline,
  MdOutlineCreditCardOff,
} from "react-icons/md";

import useTimeAgo from "../../../hooks/useTimeAgo";
import classes from "./Activity.module.scss";

const ActivityItem = ({ item }) => {
  const time = useTimeAgo(item.timestamp);

  const Icon = () => {
    switch (item.category) {
      case "enrollment":
        return <MdCreditCard />;

      case "account":
        return <MdPersonOutline />;

      case "failure":
        return <MdErrorOutline />;

      case "cancellation":
        return <MdOutlineCreditCardOff />;

      default:
        return null;
    }
  };

  return (
    <div className={classes.Activity}>
      <div className={classes.Activity__icon}>
        <Icon />
      </div>
      <div>
        <div>
          <p
            className={classes.Activity__text}
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
          <span className={classes.Activity__time}>{time}</span>
        </div>

        { item && item.enrollment_id !== 0  && (
          <NavLink
            to={`/enrollment-detail/${item.enrollment_id}`}
            className="link"
          >
            view details
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default ActivityItem;
