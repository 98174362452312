import { useState } from "react";

import Modal from "../../UI/Modal/Modal";
import Input from "../../UI/Input/Input";
import Select from "../../UI/Select/Select";
import Button from "../../UI/Button/Button";
import Space from "../../UI/Space/Space";

import useInput from "../../../hooks/useInput";
import classes from "./AboutLocation.module.scss";

const fillStates = () => {
  const statesJson = require("../../../assets/json/us-states.json");

  const options = [];

  for (let key in statesJson) {
    options.push({
      value: key,
      label: statesJson[key],
      isDisabled: false,
    });
  }

  options.unshift({
    value: "0",
    label: "",
    isDisabled: true,
  });

  return options;
};

const LocationDetailsModal = ({ onClose, onSave, data }) => {
  const options = fillStates();
  const currentAddress = data.address;

  const { id, name, phone } = data;
  const { address, address_2, city, state, zip } = currentAddress;

  const [activeOption, setActiveOption] = useState(state);  

  const {
    value: enteredAddress,
    isTouched: addressTouched,
    isValid: addressIsValid,
    hasError: addressHasError,
    inputChangeHandler: addressChangeHandler,
    inputBlurHandler: addressBlurHandler,
  } = useInput((value) => value.trim() !== "", address);

  const {
    value: enteredAddress_2,
    inputChangeHandler: address_2ChangeHandler,
    inputBlurHandler: address_2BlurHandler,
  } = useInput(() => true, address_2);

  const {
    value: enteredCity,
    isTouched: cityTouched,
    isValid: cityIsValid,
    hasError: cityHasError,
    inputChangeHandler: cityChangeHandler,
    inputBlurHandler: cityBlurHandler,
  } = useInput((value) => value.trim() !== "", city);

  const {
    value: enteredZip,
    isTouched: zipTouched,
    isValid: zipIsValid,
    hasError: zipHasError,
    inputChangeHandler: zipChangeHandler,
    inputBlurHandler: zipBlurHandler,
  } = useInput((value) => value.trim().length === 5, `${zip}`);

  const {
    value: enteredPhone,
    isTouched: phoneTouched,
    isValid: phoneIsValid,
    hasError: phoneHasError,
    inputChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput((value) => value.trim() !== "", phone);

  const changeSelectHandler = (event) => {
    setActiveOption(event.target.value);
  };

  let formIsValid = false;

  if (addressIsValid && cityIsValid && zipIsValid && phoneIsValid) {
    formIsValid = true;
  }

  const saveHandler = (event) => {
    event.preventDefault();

    if (!formIsValid) {
      return;
    }

    const prepareBody = {
      name: name,
      address: enteredAddress,
      address_2: enteredAddress_2,
      city: enteredCity,
      state: activeOption,
      zip: enteredZip,
      phone: enteredPhone,
    };

    onSave(id, prepareBody);
  };

  return (
    <Modal title="Location Details" onClose={onClose}>
      <form onSubmit={saveHandler}>
        <Input
          label="Location Name:*"
          clsType="small"
          value={name}
          disabled="disabled"
        />

        <Input
          label="Address Line 1:*"
          clsType="small"
          required={true}
          onChange={addressChangeHandler}
          onBlur={addressBlurHandler}
          value={enteredAddress}
          touched={addressTouched}
          hasError={addressHasError}
        />

        <Input
          label="Address Line 2:"
          clsType="small"
          onChange={address_2ChangeHandler}
          onBlur={address_2BlurHandler}
          value={enteredAddress_2}
        />

        <div className={classes.Modal__row}>
          <div className={classes.Modal__col__100}>
            <Input
              label="City:"
              clsType="small"
              required={true}
              onChange={cityChangeHandler}
              onBlur={cityBlurHandler}
              value={enteredCity}
              hasError={cityHasError}
              touched={cityTouched}
            />
          </div>

          <div className={classes.Modal__col__100}>
            <Select
              label="State:"
              options={options}
              clsType="small"
              value={activeOption}
              onChange={changeSelectHandler}
            />
          </div>
        </div>

        <div className={classes.Modal__row}>
          <div className={classes.Modal__col__100}>
            <Input
              type="number"
              label="Zip:"
              clsType="small"
              required={true}
              onChange={zipChangeHandler}
              onBlur={zipBlurHandler}
              value={enteredZip}
              hasError={zipHasError}
              touched={zipTouched}
              errorMessage="Must be 5 digits"
            />
          </div>

          <div className={classes.Modal__col__auto}>
            <Input
              label="Phone:*"
              clsType="small"
              required={true}
              onChange={phoneChangeHandler}
              onBlur={phoneBlurHandler}
              value={enteredPhone}
              hasError={phoneHasError}
              touched={phoneTouched}
            />
          </div>
        </div>

        <Space value="30" />

        <div className={classes.Modal__center}>
          <Button color="primary" type="submit" disabled={!formIsValid}>
            Save Changes
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default LocationDetailsModal;
