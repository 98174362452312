import { useEffect, useState } from "react";
import FileItem from "./FileItem";

import classes from "./Files.module.scss";
import LoadingBox from "../../UI/LoadingBox/LoadingBox";

const Files = ({ filesList, setFilesList, getFiles, shouldDisplayStatus, extra_button }) => {
  const [list, setList] = useState(filesList && filesList.items);
  const [summary, setSummary] = useState(filesList && filesList.summary);
  const [loading, setLoading] = useState(false);
  
  const refreshHandler = (getFiles) => {
    // clear list while fetching new data
    setList([]);
    setLoading(true);

    getFiles(1, 3)
      .then((response) => {
        setList(response.items);
        setSummary(response.summary);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
    });
  }
  const loadMoreHandler = () => {
    const pageNumber = +summary.current_page + 1;

    getFiles(pageNumber, 3)
      .then((response) => {
        setList((prevState) => {
          return [...prevState, ...response.items];
        });
        setSummary(response.summary);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  useEffect(() => {
    if(filesList) {
      refreshHandler(getFiles);
      setList(filesList.items)
      setSummary(filesList.summary)
    }
    
  }, [filesList, getFiles]);

  return (
    <div className={classes.File__wrap}>
      <LoadingBox 
        isLoading={loading}
      >
        <div className={classes.File__listContainer}>
          {list && list.map((item) => (
            <FileItem key={item.id} item={item} setFilesList={setFilesList} shouldDisplayStatus={shouldDisplayStatus}/>
          ))}
          {list && list.length === 0 && "All Files have been processed"}
        </div>
      </LoadingBox>
      
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '100px'}}>
        {summary && summary.more_items ? (
          <span
            className={`link ${classes.File__more}`}
            onClick={loadMoreHandler}
          >
            view older files &gt;
          </span>
        ) : null}
        {extra_button}
      </div>
      <br />
      
      
    </div>
  );
};

export default Files;
