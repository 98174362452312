import HistoryItem from "./HistoryItem";

import classes from "./EnrollmentDetail.module.scss";

const History = ({ enrollment }) => {
  if (!enrollment || !enrollment.history.length) {
    return <div></div>;
  }

  const { history } = enrollment;

  return (
    <div className={classes.History}>
      {history.map((item, index) => (
        <HistoryItem key={index} item={item} />
      ))}
    </div>
  );
};

export default History;
