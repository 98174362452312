import CommonTable from "../Common/Table"

const ReportTable = ({ bodyParams, rowSelection, setRowSelection, fetchReport, columns, rowActions, rowActionsSize, refreshTrigger }) => {

  return (
    <div className="container">
      <CommonTable
        columns={ columns }
        fetch={ fetchReport }
        bodyParams={ bodyParams }
        // entryPath={"/enrollment-detail/"}
        setRowSelection={setRowSelection}
        rowSelection={rowSelection}
        rowActions={rowActions}
        rowActionsSize={rowActionsSize}
        refreshTrigger={refreshTrigger}
      />
    </div>
  );
};

export default ReportTable;