import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import Button from "../../UI/Button/Button";

import classes from "./DropZone.module.scss";

const DropZonePure = ({ files, setFiles, onSubmit, text, hasUploadButton }) => {
  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, [setFiles]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <>
      <div className={classes.Wrapper}>
        <div
          className={`${classes.DropZone} ${
            isDragActive ? classes.active : ""
          }`}
          {...getRootProps()}
        >
          <span className={classes.DropZone__text}>{text}</span>

          <input {...getInputProps()} />
        </div>

        <div className={classes.Row}>
          <div className={classes.DropZone__input} onClick={open}>
            <button className={classes.DropZone__input__btn}>
              Choose File
            </button>
            <div className={classes.DropZone__input__text}>
              {files.length ? `${files[0].path}` : "No file chosen"}
            </div>
          </div>
          {hasUploadButton && 
          <div className={classes.DropZone__btn}>
            <Button onClick={onSubmit}>Upload</Button>
          </div>}
        </div>
      </div>
    </>
  );
};

export default DropZonePure;
