import React, { useState, useEffect, useRef, useCallback } from 'react';

import classes from "./Scrollbar.module.scss";

const Scrollbar = ({
  children
}: React.ComponentPropsWithoutRef<'div'>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const scrollTrackRef = useRef<HTMLDivElement>(null);
  const scrollThumbRef = useRef<HTMLDivElement>(null);
  const observer = useRef<ResizeObserver | null>(null);
  const childrenCount = React.Children.count(children);
  const [initialChildrenCount] = useState(childrenCount);
  const [needsScroll, setNeedsScroll] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [thumbHeight, setThumbHeight] = useState(20);
  const [scrollStartPosition, setScrollStartPosition] = useState<number | null>(null);
  const [initialScrollTop, setInitialScrollTop] = useState<number>(0);
  const [isDragging, setIsDragging] = useState(false);

  function handleResize(ref: HTMLDivElement, trackSize: number) {
    const { clientHeight, scrollHeight } = ref;
    setThumbHeight(Math.max((clientHeight / scrollHeight) * trackSize, 20));
  }

  const preventAndStop = (e) => {
    if (!e.touches) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  // function handleScrollButton(direction: 'up' | 'down') {
  //   const { current } = contentRef;
  //   if (current) {
  //     const scrollAmount = direction === 'down' ? 200 : -200;
  //     current.scrollBy({ top: scrollAmount, behavior: 'smooth' });
  //   }
  // }

  const handleTrackClick = useCallback((e) => {
    preventAndStop(e);
    const { current: trackCurrent } = scrollTrackRef;
    const { current: contentCurrent } = contentRef;
    if (trackCurrent && contentCurrent) {
      const clientY = e.clientY || e.touches[0].clientY
      const target = e.target as HTMLDivElement;
      const rect = target.getBoundingClientRect();
      const trackTop = rect.top;
      const thumbOffset = -(thumbHeight / 2);
      const clickRatio = (clientY - trackTop + thumbOffset) / trackCurrent.clientHeight;
      const scrollAmount = Math.floor(clickRatio * contentCurrent.scrollHeight);
      contentCurrent.scrollTo({
        top: scrollAmount,
        behavior: 'smooth',
      });
    }
  }, [thumbHeight]);

  const handleTouch = useCallback((e) => {
    let isTargetTrack = e.target === document.querySelector(`.${classes.Scrollbars__track}`)
    if (isTargetTrack) handleTrackClick(e)
    else e.preventDefault()
  }, [handleTrackClick])

  const handleThumbPosition = useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) return;

    const { scrollTop: contentTop, scrollHeight: contentHeight } = contentRef.current;
    const { clientHeight: trackHeight } = scrollTrackRef.current;
    let newTop = (+contentTop / +contentHeight) * trackHeight;
    newTop = Math.min(newTop, trackHeight - thumbHeight);
    const thumb = scrollThumbRef.current;
    thumb.style.top = `${newTop}px`;
  }, [thumbHeight]);

  const handleThumbMousedown = useCallback((e) => {
    preventAndStop(e);
    const clientY = e.clientY || e.touches[0].clientY;
    setScrollStartPosition(clientY);
    if (contentRef.current) setInitialScrollTop(contentRef.current.scrollTop);
    setIsDragging(true);
  }, []);

  const handleThumbMouseup = useCallback((e) => {
    preventAndStop(e);
    if (isDragging) setIsDragging(false);
  }, [isDragging]);

  const handleThumbMousemove = useCallback(
    (e) => {
      if(!contentRef.current) {
        return;
      }
      if(!scrollStartPosition) {
        return;
      }
      preventAndStop(e);
      if (isDragging) {
        const {
          scrollHeight: contentScrollHeight,
          offsetHeight: contentOffsetHeight,
        } = contentRef.current;

        const clientY = e.clientY || e.touches[0].clientY
        const deltaY =
          (clientY - scrollStartPosition) *
          (contentOffsetHeight / thumbHeight);
        const newScrollTop = Math.min(
          initialScrollTop + deltaY,
          contentScrollHeight - contentOffsetHeight
        );

        contentRef.current.scrollTop = newScrollTop;
      }
    },
    [isDragging, scrollStartPosition, thumbHeight, initialScrollTop]
  );

  // If the content and the scrollbar track exist, use a ResizeObserver to adjust height of thumb and listen for scroll event to move the thumb
  useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      const { clientHeight: trackSize } = scrollTrackRef.current;
      if (ref.scrollHeight > ref.clientHeight) setNeedsScroll(true) // only show scrollbar if needed
      else setNeedsScroll(false)
      if (!hasScrolled) { // bottom align scrollbar on load
        ref.scrollTop = ref.scrollHeight;
        setHasScrolled(true);
      }
      if (initialChildrenCount !== childrenCount) { // bottom align scrollbar on child added
        ref.scrollTop = ref.scrollHeight;
        handleThumbPosition()
      }
      observer.current = new ResizeObserver(() => {
        handleResize(ref, trackSize);
      });
      observer.current.observe(ref);
      ref.addEventListener('scroll', handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener('scroll', handleThumbPosition);
      };
    }
  }, [handleThumbPosition, hasScrolled, initialChildrenCount, childrenCount]);

  // Listen for mouse events to handle scrolling by dragging the thumb
  useEffect(() => {
    const mouseUpEvents = ['mouseup', 'mouseleave', 'touchend', 'touchcancel']
    document.addEventListener('mousemove', handleThumbMousemove);
    document.addEventListener('touchmove', handleThumbMousemove);
    mouseUpEvents.forEach(event => {
      document.addEventListener(event, handleThumbMouseup);
    })

    const scrollbar = document.querySelector(`.${classes.Scrollbars__scrollbar}`);

    if(scrollbar) {
      scrollbar.addEventListener('touchstart', handleTouch)
    }

    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove);
      document.removeEventListener('touchmove', handleThumbMousemove);
      mouseUpEvents.forEach(event => {
        document.removeEventListener(event, handleThumbMouseup);
      })
      if(scrollbar) {
        scrollbar.removeEventListener('touchstart', handleTouch)
      }
    };
  }, [handleThumbMousemove, handleThumbMouseup, handleTouch]);

  return (
    <div className={`${classes.Scrollbars} ${needsScroll ? '' : classes.Scrollbars__no_scrollbar}`}>
      <div className={classes.Scrollbars__content} ref={contentRef}>
        {children}
      </div>
      <div className={classes.Scrollbars__scrollbar}>
        {/* <button
          className={classes.Scrollbars__button}
          onClick={() => handleScrollButton('up')}
        >⇑</button> */}
        <div className={classes.Scrollbars__track_thumb}>
          <div
            className={classes.Scrollbars__track}
            ref={scrollTrackRef}
            onClick={handleTrackClick}
            style={{ cursor: isDragging ? 'grabbing' : "pointer" }}
          ></div>
          <div
            className={classes.Scrollbars__thumb}
            ref={scrollThumbRef}
            onMouseDown={handleThumbMousedown}
            onTouchStart={handleThumbMousedown}
            style={{
              height: `${thumbHeight}px`,
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
          ></div>
        </div>
        {/* <button
          className={classes.Scrollbars__button}
          onClick={() => handleScrollButton('down')}
        >⇓</button> */}
      </div>
    </div>
  );
};

export default Scrollbar;
