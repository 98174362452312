import { NavLink } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import classes from "./Header.module.scss";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu.tsx";
import { useState } from "react";
import { runEnrollmentReport } from "../../api/api";
import {useAuth0} from "@auth0/auth0-react";
import Logo from "../Logo/Logo";
import {Accordion, Dropdown} from "react-bootstrap";
import RunReport from "../Report/RunReport";
import CardEnrollmentUserLevelContext from "../../contexts/userLevelContext";
import UserContext from "../../contexts/userContext";
import { endViewAsSession } from "../../api/users.ts";
import { IconButton, Tooltip } from "@mui/material";
import { KeyboardReturn } from "@mui/icons-material";

const Header = () => {
  const {user} = useAuth0();
  const [showUserPage, setShowUserPage] = useState(localStorage.getItem('showUsers') === 'true');
  const [showAdminNav, setShowAdminNav] = useState(localStorage.getItem('isAdministrator') === 'true');
  let [navbarOpen, setNavbarOpen] = useState(false);
  const navigate = useNavigate();
  let resized;
  let _windowWidth = window.outerWidth;
  const nav = document.getElementById("header-nav");
  const overlay = document.getElementById("header-overlay");
  const role = useContext(CardEnrollmentUserLevelContext)
  const DbUser = useContext(UserContext)

  // REPORTS
  const enrollmentLimitReport = useRef(null)

  //Mobile Hamburger Click Handler
  const clickHandler = async () => {
    let headerHeight = document.getElementById("header").offsetHeight;

    if (!navbarOpen) {
      document.body.classList.add('no-scroll');
      nav.style.top = headerHeight + "px";
      nav.style.height = "calc(100vh - " + headerHeight + "px)";
      overlay.style.top = headerHeight + "px";
      overlay.style.height = "calc(100vh - " + headerHeight + "px)";
      window.scroll(0,0);
    } else {
      resetNav();
    }
    setNavbarOpen(!navbarOpen);
  };
  //On nav item click
  const navClickHandler = async () => {
    removeNavActiveStatus();
  };

  //Resize functionality that only fires if the width is changed not the height (this is useful on mobile with address bar hiding/showing)
  const handleResize = event => {
    removeNavActiveStatus();
  }

  function removeNavActiveStatus() {
    setNavbarOpen(navbarOpen = false);
    resetNav();
  }

  function resetNav() {
    document.body.classList.remove('no-scroll');
    setTimeout(function() {
      nav.style.removeProperty("top");
      nav.style.removeProperty("height");
      overlay.style.removeProperty("top");
      overlay.style.removeProperty("height");
    },301);
  }

  function resizeWidthOnly() {
    let currentWindowWidth =  window.outerWidth;
    if (_windowWidth !== currentWindowWidth) {
      _windowWidth = currentWindowWidth;
      return _windowWidth;
    } else {
      return false;
    }
  }

  function resizedw() {
    if (resizeWidthOnly() !== false) {
      handleResize();
    }
  }

  window.onresize = function () {
    clearTimeout(resized);
    resized = setTimeout(resizedw, 100);
  }

  useEffect(() => {
    if (['Administrator', 'Super Coordinator'].includes(role)) {
      setShowUserPage(true);
      localStorage.setItem('showUsers', true)

      if(role === 'Administrator') {
        setShowAdminNav(true);
        localStorage.setItem('isAdministrator', true)
      }
      return;
    }else {
      setShowAdminNav(false);
      setShowUserPage(false);
      localStorage.setItem('isAdministrator', false)
      localStorage.setItem('showUsers', false)
      return;
    }
  }, [role])

  return (
    <>
    {/* REPORTS */}
    <RunReport reportFunction={runEnrollmentReport} reportName={"Enrollment Rate Limit"} reportRef={enrollmentLimitReport}/>
    <header id="header">
      { process.env.REACT_APP_ENV === "training" &&
      <div className={classes.Training}>
        <p><strong>Training Site</strong> Any information provided here is public and will be deleted daily.  Do not put any private information on this site.</p>
      </div>
      }
      { process.env.REACT_APP_ENV === "staging" &&
      <div className={classes.Development}>
        <p><strong>Staging Environment</strong> This environment is for testing purposes only.  Do not enter private information.</p>
      </div>
      }
      {DbUser.real_user && 
        <div className={classes.ViewAsSession} style={{backgroundColor: "#FFF9C4"}}>
          <p><strong>View As Session</strong> You are currently viewing the site as a different user. Changes made will affect the user's data. Proceed with caution. 
          <Tooltip arrow title="End View-As Session"><IconButton onClick={() => endViewAsSession().then(() => {window.location = "/users/admin"})}><KeyboardReturn fontSize="small"/></IconButton></Tooltip></p>
        </div>
      }
      
      <div className={`container`}>
        <div className={classes.FirstRow}>
          <div className={classes.FirstRow_Left}>
            <div className={classes.FirstRow_Left_Hamburger}>
              {
                window.location.pathname !== '/notexist' &&
                <HamburgerMenu />
              }
            </div>
            <img src="https://connect.communityfinancialresources.org/images/logo.svg" alt="Community Financial Resources" className={classes.FirstRow_Left_Logo} onClick={() => {navigate("/")}} />
          </div>
          {
            window.location.pathname !== '/notexist' &&
            <div className={classes.FirstRow_Profile}>
              <Logo />
              <HamburgerMenu />
              <ProfileDropdown />
            </div>
          }
          <button className={classes.FirstRow_Hamburger + `${ navbarOpen ? " " + classes.FirstRow_Hamburger_Active : "" }`} onClick={clickHandler}>
            <span></span>
          </button>
        </div>
        <div className={classes.SecondRow}>
          <div className={classes.SecondRow_User}>
            <p className={classes.SecondRow_User_Name}>Welcome, {user.name}!</p>
            <div className={classes.SecondRow_User_Profile}>
              <ProfileDropdown />
            </div>
          </div>
          <div className={classes.Nav_Overlay + `${ navbarOpen ? " " + classes.Nav_Overlay_Active : "" }`} id="header-overlay"></div>
          <div className={classes.SecondRow_NavWrp  + `${ navbarOpen ? " " + classes.SecondRow_NavWrp_Active : "" }`} id="header-nav">
          { !['/file-upload-utility', '/notexist'].includes(window.location.pathname) &&
            <nav className={classes.Nav + ` ${showAdminNav ? classes.Admin : classes.Coord}`}>
              <NavLink exact="true" to="/" className={classes.Nav__link} onClick={navClickHandler}>
                Dashboard
              </NavLink>

              { showAdminNav ?
                  <>
                    <Dropdown className={classes.Nav__Dropdown} variant="default">
                      <Dropdown.Toggle variant="none" className={classes.Nav__Dropdown_Toggle}>
                        Enrollments
                      </Dropdown.Toggle>

                      <Dropdown.Menu className={`${classes.Nav__Dropdown_Menu}`}>
                        <Dropdown.Item as={NavLink} to="/enroll">Enroll</Dropdown.Item>
                        <Dropdown.Item as={NavLink} to="/enrollments">Search</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Accordion className={classes.Nav__Accordion}>
                      <Accordion.Item className={classes.Nav__Accordion_Item} eventKey="0">
                        <Accordion.Header className={classes.Nav__Accordion_Item_Header}>Enrollments</Accordion.Header>
                        <Accordion.Body>
                          <NavLink to={`/enroll`} className={classes.Nav__Accordion_Item_Link}>
                            Enroll
                          </NavLink>
                        </Accordion.Body>
                        <Accordion.Body>
                          <NavLink to={`/search`} className={classes.Nav__Accordion_Item_Link}>
                            Search
                          </NavLink>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
                  :
                  <NavLink to="/enroll" className={classes.Nav__link} onClick={navClickHandler}>
                    Enroll
                  </NavLink>
              }

              { !showAdminNav &&
                  <NavLink to="/search" className={classes.Nav__link} onClick={navClickHandler}>
                    Search
                  </NavLink>
              }

              { showUserPage &&
                <NavLink to={role === 'Administrator' ? "/users/admin" : "/users"} className={classes.Nav__link} onClick={navClickHandler}>
                  Users
                </NavLink>
              }

              { !showAdminNav &&
                <NavLink to="/help" className={classes.Nav__link} onClick={navClickHandler}>
                  Help
                </NavLink>
              }

              { showAdminNav &&
                  <>
                    <Dropdown className={classes.Nav__Dropdown} variant="default">
                      <Dropdown.Toggle variant="none" className={classes.Nav__Dropdown_Toggle}>
                        Organizations
                      </Dropdown.Toggle>

                      <Dropdown.Menu className={`${classes.Nav__Dropdown_Menu}`}>
                        <Dropdown.Item as={NavLink} to="/organizations">CBOs</Dropdown.Item>
                        <Dropdown.Item as={NavLink} to={`/locations`}>Locations</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Accordion className={classes.Nav__Accordion}>
                      <Accordion.Item className={classes.Nav__Accordion_Item} eventKey="0">
                        <Accordion.Header className={classes.Nav__Accordion_Item_Header}>Organizations</Accordion.Header>
                        <Accordion.Body>
                          <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                            CBOs
                          </NavLink>
                        </Accordion.Body>
                        <Accordion.Body>
                          <NavLink to={`/add-location`} className={classes.Nav__Accordion_Item_Link}>
                            Locations
                          </NavLink>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </>
              }

              { showAdminNav &&
                  <>
                  <Dropdown className={classes.Nav__Dropdown} variant="default">
                    <Dropdown.Toggle variant="none" className={classes.Nav__Dropdown_Toggle}>
                      Data
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${classes.Nav__Dropdown_Menu}`}>
                      <Dropdown.Item as={NavLink} to="/query-tool">Query Tool</Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/query-tool/saved">Saved Queries</Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/import">Import</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Accordion className={classes.Nav__Accordion}>
                    <Accordion.Item className={classes.Nav__Accordion_Item} eventKey="0">
                      <Accordion.Header className={classes.Nav__Accordion_Item_Header}>Data</Accordion.Header>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Query Tool
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Saved Queries
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Import
                        </NavLink>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              }

              { showAdminNav &&
                <>


                  <Dropdown className={classes.Nav__Dropdown} variant="default">
                    <Dropdown.Toggle variant="none" className={classes.Nav__Dropdown_Toggle}>
                      Reports
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={`${classes.Nav__Dropdown_Menu}`}>
                      <Dropdown.Item as={NavLink} to="/reports/enrollment" >Enrollment</Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/enrollments" >Enrollment Detail</Dropdown.Item>
                      <Dropdown.Item onClick={() => enrollmentLimitReport.current()}>Enrollment Rate Limit</Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/reports/card_types">Card Types</Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/reports/processing">Processing</Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/reports/accounts">Account Export</Dropdown.Item>
                      <Dropdown.Item as={NavLink} to="/reports/cashflow">Cashflow</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Accordion className={classes.Nav__Accordion}>
                    <Accordion.Item className={classes.Nav__Accordion_Item} eventKey="0">
                      <Accordion.Header className={classes.Nav__Accordion_Item_Header}><span>Reports</span></Accordion.Header>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Enrollment
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Card Types
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Processing
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Account Export
                        </NavLink>
                      </Accordion.Body>
                      <Accordion.Body>
                       <NavLink to={`/`} className={classes.Nav__Accordion_Item_Link}>
                          Cashflow
                        </NavLink>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </>
              }
            </nav>
          }
          </div>
        </div>
      </div>
    </header>
    </>
    )


  ;
};

export default Header;
