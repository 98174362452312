import Loader from "../UI/Loader/Loader";
import classes from "./EnrollForm.module.scss";

const WaitModal = (props) => {
  const message = props.message || "Please wait while we submit this information to the financial institution."


  return (
    <div className={classes.Modal}>
      <div className={classes.Modal__wrapper}>
        <div className={classes.Modal__body}>
          <div className={`${classes.Modal__content} ${classes.big}`}>
            <div className={classes.Modal__row__center}>
              <div className={classes.Modal__loader}>
                <Loader />
              </div>
              <h4>{message}</h4>
              <div className={classes.Modal__subtitle}>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.Modal__overlay}></div>
    </div>
  );
};

export default WaitModal;
