import React, { useContext, useEffect, useRef, useState } from 'react';

import Layout from '../components/Layout/Layout';
import Banner from '../components/Banner/Banner';
import Space from '../components/UI/Space/Space';
import Card from '../components/Card/Card';
import Files from '../components/FileUpload/Files/Files';
import DropZone from '../components/FileUpload/DropZone/DropZone';

import { getFiles, getOpenFiles, getSendableUsers } from '../api/api';

import BannerImg from '../assets/images/upload_banner.png';
import classes from './pages.module.scss';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import CardEnrollmentUserLevelContext from '../contexts/userLevelContext';

const FileUpload = () => {
    const [filesList, setFilesList] = useState(null);
    const [recipients, setRecipeients] = React.useState([]);
    const [selectedRecipients, setSelectedRecipients] = useState([])
    const [open, setOpen] = React.useState(false);
    const loading = open && recipients.length === 0;
    const [reviewableFilesList, setReviewableFilesList] = useState(null);
    /**
     * @type React.MutableRefObject<HTMLHeadingElement>
     */
    const titleRef = useRef(null);

    const role = useContext(CardEnrollmentUserLevelContext)

    useEffect(() => {
        if(open) {
            getSendableUsers().then(setRecipeients)
        }
    }, [open])

    const scrollToUpload = () => {
        titleRef.current.scrollIntoView();
    }

    const fetchData = () => {
        getFiles(1, 3)
            .then((response) => {
                setFilesList(response);
            })
            .catch((error) => {
                console.error(error);
            });
        
    };

    const fetchAdminData = () => {
        getOpenFiles(1, 3)
            .then((response) => {
                setReviewableFilesList(response);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {
        fetchData();

        if(role === 'Administrator') {
            fetchAdminData();
        }
    }, [role]);

    return (
        <Layout>
            <Banner
                image={BannerImg}
                title="Secure"
                subtitle="Upload"
                description="Secure file sharing with Community Financial Resources"
            />

            <Space value="70" />
            {role !== 'Administrator' ? (
                <div className="container">
                    <div
                        className={`${classes.Row} ${classes.FileUpload__row}`}
                    >
                        <div className={classes.FileUpload__left}>
                            <Card>
                                <h2 className={classes.Title}>Your files</h2>

                                {filesList && (
                                    <Files
                                        filesList={filesList}
                                        setFilesList={setFilesList}
                                        id="fileList"
                                        getFiles={getFiles}
                                    />
                                )}
                            </Card>
                        </div>

                        <div className={classes.FileUpload__right}>
                            <Card>
                                <h2 className={classes.Title}>Upload data</h2>
                                <p className={classes.FileUpload__text}>
                                    Drag files to the dotted space below or
                                    click “Choose File” to select your file.
                                </p>

                                <DropZone onRender={fetchData} />
                            </Card>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="container">
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '60px',
                        }}
                    >
                        <Card className={classes.FileUpload__fileList}>
                            <h2 className={classes.Title}>Files to review</h2>
                            <Files
                                filesList={reviewableFilesList}
                                setFilesList={setReviewableFilesList}
                                getFiles={getOpenFiles}
                                id="fileList"
                                shouldDisplayStatus={true}
                            />
                        </Card>
                        <Card className={classes.FileUpload__fileList}>
                            <h2 className={classes.Title}>Your files</h2>
                            <Files
                                filesList={filesList}
                                setFilesList={setFilesList}
                                getFiles={getFiles}
                                id="fileList"
                                extra_button={<span onClick={scrollToUpload} className={classes.link}>Upload Files {'>'}</span>}
                            />
                        </Card>
                    </div>
                    <Space value={15} />
                    <Card>
                        <h2 className={classes.Title} ref={titleRef}>Send Data</h2>
                        <p style={{ width: '60%' }}>
                            Send data to customers by selecting the distribution
                            list of users and then drag files to the dotted
                            space below or click “Choose File” to select your
                            file.
                        </p>
                        <Space value={15}/>
                        <Autocomplete
                            multiple
                            id="tags-standard"
                            options={recipients}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(a, b) => a.id === b.id}
                            open={open}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onClose={() => {
                              setOpen(false);
                            }}
                            loading={loading}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Send to:"
                                    placeholder="People"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        size={20}
                                                    />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                            onChange={(event, value) => setSelectedRecipients(value)}
                        />
                        <DropZone onRender={fetchData} send_users={selectedRecipients.map(r => r.id)} upload_callback={() => setSelectedRecipients([])}/>
                    </Card>
                </div>
            )}
        </Layout>
    );
};

export default FileUpload;
