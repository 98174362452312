import classes from "./Notification.module.scss";

const Notification = (props) => {
  const cls = [
    classes.Notification,
    props.width ? classes[props.width] : ''
  ];

  const wrapperCls = [
    classes.Notification__wrapper,
    props.status ? classes[props.status] : '',
    props.width ? classes[props.width] : ''
  ];

  return (
    <div className={cls.join(" ")}>
      <div className={wrapperCls.join(" ")}>
        <div className={classes.Notification__text}>{props.text}</div>
        <div className={classes.Notification__btn} onClick={props.onClose}>
          {props.btn_text}
        </div>
      </div>
    </div>
  );
};

export default Notification;
