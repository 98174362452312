import { useEffect, useState } from "react"

function useTrigger_parent() {
    const [triggerValue, setTriggerValue] = useState(0);

    return {
        trigger: () => setTriggerValue(v => v + 1),
        triggerValue: triggerValue
    }
}

function useTrigger_child(action, trigger) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(action, [trigger])
}


export const useTrigger = {
    parent: useTrigger_parent,
    child: useTrigger_child
}