import React, { useEffect, useRef, useState } from "react";
import Modal from "../Modal/Modal";
import Input from "../Input/Input";
import { Cbo, getCBOs } from "../../../api/cbo.ts";
import LoadingAutoComplete, { idAddingFunction } from "../LoadingAutoComplete.tsx";
import { getAllLocations } from "../../../api/locations.ts";
import styles from "./PredefinedSelection.module.scss"
import Button from "../Button/Button.js";
import useInput from "../../../hooks/useInput.js";
import { ErrorMessageProviderBuilder } from "../Input/ErrorMessageProviderBuilder.ts";
import Checkbox from "../Checkbox/Checkbox.js";
import Space from "../Space/Space.js";
import { PredefinedSelectionObject, createCashflowPredefinedSelection, deleteCashflowPredefinedSelection, getCashflowPredefinedSelection, updateCashflowPredefinedSelection } from "../../../api/data.ts";

interface CreateSelectionModalProps {
  onClose: () => void;
  onSubmit: (selection: PredefinedSelectionObject | null) => void;
  editing_id: number | null;
}



const CreateSelectionModal: React.FC<CreateSelectionModalProps> = ({onClose, onSubmit, editing_id}) => {
  const [selectedCBOs, setSelectedCBOs] = useState<Cbo[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<any[]>([]);
  const [isShared, setShared] = useState<boolean>(false);
  const {
    value: name,
    inputChangeHandler: nameChangeHandler,
    inputBlurHandler: nameBlurHandler,
    hasError: nameHasError,
    isValid: nameIsValid,
  } = useInput(v => v !== null && v.trim() !== '');
  const cboAddIdFunction = useRef<idAddingFunction>();
  const locationAddIdFunction = useRef<idAddingFunction>();
  
  useEffect(() => {
    if(editing_id) {
      console.log('editing id located')
      getCashflowPredefinedSelection(editing_id).then((selection) => {
        // i hope these functions aren't a race condition
        if(cboAddIdFunction.current) {
          cboAddIdFunction.current(selection.cbos);
        }
        if(locationAddIdFunction.current) {
          locationAddIdFunction.current(selection.locations)
        }
        nameChangeHandler({target:{value:selection.name}})
        setShared(selection.shared)
      })
    }
    //eslint-disable-next-line
  }, [editing_id])

  function createPredefinedSelection() {
    nameBlurHandler();

    if(!nameIsValid) {
      return;
    }

    if(editing_id) {
      updateCashflowPredefinedSelection(editing_id, name, selectedCBOs.map(c => c.id), selectedLocations.map(l => l.id), isShared).then((selection) => {
        onSubmit(selection);
      })
    }else {
      createCashflowPredefinedSelection(name, selectedCBOs.map(c => c.id), selectedLocations.map(l => l.id), isShared).then((selection) => {
        onSubmit(selection);
      })
    }

    
  }

  function deletePredefinedSelection() {
    if(!editing_id) {
      return;
    }

    if(!window.confirm('Are you sure you want to delete this predefined selection?')) {
      return;
    }
    deleteCashflowPredefinedSelection(editing_id).then(() => {
      onSubmit(null);
    });
  }

  return (<>
    <Modal onClose={onClose} title={editing_id ? "Edit Predefined Selection" : "Create Predefined Selection"}>
      <div className={styles.modal_inner}>
        <Input
          label="Name:*" 
          value={name}
          onChange={nameChangeHandler}
          onBlur={nameBlurHandler}
          hasError={nameHasError}
          errorMessageProvider={new ErrorMessageProviderBuilder('Name').isntEmpty().build()}
        />
        <LoadingAutoComplete
          optionsGetter={getCBOs}
          optionLabelGetter={c => c.name}
          optionsEqual={(a, b) => a.id === b.id}
          setSelected={setSelectedCBOs}
          label="CBOs:"
          placeHolder="Select CBOs"
          cls={styles.selector}
          addSelectedIdFunctionRef={cboAddIdFunction}
          selected={selectedCBOs}
        />
        <LoadingAutoComplete
          optionsGetter={getAllLocations}
          optionLabelGetter={c => c.name}
          optionsEqual={(a, b) => a.id === b.id}
          setSelected={setSelectedLocations}
          label="Locations:"
          placeHolder="Select Locations"
          cls={styles.selector}
          selected={selectedLocations}
          addSelectedIdFunctionRef={locationAddIdFunction}
        />
        <Space value={15}/>
        <Checkbox label="Share with other CFR administrators" value={isShared} onChange={() => setShared(!isShared)}/>
        <Space value={15}/>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
          <Button onClick={createPredefinedSelection}>Save</Button>
          {editing_id && <div style={{width: '10px'}}></div>}
          {editing_id && <Button onClick={deletePredefinedSelection} color="secondary">Delete</Button>}
        </div>
      </div>
    </Modal>
  </>)
}


export default CreateSelectionModal;