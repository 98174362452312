import { TreeItem, TreeView } from '@mui/lab';
import React, { ReactNode, useEffect, useState } from 'react';

function SingleDepthDropdownTree({ hierarchy, primaryIcon, secondaryIcon, label, suffixProvider }: { hierarchy: any, primaryIcon: ReactNode, secondaryIcon: ReactNode, label: ReactNode, suffixProvider: (isTopLevel: boolean, key: string) => ReactNode }) {
    const [tree, setTree] = useState<ReactNode[]>()
    
    useEffect(() => {

        function constructTree(): ReactNode[] {
            const nodes: ReactNode[] = [];
            /*eslint-disable*/
            let i = 1;
            for (const key in hierarchy) {
                nodes.push(
                    <TreeItem nodeId={i.toString()} label={<><div style={{ userSelect: 'none', display: 'inline' }}>{primaryIcon} {key} {suffixProvider(true, key)}</div></>} key={key}>
                        {hierarchy[key].map((col) => {
                            i++;
                            return (
                                <TreeItem
                                    nodeId={i.toString()}
                                    label={<><div style={{ userSelect: 'none', display: 'inline' }}>{secondaryIcon} {col} {suffixProvider(false, col)}</div></>}
                                    key={Math.random()}
                                />
                            );
                        })}
                    </TreeItem>
                );
                i++;
            }
            /*eslint-enable*/
            return nodes;
        }

        setTree(constructTree())
    }, [hierarchy, primaryIcon, secondaryIcon, suffixProvider])

    

    return (
        <>
            <TreeView>
                {label}
                {tree}
            </TreeView>
        </>
    );
}

export default SingleDepthDropdownTree;
