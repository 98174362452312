import classes from "./EnrollmentDetail.module.scss";
import moment from "moment/moment";

const NotesItem = ({ item }) => {
  let time = ""
  let timeFormat = moment.utc(item.submitted).add(moment().utcOffset(), "minutes")

  switch (timeFormat.format("YYYY-MM-DD")) {
    case moment().format("YYYY-MM-DD"):
      time = "today"
      break;
    case moment().subtract(1, 'days').format("YYYY-MM-DD"):
      time = "1 day ago"
      break;
    case moment().subtract(2, 'days').format("YYYY-MM-DD"):
      time = "2 days ago"
      break;
    default:
      time = timeFormat.format("[on] MMMM D [at] h:mm a")
  }
  return (
    <div className={classes.Notes__note}>
      <p className={classes.Notes__note__time}>{item.user.name} commented {time}:</p>
      <p className={classes.Notes__note__content}>{item.message}</p>
    </div>
  );
}

export default NotesItem